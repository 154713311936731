import { Circle as LeafletCircle } from 'leaflet'
import PropTypes from 'prop-types'
import * as l from 'leaflet-semicircle'
import { SemiCircle as Sc } from './SemiCircle'
import { Path } from 'react-leaflet'
//import children from './propTypes/children'
//import latlng from './propTypes/latlng'

export default class SemiCircleComponent extends Path {
    static propTypes = {
        center: PropTypes.any.isRequired,
        children: PropTypes.any,
        radius: PropTypes.number.isRequired
    }

    createLeafletElement(props) {
        const { center, radius, ...options } = props

        //const obj = new LeafletCircle(center, radius, this.getOptions(options))
        let obj = new LeafletCircle(center, radius, this.getOptions(options))

        obj = new Sc(center, radius, this.getOptions(options))

        return obj
    }

    updateLeafletElement(fromProps, toProps) {
        if (toProps.center !== fromProps.center) {
            this.leafletElement.setLatLng(toProps.center)
        }
        if (toProps.radius !== fromProps.radius) {
            this.leafletElement.setRadius(toProps.radius)
        }
    }
}
