import React from 'react'
import {
    Map,
    TileLayer,
    WMSTileLayer,
    Polyline,
    Rectangle,
    Polygon,
    Tooltip,
    CircleMarker,
    Circle,
    Popup,
    LayersControl,
    LayerGroup
} from 'react-leaflet'
import SemiCircle from '../../components/SemiCircleComponent'
const { BaseLayer, Overlay } = LayersControl

const dxfDataFM = [
    [[49.6053592170414, 14.0677043767605], [49.6053558861488, 14.0677093137702]],
    [[49.6053090845351, 14.0676634178138], [49.6053124154258, 14.0676584808056]],
    [[49.5921880645132, 14.0703786392999], [49.5920405575028, 14.0705474867421]],
    [[49.5920405575028, 14.0705474867421], [49.5920709594326, 14.070692165665]],
    [[49.5914626941692, 14.0710904258152], [49.5913708179462, 14.0706394298519]],
    [[49.5913708179462, 14.0706394298519], [49.5913092176289, 14.0703978027124]],
    [[49.5913092176289, 14.0703978027124], [49.5912467597728, 14.0703412060904]],
    [[49.5731877487474, 14.088814293671], [49.5731175079268, 14.0880543736733]],
    [[49.569858033771, 14.0887600695218], [49.5699997235153, 14.0883293304432]],
    [[49.5632027549957, 14.0868068418179], [49.563184157502, 14.0869036769748]],
    [[49.563184157502, 14.0869036769748], [49.562925445743, 14.0867862383401]],
    [[49.562925445743, 14.0867862383401], [49.5629764025771, 14.0865209102709]],
    [[49.5552721242166, 14.0813946196013], [49.5551024101293, 14.0813144888092]],
    [[49.5551024101293, 14.0813144888092], [49.5549646633868, 14.0812506704018]],
    [[49.5549646633868, 14.0812506704018], [49.5546957241663, 14.0811288585576]],
    [[49.5546957241663, 14.0811288585576], [49.5545419616706, 14.0810608569802]],
    [[49.5545419616706, 14.0810608569802], [49.5544193599217, 14.0809759750028]],
    [[49.5557690277164, 14.082508259085], [49.5560454708123, 14.0826724369224]],
    [[49.5257958784642, 14.0744961390541], [49.525821009327, 14.0745171637518]],
    [[49.525821009327, 14.0745171637518], [49.525843841633, 14.0745274454857]],
    [[49.525843841633, 14.0745274454857], [49.5258948813578, 14.0745681762885]],
    [[49.5258948813578, 14.0745681762885], [49.5259294967629, 14.0745906914046]],
    [[49.5259294967629, 14.0745906914046], [49.5259598620264, 14.0746059761404]],
    [[49.5869163399024, 14.0791265592292], [49.5870167220265, 14.0792284285825]],
    [[49.5870167220265, 14.0792284285825], [49.5871370056898, 14.0792604245341]],
    [[49.5871370056898, 14.0792604245341], [49.5871929096402, 14.0792375013198]],
    [[49.5873432764781, 14.0790564242333], [49.5872515057235, 14.0789004054423]],
    [[49.5864803978929, 14.0778802563769], [49.5864482946413, 14.0780094946413]],
    [[49.5864482946413, 14.0780094946413], [49.5864441953177, 14.0781914169954]],
    [[49.5864441953177, 14.0781914169954], [49.5864824254671, 14.0783561639315]],
    [[49.5864824254671, 14.0783561639315], [49.5865534018783, 14.0784940374281]],
    [[49.5865534018783, 14.0784940374281], [49.5865993482465, 14.0785669582701]],
    [[49.5722365691313, 14.086486247292], [49.5721973825033, 14.0866535537889]],
    [[49.5721973825033, 14.0866535537889], [49.5721493635716, 14.086756559979]],
    [[49.5721493635716, 14.086756559979], [49.5720995319613, 14.0868899171388]],
    [[49.5720995319613, 14.0868899171388], [49.5720677052354, 14.0870490300204]],
    [[49.5720677052354, 14.0870490300204], [49.5720567556423, 14.0871960051866]],
    [[49.5720567556423, 14.0871960051866], [49.5720661772595, 14.0873435694749]],
    [[49.6231744921715, 14.0524922604043], [49.6231661413401, 14.0524668306866]],
    [[49.6235513936958, 14.0523693866039], [49.6242176522922, 14.0522045057883]],
    [[49.6242176522922, 14.0522045057883], [49.6242049485833, 14.0519856963673]],
    [[49.5918041703179, 14.0722433202913], [49.5919282045883, 14.0723234600989]],
    [[49.5919605077625, 14.0722318580024], [49.59194635854, 14.0722227843238]],
    [[49.5918338287952, 14.0721506209343], [49.5917663733789, 14.0720969323568]],
    [[49.5917663733789, 14.0720969323568], [49.5917144047432, 14.0719681785029]],
    [[49.5746420230431, 14.0879883793289], [49.574642862441, 14.0879893488441]],
    [[49.5565156051425, 14.0819749318561], [49.5556621194097, 14.0822997311254]],
    [[49.5564654443137, 14.0819399116456], [49.5556539082602, 14.0822487472778]],
    [[49.5809837424158, 14.0832987804371], [49.5806000132776, 14.0827762367933]],
    [[49.5813380614304, 14.0837203302185], [49.5810034130562, 14.0832646132507]],
    [[49.5813620359492, 14.0837839515062], [49.5813223036557, 14.0837598261597]],
    [[49.5810034130562, 14.0832646132507], [49.5806196837636, 14.0827420696664]],
    [[49.5813986538742, 14.0837571217817], [49.5813380614304, 14.0837203302185]],
    [[49.5634715880849, 14.0870929853531], [49.5633793134152, 14.0867322038287]],
    [[49.5896493982017, 14.0738904542138], [49.5895716872474, 14.0736685523114]],
    [[49.5746566922078, 14.0881204910033], [49.574622849947, 14.0877995502834]],
    [[49.5813223036557, 14.0837598261597], [49.5809837424158, 14.0832987804371]],
    [[49.5806196837636, 14.0827420696664], [49.580582131022, 14.0828072977948]],
    [[49.5628847962336, 14.0854435775619], [49.5629621190241, 14.0857680531649]],
    [[49.5881361103265, 14.0775271301222], [49.5882273167471, 14.0784238541062]],
    [[49.5745799340874, 14.0877381512779], [49.5745873431639, 14.0878084053706]],
    [[49.5734816662909, 14.0872959029099], [49.5735284331147, 14.0872891420551]],
    [[49.5736091039391, 14.0872774798307], [49.5745255484681, 14.08714499073]],
    [[49.5813212644882, 14.0838138244581], [49.580965860011, 14.0833298414945]],
    [[49.5901203211487, 14.0747696949335], [49.5900750017749, 14.0748128844585]],
    [[49.5634715880849, 14.0870929853531], [49.5633489060413, 14.0871587305477]],
    [[49.589693476369, 14.0735657186395], [49.589571658778, 14.0736685759105]],
    [[49.580965860011, 14.0833298414945], [49.5805821310131, 14.0828072977968]],
    [[49.5745953349717, 14.0878064125758], [49.5745873431639, 14.0878084053706]],
    [[49.5882273167471, 14.0784238541062], [49.5882849168286, 14.0784130941839]],
    [[49.5633247314934, 14.0870493479399], [49.5633489060413, 14.0871587305477]],
    [[49.5745280609547, 14.0871688030484], [49.5745255484681, 14.08714499073]],
    [[49.5746566922078, 14.0881204910033], [49.5746291770891, 14.0881273518755]],
    [[49.5734787837628, 14.0872487536281], [49.5734816662909, 14.0872959029099]],
    [[49.5745558472689, 14.0871645082924], [49.5745484204251, 14.0870941193829]],
    [[49.5702773298281, 14.0871692585073], [49.5702894955183, 14.0870884391638]],
    [[49.574622849947, 14.0877995502834], [49.574630834643, 14.0877975592496]],
    [[49.5746181068534, 14.0880223671045], [49.5745953349717, 14.0878064125758]],
    [[49.574623206654, 14.087725229396], [49.574630834643, 14.0877975592496]],
    [[49.5745484204251, 14.0870941193829], [49.5735404645941, 14.087239836799]],
    [[49.5735224698453, 14.0872424381946], [49.5734787837628, 14.0872487536281]],
    [[49.5700636161776, 14.0870080501678], [49.5700876352283, 14.0870165983563]],
    [[49.5702709639189, 14.0870818438587], [49.5702894955183, 14.0870884391638]],
    [[49.570051450543, 14.0870888691561], [49.5700598426949, 14.0870918558689]],
    [[49.5702482527072, 14.087158910057], [49.5702773298281, 14.0871692585073]],
    [[49.5629399043832, 14.0853884815291], [49.562897220769, 14.0852172211143]],
    [[49.570051450543, 14.0870888691561], [49.5700636161776, 14.0870080501678]],
    [[49.5630926934004, 14.0854311909505], [49.5631572120891, 14.0854392617944]],
    [[49.6062353279421, 14.0624916410291], [49.6060378116277, 14.0625402291469]],
    [[49.5764797333678, 14.0865181758285], [49.5764662188769, 14.0864337085256]],
    [[49.5764662188769, 14.0864337085256], [49.5761651718965, 14.0863130089236]],
    [[49.5765004962423, 14.0863817175903], [49.5761756072922, 14.086251459316]],
    [[49.5761756072922, 14.086251459316], [49.576165171959, 14.0863130085189]],
    [[49.5765193169599, 14.0864993493756], [49.5765004962423, 14.0863817175903]],
    [[49.5397434267541, 14.0792336001471], [49.5397390280386, 14.0791691387069]],
    [[49.6187182365178, 14.0535336500324], [49.6187419546217, 14.0535344358598]],
    [[49.618718333273, 14.0535267317629], [49.6187410646969, 14.0535274848964]],
    [[49.6189575107705, 14.0533832410752], [49.6191357133487, 14.0532630039786]],
    [[49.6191357133487, 14.0532630039786], [49.6193054882566, 14.0531522008411]],
    [[49.6193054882566, 14.0531522008411], [49.6193937266475, 14.0531045875411]],
    [[49.5743335859253, 14.0878116286141], [49.5743948346037, 14.0878645694046]],
    [[49.5736480198176, 14.0879715608003], [49.5736468119963, 14.0879584181906]],
    [[49.6044692841232, 14.0619711742612], [49.6045032798419, 14.0620488165376]],
    [[49.5745666404258, 14.0880819969716], [49.5746291770891, 14.0881273518755]],
    [[49.598663529847, 14.0696663539268], [49.598698216591, 14.0696810650707]],
    [[49.5986960672759, 14.0693773734189], [49.598738010994, 14.0693858507593]],
    [[49.5244309648827, 14.0793183311654], [49.5243748566595, 14.079726436593]],
    [[49.534635945447, 14.0786808925344], [49.5346249773406, 14.078679499606]],
    [[49.5346249773406, 14.078679499606], [49.5344811084069, 14.0786080815439]],
    [[49.5344811084069, 14.0786080815439], [49.5343480229673, 14.0785396320076]],
    [[49.5343480229673, 14.0785396320076], [49.5341760639613, 14.0784710675943]],
    [[49.5341760639613, 14.0784710675943], [49.5339892622784, 14.0784567441241]],
    [[49.5339892622784, 14.0784567441241], [49.5337783753711, 14.0783800093747]],
    [[49.5337783753711, 14.0783800093747], [49.5337665819503, 14.0783796067349]],
    [[49.534637374703, 14.0786741425527], [49.5346258652999, 14.0786726808823]],
    [[49.5346258652999, 14.0786726808823], [49.5344825131037, 14.0786015193542]],
    [[49.5344825131037, 14.0786015193542], [49.5343493027099, 14.0785330055687]],
    [[49.5343493027099, 14.0785330055687], [49.5341767477461, 14.0784642035559]],
    [[49.5341767477461, 14.0784642035559], [49.5339898976375, 14.0784498763988]],
    [[49.5339898976375, 14.0784498763988], [49.5337789496645, 14.0783731194602]],
    [[49.5337789496645, 14.0783731194602], [49.5337676372157, 14.0783727332429]],
    [[49.5963673719199, 14.0675158663868], [49.5965443893319, 14.0682074051373]],
    [[49.5965443893319, 14.0682074051373], [49.5965495634136, 14.0682163581811]],
    [[49.5965495634136, 14.0682163581811], [49.5965578505863, 14.0682198359194]],
    [[49.5965578505863, 14.0682198359194], [49.5965767874047, 14.0682110447817]],
    [[49.5965767874047, 14.0682110447817], [49.5965794417597, 14.0682125777319]],
    [[49.5965794417597, 14.0682125777319], [49.5965818596184, 14.0682225656262]],
    [[49.5963720125282, 14.0675151189144], [49.5965482798977, 14.0682037275386]],
    [[49.5965482798977, 14.0682037275386], [49.5965520355558, 14.068210226193]],
    [[49.5965520355558, 14.068210226193], [49.5965577879826, 14.0682126402163]],
    [[49.5965577879826, 14.0682126402163], [49.5965769441072, 14.0682037472659]],
    [[49.5965769441072, 14.0682037472659], [49.5965829408904, 14.0682072105433]],
    [[49.5965829408904, 14.0682072105433], [49.5965861215854, 14.068220417988]],
    [[49.5873949314194, 14.079025829583], [49.5872889980441, 14.0786846853701]],
    [[49.5872889980441, 14.0786846853701], [49.5869409466484, 14.078056104819]],
    [[49.5869409466484, 14.078056104819], [49.5866971936483, 14.0778085014372]],
    [[49.5873989626437, 14.0790227655411], [49.5872928018056, 14.0786808895989]],
    [[49.5872928018056, 14.0786808895989], [49.5869439560269, 14.0780508744256]],
    [[49.5869439560269, 14.0780508744256], [49.586699704755, 14.0778027891875]],
    [[49.5554470782259, 14.0816385294094], [49.5554250542005, 14.0815331201351]],
    [[49.5554250542005, 14.0815331201351], [49.5553776193936, 14.081306094209]],
    [[49.5553776193936, 14.081306094209], [49.5553223995993, 14.0810418120417]],
    [[49.5553223995993, 14.0810418120417], [49.555327522782, 14.0810277498365]],
    [[49.555327522782, 14.0810277498365], [49.5553373531234, 14.0810229800371]],
    [[49.5553710369336, 14.0813423905273], [49.5553647777948, 14.0813124340481]],
    [[49.5553647777948, 14.0813124340481], [49.5553077622745, 14.08103955758]],
    [[49.5553077622745, 14.08103955758], [49.5553183661967, 14.0810104517401]],
    [[49.5553183661967, 14.0810104517401], [49.555333228622, 14.081003240308]],
    [[49.5554517491137, 14.0816382844099], [49.5554293347377, 14.0815310068733]],
    [[49.5554293347377, 14.0815310068733], [49.5553818999265, 14.0813039809285]],
    [[49.5553818999265, 14.0813039809285], [49.5553272787075, 14.0810425635292]],
    [[49.5553272787075, 14.0810425635292], [49.5553305760631, 14.081033512888]],
    [[49.5553305760631, 14.081033512888], [49.5553387252556, 14.0810295470215]],
    [[49.5554008385319, 14.0813268215927], [49.5553947415248, 14.0812976410849]],
    [[49.5553947415248, 14.0812976410849], [49.555333228622, 14.081003240308]],
    [[49.5257948455388, 14.0745235422264], [49.5258090633639, 14.0745369748761]],
    [[49.5258090633639, 14.0745369748761], [49.525837946246, 14.074549981285]],
    [[49.525837946246, 14.074549981285], [49.5258880832552, 14.0745899917245]],
    [[49.5258880832552, 14.0745899917245], [49.5259196322849, 14.0746160884753]],
    [[49.5259196322849, 14.0746160884753], [49.5260863705324, 14.074719620631]],
    [[49.5260863705324, 14.074719620631], [49.5263451322393, 14.0748682074298]],
    [[49.5263451322393, 14.0748682074298], [49.5264093519545, 14.074905370589]],
    [[49.5264093519545, 14.074905370589], [49.5264436021208, 14.0749287037756]],
    [[49.5264436021208, 14.0749287037756], [49.5264801386537, 14.074953594612]],
    [[49.5264801386537, 14.074953594612], [49.5265314360524, 14.074987026688]],
    [[49.5265314360524, 14.074987026688], [49.5266978710766, 14.0750898788409]],
    [[49.5266978710766, 14.0750898788409], [49.5267606879215, 14.0751499548331]],
    [[49.5267606879215, 14.0751499548331], [49.5269681973446, 14.0753484114135]],
    [[49.5269681973446, 14.0753484114135], [49.5269852148499, 14.0753532381895]],
    [[49.5269852148499, 14.0753532381895], [49.527001674788, 14.0753489449947]],
    [[49.527001674788, 14.0753489449947], [49.5270158345186, 14.0753440520502]],
    [[49.5270158345186, 14.0753440520502], [49.5270503546172, 14.0753362417212]],
    [[49.5270503546172, 14.0753362417212], [49.5270761725101, 14.0753377606816]],
    [[49.5270761725101, 14.0753377606816], [49.527095463185, 14.0753519009461]],
    [[49.527095463185, 14.0753519009461], [49.5271465936434, 14.075387136691]],
    [[49.5271465936434, 14.075387136691], [49.5273767177208, 14.0755417660096]],
    [[49.5273767177208, 14.0755417660096], [49.5275569956682, 14.0756583926756]],
    [[49.5275569956682, 14.0756583926756], [49.5276644781167, 14.0757257413568]],
    [[49.5276644781167, 14.0757257413568], [49.5278492203995, 14.0758560913692]],
    [[49.5278492203995, 14.0758560913692], [49.5280534491889, 14.0760013951055]],
    [[49.5280534491889, 14.0760013951055], [49.5282487255872, 14.0761403704074]],
    [[49.5282487255872, 14.0761403704074], [49.528337949703, 14.0761756641001]],
    [[49.528337949703, 14.0761756641001], [49.5285359371853, 14.0763157693255]],
    [[49.5285359371853, 14.0763157693255], [49.5285578263803, 14.076364501004]],
    [[49.5285578263803, 14.076364501004], [49.5287980258324, 14.0765907032731]],
    [[49.5287980258324, 14.0765907032731], [49.5288637832254, 14.0766922133415]],
    [[49.5288637832254, 14.0766922133415], [49.5289505524159, 14.076779353666]],
    [[49.5289505524159, 14.076779353666], [49.5290229659787, 14.0768478680542]],
    [[49.5257951516918, 14.0745157231749], [49.5258090453868, 14.0745288495913]],
    [[49.5258090453868, 14.0745288495913], [49.5258429326647, 14.0745461775687]],
    [[49.5258429326647, 14.0745461775687], [49.5258901853734, 14.0745838862695]],
    [[49.5258901853734, 14.0745838862695], [49.5259215461738, 14.0746098273166]],
    [[49.5259215461738, 14.0746098273166], [49.5260880002711, 14.0747131830145]],
    [[49.5260880002711, 14.0747131830145], [49.5263467117361, 14.0748617409291]],
    [[49.5263467117361, 14.0748617409291], [49.5264110570583, 14.0748989767671]],
    [[49.5264110570583, 14.0748989767671], [49.5264454246925, 14.074922389975]],
    [[49.5264454246925, 14.074922389975], [49.5264819279834, 14.0749472581603]],
    [[49.5264819279834, 14.0749472581603], [49.5265331530601, 14.0749806430952]],
    [[49.5265331530601, 14.0749806430952], [49.5266999135188, 14.0750836963364]],
    [[49.5266999135188, 14.0750836963364], [49.526763063973, 14.0751440913735]],
    [[49.526763063973, 14.0751440913735], [49.5269698507352, 14.0753418567916]],
    [[49.5269698507352, 14.0753418567916], [49.5269852477127, 14.0753462239256]],
    [[49.5269852477127, 14.0753462239256], [49.527000804591, 14.0753421662708]],
    [[49.527000804591, 14.0753421662708], [49.5270150125153, 14.0753372566708]],
    [[49.5270150125153, 14.0753372566708], [49.5270501110391, 14.0753293154656]],
    [[49.5270501110391, 14.0753293154656], [49.5270772714395, 14.0753309134068]],
    [[49.5270772714395, 14.0753309134068], [49.5270973515379, 14.075345632324]],
    [[49.5270973515379, 14.075345632324], [49.5271484145564, 14.0753808215871]],
    [[49.5271484145564, 14.0753808215871], [49.5273784910447, 14.0755354188989]],
    [[49.5273784910447, 14.0755354188989], [49.527558717113, 14.0756520119793]],
    [[49.527558717113, 14.0756520119793], [49.5276662661331, 14.0757194023604]],
    [[49.5276662661331, 14.0757194023604], [49.5278511032831, 14.075849819285]],
    [[49.5278511032831, 14.075849819285], [49.5280553385308, 14.0759951275899]],
    [[49.5280553385308, 14.0759951275899], [49.5282502435082, 14.0761338385307]],
    [[49.5282502435082, 14.0761338385307], [49.5283394628613, 14.0761691303279]],
    [[49.5283394628613, 14.0761691303279], [49.5285389375428, 14.0763102879417]],
    [[49.5285389375428, 14.0763102879417], [49.5285609866673, 14.0763593756655]],
    [[49.5285609866673, 14.0763593756655], [49.5288008335175, 14.0765852458579]],
    [[49.5288008335175, 14.0765852458579], [49.5288666375665, 14.0766868279406]],
    [[49.5288666375665, 14.0766868279406], [49.5289529616389, 14.0767735212335]],
    [[49.5289529616389, 14.0767735212335], [49.5290253235813, 14.0768419867723]],
    [[49.5298634375938, 14.0773551248351], [49.5299525373869, 14.0774001318662]],
    [[49.5299525373869, 14.0774001318662], [49.5301760221041, 14.0775714236586]],
    [[49.5301760221041, 14.0775714236586], [49.5303562953545, 14.0777128862536]],
    [[49.5303562953545, 14.0777128862536], [49.5305140792859, 14.0778062381171]],
    [[49.5305140792859, 14.0778062381171], [49.5307006263624, 14.0779151309189]],
    [[49.5307006263624, 14.0779151309189], [49.5308502522475, 14.0779995622346]],
    [[49.5308502522475, 14.0779995622346], [49.5310459606448, 14.0781030754908]],
    [[49.5310459606448, 14.0781030754908], [49.5311606259866, 14.0781623598081]],
    [[49.5311606259866, 14.0781623598081], [49.5312987399109, 14.0782345422051]],
    [[49.5312987399109, 14.0782345422051], [49.5314560127499, 14.0783120241781]],
    [[49.5314560127499, 14.0783120241781], [49.5315446203061, 14.0783530370122]],
    [[49.5315446203061, 14.0783530370122], [49.5316401398514, 14.0783967071291]],
    [[49.5316401398514, 14.0783967071291], [49.5317268330825, 14.0784092045252]],
    [[49.5317268330825, 14.0784092045252], [49.5318085181624, 14.0784548738953]],
    [[49.5318085181624, 14.0784548738953], [49.5319027422456, 14.0784968775578]],
    [[49.5319027422456, 14.0784968775578], [49.5319473835109, 14.0786011224141]],
    [[49.5298592276852, 14.0773602693062], [49.5299508227477, 14.0774065367835]],
    [[49.5299508227477, 14.0774065367835], [49.5301739960356, 14.0775775899077]],
    [[49.5301739960356, 14.0775775899077], [49.5303544599863, 14.077719202171]],
    [[49.5303544599863, 14.077719202171], [49.5305124730381, 14.0778126896126]],
    [[49.5305124730381, 14.0778126896126], [49.5306990529024, 14.0779216015784]],
    [[49.5306990529024, 14.0779216015784], [49.5308487453466, 14.0780060704722]],
    [[49.5308487453466, 14.0780060704722], [49.5310445119279, 14.078109614529]],
    [[49.5310445119279, 14.078109614529], [49.5311591850777, 14.0781689028987]],
    [[49.5311591850777, 14.0781689028987], [49.5312973294621, 14.0782411012336]],
    [[49.5312973294621, 14.0782411012336], [49.5314546778089, 14.0783186204274]],
    [[49.5314546778089, 14.0783186204274], [49.531543330731, 14.0783596542716]],
    [[49.531543330731, 14.0783596542716], [49.5316392805196, 14.0784035211028]],
    [[49.5316392805196, 14.0784035211028], [49.5317258382428, 14.0784159989764]],
    [[49.5317258382428, 14.0784159989764], [49.5318071215097, 14.0784614437081]],
    [[49.5318071215097, 14.0784614437081], [49.531899897959, 14.0785028020486]],
    [[49.531899897959, 14.0785028020486], [49.531943556954, 14.0786047061917]],
    [[49.5290251889476, 14.07684186902], [49.5291516540419, 14.0769437780824]],
    [[49.5291516540419, 14.0769437780824], [49.5294971330537, 14.0771753239166]],
    [[49.5294971330537, 14.0771753239166], [49.5296893736007, 14.0772934672974]],
    [[49.5296893736007, 14.0772934672974], [49.5297836335245, 14.0773148135058]],
    [[49.5297836335245, 14.0773148135058], [49.5298583275048, 14.0773525435781]],
    [[49.5298583275048, 14.0773525435781], [49.529864694645, 14.0773447629869]],
    [[49.529864694645, 14.0773447629869], [49.5299338097311, 14.0771053128491]],
    [[49.5299338097311, 14.0771053128491], [49.5299745253546, 14.0769642519009]],
    [[49.5299745253546, 14.0769642519009], [49.5301033230355, 14.0765180215721]],
    [[49.5301033230355, 14.0765180215721], [49.53010602225, 14.0764962076199]],
    [[49.5298634375938, 14.0773551248351], [49.5298683571185, 14.0773491132178]],
    [[49.5298683571185, 14.0773491132178], [49.5299379196477, 14.077108112919]],
    [[49.5299379196477, 14.077108112919], [49.5299786352746, 14.0769670519612]],
    [[49.5299786352746, 14.0769670519612], [49.5301076430436, 14.0765200937648]],
    [[49.5301076430436, 14.0765200937648], [49.530110356542, 14.0764981643777]],
    [[49.5290231006123, 14.0768479858066], [49.5291497085247, 14.0769500099721]],
    [[49.5291497085247, 14.0769500099721], [49.5294953988943, 14.0771816975063]],
    [[49.5294953988943, 14.0771816975063], [49.5296881948052, 14.0773001822182]],
    [[49.5296881948052, 14.0773001822182], [49.5297825960027, 14.0773215604324]],
    [[49.5297825960027, 14.0773215604324], [49.5298592276852, 14.0773602693062]],
    [[49.540365993318, 14.0802267555954], [49.5403669183085, 14.0801936678528]],
    [[49.5403669183085, 14.0801936678528], [49.5403609072635, 14.0801725005532]],
    [[49.5403609072635, 14.0801725005532], [49.5402909588104, 14.0800920825221]],
    [[49.5402909588104, 14.0800920825221], [49.5402237696951, 14.0800217825252]],
    [[49.5402237696951, 14.0800217825252], [49.5402200036459, 14.0799976326146]],
    [[49.5402200036459, 14.0799976326146], [49.540253710186, 14.0798751938675]],
    [[49.540253710186, 14.0798751938675], [49.540307499425, 14.0795721629607]],
    [[49.540307499425, 14.0795721629607], [49.5403139827348, 14.0792878307687]],
    [[49.5403139827348, 14.0792878307687], [49.5403091447485, 14.0792770437681]],
    [[49.5403091447485, 14.0792770437681], [49.5402445965602, 14.0792303862548]],
    [[49.5402445965602, 14.0792303862548], [49.540238839, 14.0792087458805]],
    [[49.540238839, 14.0792087458805], [49.5402621752414, 14.0789783387091]],
    [[49.5402621752414, 14.0789783387091], [49.540257938411, 14.0789663303119]],
    [[49.540257938411, 14.0789663303119], [49.5402518945925, 14.078964929307]],
    [[49.5403704561723, 14.0802270306471], [49.540371454385, 14.0801923675675]],
    [[49.540371454385, 14.0801923675675], [49.5403645524292, 14.0801680630147]],
    [[49.5403645524292, 14.0801680630147], [49.5402935719177, 14.0800864584573]],
    [[49.5402935719177, 14.0800864584573], [49.5402277367535, 14.0800175751051]],
    [[49.5402277367535, 14.0800175751051], [49.5402247202924, 14.079998231952]],
    [[49.5402247202924, 14.079998231952], [49.5402579682804, 14.0798774588869]],
    [[49.5402579682804, 14.0798774588869], [49.5403119742755, 14.0795732068464]],
    [[49.5403119742755, 14.0795732068464], [49.5403185279083, 14.0792857904818]],
    [[49.5403185279083, 14.0792857904818], [49.5403121574046, 14.0792715865089]],
    [[49.5403121574046, 14.0792715865089], [49.5402480952205, 14.0792252803006]],
    [[49.5402480952205, 14.0792252803006], [49.5402434736997, 14.0792079098461]],
    [[49.5402434736997, 14.0792079098461], [49.5402668510882, 14.0789770963709]],
    [[49.5402668510882, 14.0789770963709], [49.5402608234028, 14.0789600121759]],
    [[49.5402608234028, 14.0789600121759], [49.5402525787611, 14.0789581010039]],
    [[49.5744498213781, 14.0879109164797], [49.5745268297309, 14.0879580459596]],
    [[49.5745268297309, 14.0879580459596], [49.5746114854788, 14.0880446566024]],
    [[49.5746114854788, 14.0880446566024], [49.5746196735742, 14.0880506118699]],
    [[49.5735899682874, 14.0872855123917], [49.5735946535227, 14.0872886798976]],
    [[49.5735946535227, 14.0872886798976], [49.5735995469489, 14.0873206180488]],
    [[49.5735995469489, 14.0873206180488], [49.5736584605707, 14.0879616518447]],
    [[49.5736584605707, 14.0879616518447], [49.5736690404906, 14.0879729083924]],
    [[49.5736690404906, 14.0879729083924], [49.5737506395149, 14.0879554890687]],
    [[49.5737506395149, 14.0879554890687], [49.5740328887898, 14.0878956458527]],
    [[49.5740328887898, 14.0878956458527], [49.5743243999419, 14.0878314467251]],
    [[49.5743243999419, 14.0878314467251], [49.5743888334705, 14.087887140395]],
    [[49.5735916573395, 14.0872791543484], [49.5735985140469, 14.0872837296273]],
    [[49.5735985140469, 14.0872837296273], [49.5736039699561, 14.087319338959]],
    [[49.5736039699561, 14.087319338959], [49.5736626368717, 14.0879576882612]],
    [[49.5736626368717, 14.0879576882612], [49.5736701577338, 14.0879656901114]],
    [[49.5736701577338, 14.0879656901114], [49.5737500213056, 14.087948641251]],
    [[49.5737500213056, 14.087948641251], [49.574032263058, 14.0878887995872]],
    [[49.574032263058, 14.0878887995872], [49.5743252728714, 14.0878242703682]],
    [[49.5743252728714, 14.0878242703682], [49.574390692422, 14.0878808163042]],
    [[49.574390692422, 14.0878808163042], [49.5744462471041, 14.0879012880136]],
    [[49.5744462471041, 14.0879012880136], [49.574528928831, 14.0879518896093]],
    [[49.574528928831, 14.0879518896093], [49.5746137884371, 14.0880387088065]],
    [[49.5746137884371, 14.0880387088065], [49.5746216455106, 14.0880439378788]],
    [[49.6054129900996, 14.0561407105126], [49.6053757403556, 14.056199342306]],
    [[49.6053757403556, 14.056199342306], [49.6054133467148, 14.0568995873956]],
    [[49.6054133467148, 14.0568995873956], [49.6054540398623, 14.0575885151373]],
    [[49.6054540398623, 14.0575885151373], [49.6054898760376, 14.0582780062362]],
    [[49.6054898760376, 14.0582780062362], [49.6055265043248, 14.0589673084395]],
    [[49.6055265043248, 14.0589673084395], [49.6055548539911, 14.0596578237882]],
    [[49.6055548539911, 14.0596578237882], [49.6055950083882, 14.0603475029255]],
    [[49.6055950083882, 14.0603475029255], [49.6056298088592, 14.0608006831522]],
    [[49.6056298088592, 14.0608006831522], [49.6056499817977, 14.0612595854911]],
    [[49.6056499817977, 14.0612595854911], [49.6056778883453, 14.0613635782546]],
    [[49.6054076934643, 14.0562231184695], [49.6054073579047, 14.0562168704618]],
    [[49.6054073579047, 14.0562168704618], [49.6054224605724, 14.0561937256403]],
    [[49.60570641619, 14.0613466306093], [49.6056801467822, 14.0612487385778]],
    [[49.6056801467822, 14.0612487385778], [49.6056602594077, 14.0607963328839]],
    [[49.6056602594077, 14.0607963328839], [49.6056254198095, 14.0603426430372]],
    [[49.6056254198095, 14.0603426430372], [49.6055853524465, 14.0596545497134]],
    [[49.6055853524465, 14.0596545497134], [49.6055569975998, 14.0589639083187]],
    [[49.6055569975998, 14.0589639083187], [49.6055203484889, 14.058274214257]],
    [[49.6055203484889, 14.058274214257], [49.6054845012881, 14.0575845108368]],
    [[49.6054845012881, 14.0575845108368], [49.6054438044703, 14.0568955211627]],
    [[49.6054438044703, 14.0568955211627], [49.6054076934643, 14.0562231184695]],
    [[49.5629399043832, 14.085388481529], [49.5628847962336, 14.0854435775619]],
    [[49.5895392178328, 14.0731302764079], [49.5894946992489, 14.0731671912044]],
    [[49.5894946992489, 14.0731671912044], [49.5894673326731, 14.0730911135242]],
    [[49.5894673326731, 14.0730911135242], [49.5895123982045, 14.0730537451289]],
    [[49.616735691654, 14.056212153025], [49.6169047856664, 14.0560986569505]],
    [[49.6169047856664, 14.0560986569505], [49.6169095434464, 14.055859644357]],
    [[49.5895038615004, 14.0730293852243], [49.5894475930379, 14.0730651767865]],
    [[49.5894475930379, 14.0730651767865], [49.5894954686218, 14.0731970755301]],
    [[49.5894954686218, 14.0731970755301], [49.5895475447411, 14.073154062937]],
    [[49.5628875072142, 14.0849989061258], [49.5628366033272, 14.0850322884435]],
    [[49.5628366033272, 14.0850322884435], [49.5628868817145, 14.0852236061298]],
    [[49.5628868817145, 14.0852236061298], [49.562897220769, 14.0852172211143]],
    [[49.5630926934004, 14.0854311909505], [49.5630729806667, 14.0854223751997]],
    [[49.5630729806667, 14.0854223751997], [49.5629855954634, 14.0851132553875]],
    [[49.5635807017371, 14.0856484252461], [49.5632591638013, 14.0855056386355]],
    [[49.6100691668357, 14.0598705680829], [49.609473630165, 14.0602245322203]],
    [[49.6116199481189, 14.0591584982351], [49.6112507098726, 14.0593745647093]],
    [[49.6112507098726, 14.0593745647093], [49.6106575679891, 14.059685426469]],
    [[49.6106575679891, 14.059685426469], [49.609806067991, 14.0601326875653]],
    [[49.609806067991, 14.0601326875653], [49.6090049457462, 14.060645013629]],
    [[49.6090049457462, 14.060645013629], [49.6089977703459, 14.0606167021371]],
    [[49.5338836150026, 14.0782695487678], [49.5334999913157, 14.0781446426497]],
    [[49.5334999913157, 14.0781446426497], [49.5331946185859, 14.0780042525931]],
    [[49.5331946185859, 14.0780042525931], [49.5330573292448, 14.0779485404358]],
    [[49.5397434267541, 14.0792336001471], [49.5393071479459, 14.0793038974957]],
    [[49.5393071479459, 14.0793038974957], [49.5385976282288, 14.0793443737965]],
    [[49.5385976282288, 14.0793443737965], [49.5378851942775, 14.0792976733206]],
    [[49.5378851942775, 14.0792976733206], [49.5369858021851, 14.0792379237532]],
    [[49.5369858021851, 14.0792379237532], [49.5361426251297, 14.0790800546019]],
    [[49.5361426251297, 14.0790800546019], [49.5353080678253, 14.0787991491499]],
    [[49.5353080678253, 14.0787991491499], [49.5347286036911, 14.0785762747698]],
    [[49.6080935910874, 14.0611271926272], [49.607366405966, 14.0616538421052]],
    [[49.607366405966, 14.0616538421052], [49.6065574084793, 14.0622567842995]],
    [[49.6065574084793, 14.0622567842995], [49.6063906578399, 14.062370967942]],
    [[49.5545661181013, 14.0810715401501], [49.5546476361501, 14.0807035751169]],
    [[49.5546476361501, 14.0807035751169], [49.5546867900329, 14.0807240717702]],
    [[49.5546867900329, 14.0807240717702], [49.5546059137846, 14.0810891397958]],
    [[49.6084253964272, 14.0610218282675], [49.6089667992317, 14.0606696738286]],
    [[49.6089667992317, 14.0606696738286], [49.6089577792845, 14.0606340844413]],
    [[49.5646064017303, 14.0865374680789], [49.5643896505135, 14.0856249642683]],
    [[49.5643896505135, 14.0856249642683], [49.5638695615742, 14.0853972901669]],
    [[49.5638695615742, 14.0853972901669], [49.5632591638013, 14.0855056386355]],
    [[49.5716778540974, 14.087464488108], [49.5713876878331, 14.0875081829443]],
    [[49.5713876878331, 14.0875081829443], [49.5711336066663, 14.0876085375972]],
    [[49.5330049454882, 14.0779181091985], [49.5323947366155, 14.0776551803247]],
    [[49.5323947366155, 14.0776551803247], [49.5324061482489, 14.0775926606807]],
    [[49.5244007232618, 14.0793097875626], [49.5242814110344, 14.0797830312991]],
    [[49.5242814110344, 14.0797830312991], [49.5241557508291, 14.0804462124736]],
    [[49.5241557508291, 14.0804462124736], [49.5240261225164, 14.0811073915274]],
    [[49.5240261225164, 14.0811073915274], [49.5239037829836, 14.081743494662]],
    [[49.5239037829836, 14.081743494662], [49.5237752262972, 14.082429073562]],
    [[49.5237752262972, 14.082429073562], [49.5236414319344, 14.083093043304]],
    [[49.5236414319344, 14.083093043304], [49.5235154224283, 14.08375627352]],
    [[49.5235154224283, 14.08375627352], [49.5233861436709, 14.0844173448844]],
    [[49.5233861436709, 14.0844173448844], [49.5232551536579, 14.0850774628773]],
    [[49.5232551536579, 14.0850774628773], [49.5231177978327, 14.0857401141829]],
    [[49.5231177978327, 14.0857401141829], [49.5230488857353, 14.0862323650321]],
    [[49.5247420141927, 14.0776296257068], [49.524893400201, 14.0771844370015]],
    [[49.524893400201, 14.0771844370015], [49.5251789145813, 14.0768097558046]],
    [[49.5251789145813, 14.0768097558046], [49.5255627828136, 14.0764467239738]],
    [[49.5255627828136, 14.0764467239738], [49.5259389899822, 14.0760909314485]],
    [[49.5259389899822, 14.0760909314485], [49.5259414878754, 14.0760395697408]],
    [[49.5246532361468, 14.0779641699442], [49.5246831391618, 14.0778027604524]],
    [[49.5246831391618, 14.0778027604524], [49.5247420141927, 14.0776296257068]],
    [[49.5244007232618, 14.0793097875626], [49.5244350060475, 14.0791327370432]],
    [[49.5244350060475, 14.0791327370432], [49.5245596521454, 14.0784693053409]],
    [[49.5245596521454, 14.0784693053409], [49.5246532361468, 14.0779641699442]],
    [[49.5228315927245, 14.0869251292435], [49.5230146365863, 14.086406765762]],
    [[49.5230146365863, 14.086406765762], [49.5230488857353, 14.0862323650322]],
    [[49.5230760241533, 14.0862567792828], [49.5230430020034, 14.0864249319057]],
    [[49.5230430020034, 14.0864249319057], [49.5228616881037, 14.0869383963701]],
    [[49.5246901960068, 14.0779362433614], [49.524589041072, 14.0784822447524]],
    [[49.524589041072, 14.0784822447524], [49.5244643447164, 14.0791459438373]],
    [[49.5244643447164, 14.0791459438373], [49.5244309648827, 14.0793183311656]],
    [[49.5247272174814, 14.0777745703455], [49.5247116865597, 14.0778202422611]],
    [[49.5247116865597, 14.0778202422611], [49.5246901960068, 14.0779362433614]],
    [[49.5259474483443, 14.0761380840861], [49.5255788100794, 14.0764867180439]],
    [[49.5255788100794, 14.0764867180439], [49.5251969652355, 14.0768478360142]],
    [[49.5251969652355, 14.0768478360142], [49.5249177575745, 14.0772142407298]],
    [[49.5249177575745, 14.0772142407298], [49.5247307336259, 14.0777642303776]],
    [[49.598698216591, 14.0696810650707], [49.5987181915586, 14.0695731444617]],
    [[49.5987181915586, 14.0695731444617], [49.598738010994, 14.0693858507593]],
    [[49.5725077953209, 14.0884218128143], [49.5725373480635, 14.0885024043179]],
    [[49.5725373480635, 14.0885024043179], [49.5725679924615, 14.0886707586694]],
    [[49.5725679924615, 14.0886707586694], [49.5726965744051, 14.0892335228498]],
    [[49.5726965744051, 14.0892335228498], [49.5727108604845, 14.0892893374961]],
    [[49.5727108604845, 14.0892893374961], [49.5728747112152, 14.0898952461053]],
    [[49.5728747112152, 14.0898952461053], [49.5729584713822, 14.0901992054689]],
    [[49.5729584713822, 14.0901992054689], [49.5729705892262, 14.0902475752739]],
    [[49.572511490992, 14.0884176795604], [49.572541632364, 14.0885000690689]],
    [[49.572541632364, 14.0885000690689], [49.5725722828405, 14.0886686800927]],
    [[49.5725722828405, 14.0886686800927], [49.5727007884705, 14.0892311102663]],
    [[49.5727007884705, 14.0892311102663], [49.572715028263, 14.0892867440723]],
    [[49.572715028263, 14.0892867440723], [49.5728788566975, 14.0898925702232]],
    [[49.5728788566975, 14.0898925702232], [49.5729626403566, 14.090196614834]],
    [[49.5729626403566, 14.090196614834], [49.572974719342, 14.0902449677828]],
    [[49.5816737338377, 14.0843462053536], [49.5816473355854, 14.084367045465]],
    [[49.5816473355854, 14.084367045465], [49.5816383643874, 14.0843401653713]],
    [[49.6207469251201, 14.0536291145657], [49.6207513008677, 14.0536266784935]],
    [[49.528950226994, 14.0767790268514], [49.5289501234025, 14.0767706708517]],
    [[49.5286755249242, 14.0761956270778], [49.5286117536133, 14.0764223530989]],
    [[49.5719322835942, 14.0873777662759], [49.572458793721, 14.0876030994758]],
    [[49.5725577700653, 14.0875911018289], [49.5726454226702, 14.0880117211599]],
    [[49.6025589091162, 14.0625624705592], [49.6024706732282, 14.0625531668391]],
    [[49.5560704292268, 14.0819968512348], [49.5561868854077, 14.0823396847924]],
    [[49.5268613070102, 14.0744639988078], [49.5267893714475, 14.0747218088019]],
    [[49.5290549393828, 14.0762735643357], [49.5290709341502, 14.0760011452868]],
    [[49.5315833556097, 14.0780375960969], [49.5317221033364, 14.0778666092285]],
    [[49.5380236242913, 14.0794137113487], [49.5385281584386, 14.0799033959711]],
    [[49.5387467133238, 14.0794436383865], [49.538940782972, 14.0799545791554]],
    [[49.5472432900988, 14.0791235604129], [49.5472414504501, 14.0790919169103]],
    [[49.5950380494752, 14.0689301953485], [49.5954585153873, 14.0683468024983]],
    [[49.597095626834, 14.0674627308022], [49.5970548169026, 14.067475166021]],
    [[49.5973403095712, 14.0662148212178], [49.597359906173, 14.0663068028943]],
    [[49.6037707258163, 14.0652101977226], [49.6037801111408, 14.065256634411]],
    [[49.604129214423, 14.0642900283197], [49.6040094784114, 14.0643907637993]],
    [[49.6209192080912, 14.0527319526022], [49.6209702686954, 14.0527840746417]],
    [[49.619746461936, 14.05700865852], [49.6196152515067, 14.0573032092174]],
    [[49.6196883241436, 14.0576106710307], [49.6196132707679, 14.0574887381216]],
    [[49.6193428152982, 14.0538063628349], [49.619324899283, 14.0539590419589]],
    [[49.6247258974724, 14.0516010516678], [49.6245038930772, 14.0515420000218]],
    [[49.5810420283457, 14.0847611932969], [49.5810425258804, 14.0848019568857]],
    [[49.6207975326839, 14.0536880796224], [49.6207653977037, 14.0537069481137]],
    [[49.60492105246, 14.0630326204182], [49.6049280455638, 14.0630305427649]],
    [[49.5735404645941, 14.087239836799], [49.5735275711869, 14.0872417007237]],
    [[49.6060378116277, 14.0625402291469], [49.6060243064356, 14.0625435513482]],
    [[49.6060243064356, 14.0625435513482], [49.6060053330123, 14.0625434677825]],
    [[49.6162660929296, 14.0563096977532], [49.6161261118995, 14.0563840285702]],
    [[49.6161261118995, 14.0563840285702], [49.6160619403638, 14.0564139060101]],
    [[49.5274165768152, 14.0746156527852], [49.5274891664035, 14.0746756862292]],
    [[49.5274891664035, 14.0746756862292], [49.5276072472394, 14.0747581447811]],
    [[49.5327515106696, 14.0788603652905], [49.5328780823345, 14.0789476969178]],
    [[49.5328780823345, 14.0789476969178], [49.5331084530185, 14.0791066488331]],
    [[49.5331084530185, 14.0791066488331], [49.5334174897076, 14.0793269850338]],
    [[49.5334174897076, 14.0793269850338], [49.5334656454914, 14.0793625098864]],
    [[49.5334656454914, 14.0793625098864], [49.5334750484427, 14.0792816109797]],
    [[49.5334750484427, 14.0792816109797], [49.5334750484427, 14.0792816109797]],
    [[49.5334750484427, 14.0792816109797], [49.5334881787733, 14.079096772489]],
    [[49.5334881787733, 14.079096772489], [49.5334913615796, 14.0790536546725]],
    [[49.5273484337621, 14.0747898949577], [49.5275857261342, 14.0749572163641]],
    [[49.5275857261342, 14.0749572163641], [49.5282688318857, 14.0754388295308]],
    [[49.5282688318857, 14.0754388295308], [49.5283819920504, 14.075518180933]],
    [[49.5283819920504, 14.075518180933], [49.52945664864, 14.0762715586331]],
    [[49.52945664864, 14.0762715586331], [49.5296914504656, 14.0764277174011]],
    [[49.5296914504656, 14.0764277174011], [49.5297438404084, 14.0764667764032]],
    [[49.5297438404084, 14.0764667764032], [49.5301962196183, 14.0767951437187]],
    [[49.5301962196183, 14.0767951437187], [49.5303071666896, 14.0768731709925]],
    [[49.5303071666896, 14.0768731709925], [49.5306313753647, 14.0771013278059]],
    [[49.5306313753647, 14.0771013278059], [49.5308541262536, 14.0772431046189]],
    [[49.5308541262536, 14.0772431046189], [49.5311254716426, 14.0774388669875]],
    [[49.5311254716426, 14.0774388669875], [49.5314844475288, 14.0776962699965]],
    [[49.5314844475288, 14.0776962699965], [49.5317221033364, 14.0778666092285]],
    [[49.5317221033364, 14.0778666092285], [49.5320065215208, 14.0780584043775]],
    [[49.5320065215208, 14.0780584043775], [49.5322372534512, 14.0782098872548]],
    [[49.5322372534512, 14.0782098872548], [49.5326281804684, 14.0784759463231]],
    [[49.5326281804684, 14.0784759463231], [49.5328264542579, 14.0786084328965]],
    [[49.5328264542579, 14.0786084328965], [49.5328654577628, 14.0786353517782]],
    [[49.5328654577628, 14.0786353517782], [49.5332815255578, 14.0789306691522]],
    [[49.5332815255578, 14.0789306691522], [49.5334913615796, 14.0790536546725]],
    [[49.5327515106696, 14.0788603652905], [49.5326131439879, 14.0787608330431]],
    [[49.5326131439879, 14.0787608330431], [49.5321206602122, 14.0784063130033]],
    [[49.5321206602122, 14.0784063130033], [49.5319014481148, 14.0782585909341]],
    [[49.5319014481148, 14.0782585909341], [49.5317537090539, 14.0781588780233]],
    [[49.5317537090539, 14.0781588780233], [49.5315833556097, 14.0780375960969]],
    [[49.5315833556097, 14.0780375960969], [49.5314973396501, 14.077976720266]],
    [[49.5314973396501, 14.077976720266], [49.5311207547826, 14.0777125594233]],
    [[49.5311207547826, 14.0777125594233], [49.5308335485061, 14.0775279374435]],
    [[49.5308335485061, 14.0775279374435], [49.5308323519662, 14.0774620573822]],
    [[49.5308323519662, 14.0774620573822], [49.5303867508067, 14.0771381984648]],
    [[49.5303867508067, 14.0771381984648], [49.530298739741, 14.0771420886922]],
    [[49.530298739741, 14.0771420886922], [49.5297196365391, 14.076727140102]],
    [[49.5297196365391, 14.076727140102], [49.5293878339266, 14.0765033011502]],
    [[49.5293878339266, 14.0765033011502], [49.5290879058032, 14.0762931480425]],
    [[49.5290879058032, 14.0762931480425], [49.5290549393828, 14.0762735643357]],
    [[49.5290549393828, 14.0762735643357], [49.529035055237, 14.0762618648309]],
    [[49.529035055237, 14.0762618648309], [49.5281971924031, 14.0756802550782]],
    [[49.5281971924031, 14.0756802550782], [49.5279702083465, 14.075529092804]],
    [[49.5279702083465, 14.075529092804], [49.5275398357325, 14.0752422641752]],
    [[49.5275398357325, 14.0752422641752], [49.5273534067922, 14.0751180984205]],
    [[49.5557481328407, 14.0813699608533], [49.5557192776794, 14.0815331906141]],
    [[49.5557192776794, 14.0815331906141], [49.5557075265686, 14.0815995612634]],
    [[49.5557075265686, 14.0815995612634], [49.5556990124509, 14.0818059505023]],
    [[49.5556647872365, 14.0817884427727], [49.5556634381395, 14.0814754543036]],
    [[49.5556634381395, 14.0814754543036], [49.5556467635149, 14.0814208827311]],
    [[49.5576525961644, 14.0827011322099], [49.5574154020609, 14.0825699510933]],
    [[49.5574154020609, 14.0825699510933], [49.5570046577901, 14.0823093156383]],
    [[49.5570046577901, 14.0823093156383], [49.5567709703657, 14.0821532184965]],
    [[49.5567709703657, 14.0821532184965], [49.556438242127, 14.0819209234201]],
    [[49.5535438095998, 14.0813772189756], [49.5537465538857, 14.0814835274854]],
    [[49.5537465538857, 14.0814835274854], [49.5538498274984, 14.0815356381485]],
    [[49.5538498274984, 14.0815356381485], [49.5539399677524, 14.0815812667091]],
    [[49.5539399677524, 14.0815812667091], [49.5540461018973, 14.0816348464853]],
    [[49.5540461018973, 14.0816348464853], [49.5541573162321, 14.0817040684801]],
    [[49.5541573162321, 14.0817040684801], [49.5542453927013, 14.0817588043249]],
    [[49.5542453927013, 14.0817588043249], [49.5543145050672, 14.0817921383882]],
    [[49.5543145050672, 14.0817921383882], [49.5543936104429, 14.0818304078169]],
    [[49.5543936104429, 14.0818304078169], [49.5544956679997, 14.081776126758]],
    [[49.5544956679997, 14.081776126758], [49.5545496592239, 14.0817500835773]],
    [[49.5545496592239, 14.0817500835773], [49.5546054949754, 14.0817223806345]],
    [[49.5546054949754, 14.0817223806345], [49.5547599776917, 14.0816467258626]],
    [[49.5547599776917, 14.0816467258626], [49.5548324747576, 14.0816116107773]],
    [[49.5548324747576, 14.0816116107773], [49.5549064472016, 14.0815757542129]],
    [[49.5549064472016, 14.0815757542129], [49.5550306461654, 14.0815153749128]],
    [[49.5550306461654, 14.0815153749128], [49.5550639439012, 14.0814992999451]],
    [[49.5550639439012, 14.0814992999451], [49.555035591734, 14.0814863721741]],
    [[49.555035591734, 14.0814863721741], [49.5549279042091, 14.0814375986702]],
    [[49.5549279042091, 14.0814375986702], [49.5548592613928, 14.0814063957308]],
    [[49.5548592613928, 14.0814063957308], [49.5546596008472, 14.0813157449013]],
    [[49.5546596008472, 14.0813157449013], [49.5545651739981, 14.0812728681886]],
    [[49.5545651739981, 14.0812728681886], [49.5545133136071, 14.0812493047067]],
    [[49.5545133136071, 14.0812493047067], [49.5545182119744, 14.0811471589077]],
    [[49.5545182119744, 14.0811471589077], [49.5544853043923, 14.0810457398971]],
    [[49.5544853043923, 14.0810457398971], [49.5544193599217, 14.0809759750028]],
    [[49.5544193599217, 14.0809759750028], [49.5543976167577, 14.0809716543755]],
    [[49.5543976167577, 14.0809716543755], [49.5542008093632, 14.0809324551099]],
    [[49.5542008093632, 14.0809324551099], [49.5540889693663, 14.0809006460537]],
    [[49.5540889693663, 14.0809006460537], [49.5539798249523, 14.0808695042409]],
    [[49.5539798249523, 14.0808695042409], [49.5538804709966, 14.080841108206]],
    [[49.5538804709966, 14.080841108206], [49.5537753444367, 14.0808111822818]],
    [[49.5537753444367, 14.0808111822818], [49.5536613556047, 14.0807787267456]],
    [[49.5536613556047, 14.0807787267456], [49.5535517495239, 14.0807573190474]],
    [[49.5535517495239, 14.0807573190474], [49.553257516967, 14.0806997744258]],
    [[49.553257516967, 14.0806997744258], [49.5531159954242, 14.0806720826159]],
    [[49.5531159954242, 14.0806720826159], [49.5530947226812, 14.0806679385897]],
    [[49.5530947226812, 14.0806679385897], [49.5528726660818, 14.0805178340841]],
    [[49.5528726660818, 14.0805178340841], [49.5526544299673, 14.0804178499877]],
    [[49.5526544299673, 14.0804178499877], [49.5524840875064, 14.0803397827648]],
    [[49.5524840875064, 14.0803397827648], [49.5523373511465, 14.0802822413228]],
    [[49.5523373511465, 14.0802822413228], [49.5521708438486, 14.0802171569165]],
    [[49.5521708438486, 14.0802171569165], [49.5520671836498, 14.0801765823767]],
    [[49.5520671836498, 14.0801765823767], [49.5518980190635, 14.0801102648755]],
    [[49.5518980190635, 14.0801102648755], [49.5518980910192, 14.0800560847424]],
    [[49.5518980910192, 14.0800560847424], [49.5517191534719, 14.0799744548276]],
    [[49.5517191534719, 14.0799744548276], [49.5516829921688, 14.0799644925688]],
    [[49.5516829921688, 14.0799644925688], [49.5515106538467, 14.079916878335]],
    [[49.5515106538467, 14.079916878335], [49.5513449399618, 14.0798711663933]],
    [[49.5513449399618, 14.0798711663933], [49.5513379467536, 14.0798692054621]],
    [[49.5513379467536, 14.0798692054621], [49.5511289010354, 14.0798096552975]],
    [[49.5511289010354, 14.0798096552975], [49.550985235373, 14.079768615955]],
    [[49.550985235373, 14.079768615955], [49.5507999665169, 14.0797157336615]],
    [[49.5507999665169, 14.0797157336615], [49.5506081718434, 14.0796688852292]],
    [[49.5506081718434, 14.0796688852292], [49.5504777911596, 14.0796369472333]],
    [[49.5504777911596, 14.0796369472333], [49.5499835304038, 14.0795466499097]],
    [[49.5499835304038, 14.0795466499097], [49.549932421049, 14.0794870506486]],
    [[49.549932421049, 14.0794870506486], [49.5497781150714, 14.0794703953578]],
    [[49.5497781150714, 14.0794703953578], [49.5497418751125, 14.0794664535482]],
    [[49.5497418751125, 14.0794664535482], [49.5497519294298, 14.0791452851932]],
    [[49.5497519294298, 14.0791452851932], [49.5496862508864, 14.0791421966279]],
    [[49.5496862508864, 14.0791421966279], [49.5496366683965, 14.0792796483702]],
    [[49.5496366683965, 14.0792796483702], [49.5496219394937, 14.0793550381809]],
    [[49.5496219394937, 14.0793550381809], [49.5495939263193, 14.0793860105708]],
    [[49.5495939263193, 14.0793860105708], [49.5495791818537, 14.0794023560462]],
    [[49.5495791818537, 14.0794023560462], [49.5495122678036, 14.0794045626033]],
    [[49.5495122678036, 14.0794045626033], [49.5493635995456, 14.079409568133]],
    [[49.5493635995456, 14.079409568133], [49.5493531471501, 14.0794085032599]],
    [[49.5493531471501, 14.0794085032599], [49.5493303349137, 14.0794063717871]],
    [[49.5493303349137, 14.0794063717871], [49.5491637066954, 14.0793900380458]],
    [[49.5491637066954, 14.0793900380458], [49.5488485365427, 14.0793476932843]],
    [[49.5488485365427, 14.0793476932843], [49.5487615360472, 14.0793360052748]],
    [[49.5487615360472, 14.0793360052748], [49.5486490660753, 14.0793209522124]],
    [[49.5486490660753, 14.0793209522124], [49.5485479893696, 14.0793073166981]],
    [[49.5485479893696, 14.0793073166981], [49.5484772392117, 14.0792984969625]],
    [[49.5484772392117, 14.0792984969625], [49.5481853501966, 14.0792623698516]],
    [[49.5481853501966, 14.0792623698516], [49.5480820859337, 14.0792496316334]],
    [[49.5480820859337, 14.0792496316334], [49.5479062277517, 14.0792277791118]],
    [[49.5479062277517, 14.0792277791118], [49.5475739296205, 14.0791828998679]],
    [[49.5475739296205, 14.0791828998679], [49.5475054660723, 14.0791801411108]],
    [[49.5475054660723, 14.0791801411108], [49.5473420004894, 14.0791734466211]],
    [[49.5473420004894, 14.0791734466211], [49.5472900979408, 14.0791258891629]],
    [[49.5472900979408, 14.0791258891629], [49.5472432900988, 14.0791235604129]],
    [[49.5472432900988, 14.0791235604129], [49.5471685577902, 14.0791197993877]],
    [[49.5471685577902, 14.0791197993877], [49.5471107123898, 14.0791169525796]],
    [[49.5471107123898, 14.0791169525796], [49.547064889372, 14.0791331129656]],
    [[49.547064889372, 14.0791331129656], [49.5470568007001, 14.0791359981322]],
    [[49.5470568007001, 14.0791359981322], [49.546921583258, 14.0791155901797]],
    [[49.546921583258, 14.0791155901797], [49.5467979751194, 14.0790969712406]],
    [[49.5467979751194, 14.0790969712406], [49.5467068915168, 14.0790882706289]],
    [[49.5467068915168, 14.0790882706289], [49.5464250067262, 14.0790611254254]],
    [[49.5464250067262, 14.0790611254254], [49.5463133870686, 14.0790503544399]],
    [[49.5463133870686, 14.0790503544399], [49.546213479148, 14.0790395353974]],
    [[49.546213479148, 14.0790395353974], [49.5461288810765, 14.0790302545233]],
    [[49.5461288810765, 14.0790302545233], [49.5460307152198, 14.0790196130146]],
    [[49.5460307152198, 14.0790196130146], [49.5459281370194, 14.0790084008348]],
    [[49.5459281370194, 14.0790084008348], [49.5458170391905, 14.0789963993291]],
    [[49.5458170391905, 14.0789963993291], [49.5457352004107, 14.0789874923971]],
    [[49.5457352004107, 14.0789874923971], [49.5455361093868, 14.0789658359672]],
    [[49.5455361093868, 14.0789658359672], [49.5454505942777, 14.0789603849384]],
    [[49.5454505942777, 14.0789603849384], [49.5453454329591, 14.0789536460184]],
    [[49.5453454329591, 14.0789536460184], [49.5452221894544, 14.0789458355967]],
    [[49.5452221894544, 14.0789458355967], [49.5451536346439, 14.0789489595986]],
    [[49.5451536346439, 14.0789489595986], [49.5450612872327, 14.078953098019]],
    [[49.5450612872327, 14.078953098019], [49.5449338422243, 14.0789587683584]],
    [[49.5449338422243, 14.0789587683584], [49.5448621210003, 14.0789620262801]],
    [[49.5448621210003, 14.0789620262801], [49.5448196535872, 14.078948699155]],
    [[49.5448196535872, 14.078948699155], [49.544658023077, 14.0788980560623]],
    [[49.544658023077, 14.0788980560623], [49.544563831845, 14.0788685405526]],
    [[49.544563831845, 14.0788685405526], [49.5445053692039, 14.0788501963792]],
    [[49.5445053692039, 14.0788501963792], [49.5441462831506, 14.0787906615261]],
    [[49.5441462831506, 14.0787906615261], [49.5440071398079, 14.0787945629051]],
    [[49.5440071398079, 14.0787945629051], [49.5439733994413, 14.0787715118656]],
    [[49.5439733994413, 14.0787715118656], [49.5439614706106, 14.0790230673787]],
    [[49.5432603860492, 14.0790605799045], [49.5432616353654, 14.0789904897318]],
    [[49.5432616353654, 14.0789904897318], [49.5431501539062, 14.0790175139599]],
    [[49.5431501539062, 14.0790175139599], [49.5430269811718, 14.0790242039076]],
    [[49.5430269811718, 14.0790242039076], [49.5428519658298, 14.0790261767169]],
    [[49.5428519658298, 14.0790261767169], [49.5427351151124, 14.0790275764389]],
    [[49.5427351151124, 14.0790275764389], [49.542528166346, 14.0790368096011]],
    [[49.542528166346, 14.0790368096011], [49.542488669818, 14.0790375996069]],
    [[49.542488669818, 14.0790375996069], [49.5424178387868, 14.0790390163589]],
    [[49.5424178387868, 14.0790390163589], [49.5422334381784, 14.0790426221426]],
    [[49.5422334381784, 14.0790426221426], [49.5420512247429, 14.0790463080287]],
    [[49.5420512247429, 14.0790463080287], [49.541732404124, 14.0790724609683]],
    [[49.541732404124, 14.0790724609683], [49.5417335826277, 14.0790498721141]],
    [[49.5417335826277, 14.0790498721141], [49.5417185301632, 14.079073679598]],
    [[49.5417185301632, 14.079073679598], [49.5416156144854, 14.0790804069597]],
    [[49.5416156144854, 14.0790804069597], [49.5414548254515, 14.0790908536881]],
    [[49.5414548254515, 14.0790908536881], [49.5413134025252, 14.0791034868613]],
    [[49.5413134025252, 14.0791034868613], [49.5410071450987, 14.0791309400435]],
    [[49.5410071450987, 14.0791309400435], [49.5408516999603, 14.0791333794324]],
    [[49.5408516999603, 14.0791333794324], [49.5407321785785, 14.0791353626441]],
    [[49.5407321785785, 14.0791353626441], [49.5406955825036, 14.0791314996296]],
    [[49.5406955825036, 14.0791314996296], [49.540583975853, 14.0791198894414]],
    [[49.540583975853, 14.0791198894414], [49.5404213318822, 14.0790926396933]],
    [[49.5404213318822, 14.0790926396933], [49.5403944797598, 14.0790802265852]],
    [[49.5403944797598, 14.0790802265852], [49.5403847153174, 14.0790758015534]],
    [[49.5403847153174, 14.0790758015534], [49.5403538437984, 14.0790327251839]],
    [[49.5403538437984, 14.0790327251839], [49.5403549563436, 14.0789829359821]],
    [[49.5403549563436, 14.0789829359821], [49.5403487456631, 14.0786607792454]],
    [[49.5403487456631, 14.0786607792454], [49.5403508484755, 14.0786148209379]],
    [[49.5403508484755, 14.0786148209379], [49.5402925825259, 14.0786143000815]],
    [[49.5402925825259, 14.0786143000815], [49.5402826360243, 14.0788894657056]],
    [[49.5402826360243, 14.0788894657056], [49.5402556503535, 14.079161098504]],
    [[49.5402556503535, 14.079161098504], [49.5402277225115, 14.0794009762773]],
    [[49.5402277225115, 14.0794009762773], [49.54013316171, 14.0792742661326]],
    [[49.54013316171, 14.0792742661326], [49.5398109819406, 14.0793386936462]],
    [[49.5398109819406, 14.0793386936462], [49.5397247486534, 14.0793490307493]],
    [[49.5397247486534, 14.0793490307493], [49.5396602612284, 14.079356847229]],
    [[49.5396602612284, 14.079356847229], [49.5390967754451, 14.0794243374917]],
    [[49.5390967754451, 14.0794243374917], [49.539080345177, 14.0794263938699]],
    [[49.539080345177, 14.0794263938699], [49.5387467133238, 14.0794436383865]],
    [[49.5387467133238, 14.0794436383865], [49.5385726919758, 14.0794730252254]],
    [[49.5385726919758, 14.0794730252254], [49.5380236242913, 14.0794137113487]],
    [[49.5380236242913, 14.0794137113487], [49.5372830680198, 14.0793707168253]],
    [[49.5372830680198, 14.0793707168253], [49.5369463476658, 14.0793506768117]],
    [[49.5369463476658, 14.0793506768117], [49.5366551830067, 14.0792821619445]],
    [[49.5366551830067, 14.0792821619445], [49.5360510339229, 14.0791743260321]],
    [[49.5360510339229, 14.0791743260321], [49.5353065697383, 14.0789076522324]],
    [[49.5353065697383, 14.0789076522324], [49.534871404509, 14.0787739104121]],
    [[49.534871404509, 14.0787739104121], [49.5348437243436, 14.0787653081835]],
    [[49.5348437243436, 14.0787653081835], [49.5346876115379, 14.0787173771764]],
    [[49.5346876115379, 14.0787173771764], [49.5343433448179, 14.0785432554386]],
    [[49.5343433448179, 14.0785432554386], [49.5341727298587, 14.0784760283288]],
    [[49.5341727298587, 14.0784760283288], [49.5339896197233, 14.0784631658179]],
    [[49.5339896197233, 14.0784631658179], [49.5337986075872, 14.078396351235]],
    [[49.5337986075872, 14.078396351235], [49.5336624771013, 14.0783347047517]],
    [[49.5336624771013, 14.0783347047517], [49.5336295855569, 14.0783198461482]],
    [[49.5336295855569, 14.0783198461482], [49.5334519305819, 14.0782393677252]],
    [[49.5334519305819, 14.0782393677252], [49.5333449027403, 14.0781908969103]],
    [[49.5333449027403, 14.0781908969103], [49.5333106888848, 14.0781754902677]],
    [[49.5333106888848, 14.0781754902677], [49.5332057125191, 14.0781158262489]],
    [[49.5332057125191, 14.0781158262489], [49.5331394745115, 14.0780782549562]],
    [[49.5331394745115, 14.0780782549562], [49.5330416755026, 14.0780382333136]],
    [[49.5330416755026, 14.0780382333136], [49.532999959583, 14.0780212572454]],
    [[49.532999959583, 14.0780212572454], [49.532960316122, 14.0780050840816]],
    [[49.532960316122, 14.0780050840816], [49.5326705171049, 14.077886606225]],
    [[49.5326705171049, 14.077886606225], [49.5324372715526, 14.0777913509072]],
    [[49.5324372715526, 14.0777913509072], [49.5322572820897, 14.0776954786589]],
    [[49.5322572820897, 14.0776954786589], [49.5319439371933, 14.0775287066418]],
    [[49.5319439371933, 14.0775287066418], [49.531742033677, 14.0774080438589]],
    [[49.531742033677, 14.0774080438589], [49.5316797373509, 14.0773708688082]],
    [[49.5316797373509, 14.0773708688082], [49.5315026566913, 14.0772828756538]],
    [[49.5315026566913, 14.0772828756538], [49.5314506818007, 14.0772571196479]],
    [[49.5314506818007, 14.0772571196479], [49.5311465723508, 14.077079263998]],
    [[49.5311465723508, 14.077079263998], [49.5308738525961, 14.076919665533]],
    [[49.5308738525961, 14.076919665533], [49.5307622778104, 14.0768544725556]],
    [[49.5307622778104, 14.0768544725556], [49.5306677503584, 14.0768036932031]],
    [[49.5306677503584, 14.0768036932031], [49.5304787080539, 14.0767022718562]],
    [[49.5304787080539, 14.0767022718562], [49.5304439736063, 14.0766488869408]],
    [[49.5304439736063, 14.0766488869408], [49.5303462391887, 14.0766046699835]],
    [[49.5303462391887, 14.0766046699835], [49.5302254792093, 14.0765501377454]],
    [[49.5302254792093, 14.0765501377454], [49.5301028247744, 14.076494764089]],
    [[49.5301028247744, 14.076494764089], [49.5299342753376, 14.0763733763742]],
    [[49.5299342753376, 14.0763733763742], [49.5297787728381, 14.0762516488307]],
    [[49.5297787728381, 14.0762516488307], [49.5289207840375, 14.0756586631226]],
    [[49.5289207840375, 14.0756586631226], [49.5287332038952, 14.0755609251936]],
    [[49.5287332038952, 14.0755609251936], [49.5286202760948, 14.0755020849695]],
    [[49.5286202760948, 14.0755020849695], [49.5281675202635, 14.0751491245478]],
    [[49.5281675202635, 14.0751491245478], [49.5276072472394, 14.0747581447811]],
    [[49.5274165768152, 14.0746156527852], [49.5273861807202, 14.0745904857516]],
    [[49.5273861807202, 14.0745904857516], [49.5273808668126, 14.0745860663881]],
    [[49.5273808668126, 14.0745860663881], [49.5273241045468, 14.0745390372083]],
    [[49.5273241045468, 14.0745390372083], [49.5268903610095, 14.0742482136308]],
    [[49.5268903610095, 14.0742482136308], [49.5268613070102, 14.0744639988078]],
    [[49.5268613070102, 14.0744639988078], [49.5273141464583, 14.0747648809748]],
    [[49.5273141464583, 14.0747648809748], [49.5273484337621, 14.0747898949577]],
    [[49.5273534067922, 14.0751180984205], [49.5273352769329, 14.0751076905846]],
    [[49.5273352769329, 14.0751076905846], [49.527259782464, 14.0750643377467]],
    [[49.527259782464, 14.0750643377467], [49.5272211985666, 14.0750351004059]],
    [[49.5272211985666, 14.0750351004059], [49.5270786589461, 14.0749317578802]],
    [[49.5270786589461, 14.0749317578802], [49.5267893714475, 14.0747218088019]],
    [[49.5267893714475, 14.0747218088019], [49.5264196600524, 14.0744535430146]],
    [[49.5264196600524, 14.0744535430146], [49.5260983711394, 14.0742246443587]],
    [[49.5260983711394, 14.0742246443587], [49.5259661278986, 14.0741304963089]],
    [[49.5259661278986, 14.0741304963089], [49.5259625230373, 14.0742984368688]],
    [[49.5259625230373, 14.0742984368688], [49.5259592160045, 14.074449987679]],
    [[49.5259592160045, 14.074449987679], [49.5259598620264, 14.0746059761404]],
    [[49.5259598620264, 14.0746059761404], [49.5260959030778, 14.0747117129096]],
    [[49.5260959030778, 14.0747117129096], [49.5264184668656, 14.0748916407998]],
    [[49.5264184668656, 14.0748916407998], [49.5264150131398, 14.0749504392493]],
    [[49.5264150131398, 14.0749504392493], [49.5263561940881, 14.0749312075981]],
    [[49.5263561940881, 14.0749312075981], [49.5262667903382, 14.0749543816052]],
    [[49.5262667903382, 14.0749543816052], [49.5260890720749, 14.0751232743941]],
    [[49.5260890720749, 14.0751232743941], [49.5260288912596, 14.0751805216542]],
    [[49.5260288912596, 14.0751805216542], [49.5259590233347, 14.0753028127629]],
    [[49.5259590233347, 14.0753028127629], [49.5259533424613, 14.0753297274792]],
    [[49.5259533424613, 14.0753297274792], [49.5259269375474, 14.0754554922859]],
    [[49.5259269375474, 14.0754554922859], [49.5259308708712, 14.0755891352261]],
    [[49.5259308708712, 14.0755891352261], [49.5259467179171, 14.0756543175174]],
    [[49.5259467179171, 14.0756543175174], [49.5259609142837, 14.0757124658337]],
    [[49.5259609142837, 14.0757124658337], [49.5259841851946, 14.0757525253646]],
    [[49.5260534112437, 14.0758470220269], [49.5260853379343, 14.0758722302967]],
    [[49.5260853379343, 14.0758722302967], [49.5260916103139, 14.0760066180038]],
    [[49.5260916103139, 14.0760066180038], [49.5260928975683, 14.0760342418206]],
    [[49.5260928975683, 14.0760342418206], [49.5261076573752, 14.0760808260684]],
    [[49.5261076573752, 14.0760808260684], [49.5261655613993, 14.0760264495912]],
    [[49.5261655613993, 14.0760264495912], [49.5268495696878, 14.0753843838496]],
    [[49.5268495696878, 14.0753843838496], [49.5269575677287, 14.0752828807633]],
    [[49.5269575677287, 14.0752828807633], [49.5269975366492, 14.0753119900113]],
    [[49.5269975366492, 14.0753119900113], [49.5270607197133, 14.0753244080562]],
    [[49.5270607197133, 14.0753244080562], [49.5271487390092, 14.0753696273325]],
    [[49.5271487390092, 14.0753696273325], [49.527334798586, 14.0754947114039]],
    [[49.527334798586, 14.0754947114039], [49.5275098865305, 14.0756121488477]],
    [[49.5275098865305, 14.0756121488477], [49.5276441432031, 14.0756907941379]],
    [[49.5276441432031, 14.0756907941379], [49.5282849976628, 14.0761497012787]],
    [[49.5282849976628, 14.0761497012787], [49.5284549333297, 14.0762713392755]],
    [[49.5284549333297, 14.0762713392755], [49.5290003890916, 14.0767966038826]],
    [[49.5290003890916, 14.0767966038826], [49.5290284951321, 14.0768235294386]],
    [[49.5291447141092, 14.0769163094142], [49.5294948848379, 14.0771570649921]],
    [[49.5294948848379, 14.0771570649921], [49.529818712488, 14.0773477677924]],
    [[49.529818712488, 14.0773477677924], [49.529913108619, 14.0773745756698]],
    [[49.529913108619, 14.0773745756698], [49.5299966901384, 14.077417003676]],
    [[49.5299966901384, 14.077417003676], [49.5303950523367, 14.0777329088808]],
    [[49.5303950523367, 14.0777329088808], [49.530496749229, 14.0777895180305]],
    [[49.530496749229, 14.0777895180305], [49.531015111743, 14.0780782222385]],
    [[49.531015111743, 14.0780782222385], [49.5310855001433, 14.0781027447268]],
    [[49.5310855001433, 14.0781027447268], [49.5314259154084, 14.0782744843939]],
    [[49.5314259154084, 14.0782744843939], [49.531487602058, 14.0783090027913]],
    [[49.531487602058, 14.0783090027913], [49.531573889673, 14.0783571215326]],
    [[49.531573889673, 14.0783571215326], [49.5315978295015, 14.0783704476939]],
    [[49.5315978295015, 14.0783704476939], [49.5324213492229, 14.0787463795319]],
    [[49.5324213492229, 14.0787463795319], [49.5325993945992, 14.0788359826154]],
    [[49.5325993945992, 14.0788359826154], [49.5326285475418, 14.0788506816055]],
    [[49.5326285475418, 14.0788506816055], [49.5327515106696, 14.0788603652905]],
    [[49.5334913615796, 14.0790536546725], [49.5334935859956, 14.0790227473293]],
    [[49.5334935859956, 14.0790227473293], [49.533711090445, 14.0791441971719]],
    [[49.533711090445, 14.0791441971719], [49.5340442115863, 14.0792322834077]],
    [[49.5340442115863, 14.0792322834077], [49.5342279056222, 14.0792808851551]],
    [[49.5342279056222, 14.0792808851551], [49.5345819998447, 14.0794248139769]],
    [[49.5345819998447, 14.0794248139769], [49.5348364767211, 14.0794963219933]],
    [[49.5348364767211, 14.0794963219933], [49.5348614742697, 14.0795034177182]],
    [[49.5348614742697, 14.0795034177182], [49.5351050416734, 14.0795718685654]],
    [[49.5351050416734, 14.0795718685654], [49.5361842960946, 14.0797161126079]],
    [[49.5361842960946, 14.0797161126079], [49.5369496770455, 14.0798182998752]],
    [[49.5369496770455, 14.0798182998752], [49.5372076370974, 14.0798263909069]],
    [[49.5372076370974, 14.0798263909069], [49.5373734518885, 14.0798417836608]],
    [[49.5373734518885, 14.0798417836608], [49.5385281584386, 14.0799033959711]],
    [[49.5385281584386, 14.0799033959711], [49.538940782972, 14.0799545791554]],
    [[49.538940782972, 14.0799545791554], [49.5390736210449, 14.0800110946331]],
    [[49.5390736210449, 14.0800110946331], [49.5390867820837, 14.0800119860464]],
    [[49.5390867820837, 14.0800119860464], [49.5393098712618, 14.0800270027641]],
    [[49.5393098712618, 14.0800270027641], [49.5393985401054, 14.0800321843576]],
    [[49.5393985401054, 14.0800321843576], [49.5399438032126, 14.0800641462827]],
    [[49.5399438032126, 14.0800641462827], [49.5400186878613, 14.0800685727731]],
    [[49.5400186878613, 14.0800685727731], [49.5400788181787, 14.0801795025223]],
    [[49.5400788181787, 14.0801795025223], [49.5400739746036, 14.0802086138224]],
    [[49.5400739746036, 14.0802086138224], [49.5400419157533, 14.080308711142]],
    [[49.5400419157533, 14.080308711142], [49.5400493866953, 14.0803246633251]],
    [[49.5400493866953, 14.0803246633251], [49.5400852809532, 14.0803994400592]],
    [[49.5400852809532, 14.0803994400592], [49.5401245929068, 14.080481704205]],
    [[49.5401245929068, 14.080481704205], [49.5401532798232, 14.0803873682447]],
    [[49.5401532798232, 14.0803873682447], [49.5402722713085, 14.0801147466827]],
    [[49.5402722713085, 14.0801147466827], [49.5402831337792, 14.0800751082675]],
    [[49.5402831337792, 14.0800751082675], [49.5403627104929, 14.0800801839714]],
    [[49.5403627104929, 14.0800801839714], [49.5404308682345, 14.080084545707]],
    [[49.5404308682345, 14.080084545707], [49.5405579215476, 14.0800726840364]],
    [[49.5405579215476, 14.0800726840364], [49.5406657312076, 14.0800707509788]],
    [[49.5406657312076, 14.0800707509788], [49.5408253983857, 14.0800678104084]],
    [[49.5408253983857, 14.0800678104084], [49.5409828687696, 14.0800568363834]],
    [[49.5409828687696, 14.0800568363834], [49.5410615594496, 14.0800513590731]],
    [[49.5410615594496, 14.0800513590731], [49.5411910658527, 14.080042450137]],
    [[49.5411910658527, 14.080042450137], [49.5411910658527, 14.080042450137]],
    [[49.5411910658527, 14.080042450137], [49.5414201408731, 14.0800338281049]],
    [[49.5414201408731, 14.0800338281049], [49.5414481934907, 14.080032720498]],
    [[49.5414481934907, 14.080032720498], [49.5416017253835, 14.0800125581344]],
    [[49.5416017253835, 14.0800125581344], [49.5419969049865, 14.0799606476073]],
    [[49.5419969049865, 14.0799606476073], [49.5422174119914, 14.0799448258445]],
    [[49.5422174119914, 14.0799448258445], [49.5423551843559, 14.0799349468979]],
    [[49.5423551843559, 14.0799349468979], [49.5424527185113, 14.0799348409453]],
    [[49.5424527185113, 14.0799348409453], [49.5426086586589, 14.079934808106]],
    [[49.5426086586589, 14.079934808106], [49.542869591149, 14.0799181046125]],
    [[49.542869591149, 14.0799181046125], [49.5430766925537, 14.0798761815161]],
    [[49.5430766925537, 14.0798761815161], [49.5432439921798, 14.0798006679273]],
    [[49.5432439921798, 14.0798006679273], [49.5432780905224, 14.0797853976689]],
    [[49.5432780905224, 14.0797853976689], [49.5433473048082, 14.0797540763334]],
    [[49.5433473048082, 14.0797540763334], [49.5433799151022, 14.0797394104562]],
    [[49.5433799151022, 14.0797394104562], [49.5434301278746, 14.079716709271]],
    [[49.5434301278746, 14.079716709271], [49.5436108738913, 14.0797589876846]],
    [[49.5436108738913, 14.0797589876846], [49.5437594322182, 14.0797753630216]],
    [[49.5437594322182, 14.0797753630216], [49.5437697955921, 14.0797764473172]],
    [[49.5439686935283, 14.0811943285885], [49.5440065872645, 14.0811965139821]],
    [[49.5440065872645, 14.0811965139821], [49.544105888585, 14.0812292434381]],
    [[49.544105888585, 14.0812292434381], [49.544105888585, 14.0812292434381]],
    [[49.544105888585, 14.0812292434381], [49.5443123840461, 14.0812092313792]],
    [[49.5443123840461, 14.0812092313792], [49.5444223461873, 14.0812305582903]],
    [[49.5444223461873, 14.0812305582903], [49.544449251846, 14.0812357038269]],
    [[49.544449251846, 14.0812357038269], [49.5444573197743, 14.0811256282986]],
    [[49.5444573197743, 14.0811256282986], [49.5444338622006, 14.0811155418074]],
    [[49.5444338622006, 14.0811155418074], [49.5441583686504, 14.0810063139206]],
    [[49.5441583686504, 14.0810063139206], [49.544096877809, 14.0809611327759]],
    [[49.544096877809, 14.0809611327759], [49.5440597982033, 14.0808941457834]],
    [[49.5440597982033, 14.0808941457834], [49.5440431951408, 14.0808187748261]],
    [[49.5440431951408, 14.0808187748261], [49.5440332699555, 14.0801885240463]],
    [[49.5440332699555, 14.0801885240463], [49.5440174560457, 14.0799794061518]],
    [[49.5440174560457, 14.0799794061518], [49.5440219728272, 14.0799075139305]],
    [[49.5440219728272, 14.0799075139305], [49.5440136698852, 14.0798070194867]],
    [[49.5440136698852, 14.0798070194867], [49.5444011144601, 14.0797637744513]],
    [[49.5444011144601, 14.0797637744513], [49.5445282340465, 14.0797447779554]],
    [[49.5445282340465, 14.0797447779554], [49.5446666336791, 14.0797240132767]],
    [[49.5446666336791, 14.0797240132767], [49.5446945857355, 14.0797198566665]],
    [[49.5446945857355, 14.0797198566665], [49.5447668161646, 14.0797152323505]],
    [[49.5447668161646, 14.0797152323505], [49.5447870356193, 14.0797138828154]],
    [[49.5447870356193, 14.0797138828154], [49.5448614278952, 14.0797090650268]],
    [[49.5448614278952, 14.0797090650268], [49.5449215434445, 14.0797276072998]],
    [[49.5449215434445, 14.0797276072998], [49.5450411387184, 14.0797646914012]],
    [[49.5450411387184, 14.0797646914012], [49.5450591810166, 14.0797702384919]],
    [[49.5450591810166, 14.0797702384919], [49.5451645195796, 14.0797788944965]],
    [[49.5451645195796, 14.0797788944965], [49.5453542282179, 14.0797943699558]],
    [[49.5453542282179, 14.0797943699558], [49.545446571122, 14.0798019588611]],
    [[49.545446571122, 14.0798019588611], [49.5455322549784, 14.0797984412965]],
    [[49.5455322549784, 14.0797984412965], [49.545711786603, 14.0797912963326]],
    [[49.545711786603, 14.0797912963326], [49.5458071477398, 14.0797873380823]],
    [[49.5458071477398, 14.0797873380823], [49.5459274967551, 14.0797825232113]],
    [[49.5459274967551, 14.0797825232113], [49.5460188634432, 14.0797825089943]],
    [[49.5460188634432, 14.0797825089943], [49.5461104208375, 14.0797825926667]],
    [[49.5461104208375, 14.0797825926667], [49.5462022452964, 14.079782617953]],
    [[49.5462022452964, 14.079782617953], [49.5464129545388, 14.0797826150797]],
    [[49.5464129545388, 14.0797826150797], [49.5465042322025, 14.0797826203207]],
    [[49.5465042322025, 14.0797826203207], [49.5466999423256, 14.0797667735479]],
    [[49.5466999423256, 14.0797667735479], [49.5467887430668, 14.0797596431254]],
    [[49.5467887430668, 14.0797596431254], [49.5469226118396, 14.0797488014746]],
    [[49.5469226118396, 14.0797488014746], [49.5469638001525, 14.079748311409]],
    [[49.5469638001525, 14.079748311409], [49.5470286663442, 14.0797475302655]],
    [[49.5470286663442, 14.0797475302655], [49.5470605699417, 14.0797517221801]],
    [[49.5470605699417, 14.0797517221801], [49.5471653978012, 14.0797656547946]],
    [[49.5471653978012, 14.0797656547946], [49.5472573447306, 14.0797777972247]],
    [[49.5472573447306, 14.0797777972247], [49.5473271282826, 14.0797869683464]],
    [[49.5473271282826, 14.0797869683464], [49.5475282923418, 14.0798074777097]],
    [[49.5475282923418, 14.0798074777097], [49.5477231366118, 14.0798274146863]],
    [[49.5477231366118, 14.0798274146863], [49.5480760094377, 14.0799052098383]],
    [[49.5480760094377, 14.0799052098383], [49.5481734461964, 14.0799266216541]],
    [[49.5481734461964, 14.0799266216541], [49.548306116814, 14.0799558248703]],
    [[49.548306116814, 14.0799558248703], [49.5484701326925, 14.0799861319432]],
    [[49.5484701326925, 14.0799861319432], [49.5486324828552, 14.0800161054361]],
    [[49.5486324828552, 14.0800161054361], [49.5487383613303, 14.080042372447]],
    [[49.5487383613303, 14.080042372447], [49.5488301133332, 14.0800651672407]],
    [[49.5488301133332, 14.0800651672407], [49.5494689908089, 14.0801694971516]],
    [[49.5494689908089, 14.0801694971516], [49.5495064375357, 14.0801766654288]],
    [[49.5495064375357, 14.0801766654288], [49.5495551627907, 14.0801859744742]],
    [[49.5495551627907, 14.0801859744742], [49.5495551627907, 14.0801859744742]],
    [[49.5495551627907, 14.0801859744742], [49.5497100231667, 14.0802154928489]],
    [[49.5497100231667, 14.0802154928489], [49.5497474281445, 14.0802644085594]],
    [[49.5497474281445, 14.0802644085594], [49.5500352218292, 14.0803014380682]],
    [[49.5500352218292, 14.0803014380682], [49.5503902945164, 14.0803784800598]],
    [[49.5503902945164, 14.0803784800598], [49.5506025144257, 14.0804497617241]],
    [[49.5506025144257, 14.0804497617241], [49.5507950393737, 14.080514321751]],
    [[49.5507950393737, 14.080514321751], [49.5509517598874, 14.080566887361]],
    [[49.5509517598874, 14.080566887361], [49.5509866241855, 14.0805765750818]],
    [[49.5509866241855, 14.0805765750818], [49.5511310911455, 14.0806164643874]],
    [[49.5511310911455, 14.0806164643874], [49.5513430488477, 14.0806751021029]],
    [[49.5513430488477, 14.0806751021029], [49.5515145476793, 14.0807224835317]],
    [[49.5515145476793, 14.0807224835317], [49.5516873561068, 14.0807702769688]],
    [[49.5516873561068, 14.0807702769688], [49.551719880881, 14.0807792200668]],
    [[49.551719880881, 14.0807792200668], [49.5518953322939, 14.0808320200292]],
    [[49.5518953322939, 14.0808320200292], [49.5520698554765, 14.0808846044988]],
    [[49.5520698554765, 14.0808846044988], [49.5521699081688, 14.0809146616164]],
    [[49.5521699081688, 14.0809146616164], [49.552338353665, 14.0809653645874]],
    [[49.552338353665, 14.0809653645874], [49.5524765252535, 14.0810069116174]],
    [[49.5524765252535, 14.0810069116174], [49.552652828401, 14.0810600853798]],
    [[49.552652828401, 14.0810600853798], [49.5528664953893, 14.081104811746]],
    [[49.5528664953893, 14.081104811746], [49.5531159187704, 14.0811541459313]],
    [[49.5531159187704, 14.0811541459313], [49.5532009333126, 14.0811708792107]],
    [[49.5532009333126, 14.0811708792107], [49.5532547993426, 14.081203356879]],
    [[49.5532547993426, 14.081203356879], [49.5535438095998, 14.0813772189756]],
    [[49.5820634324813, 14.0840275655641], [49.5822876760102, 14.0838959704054]],
    [[49.5822876760102, 14.0838959704054], [49.5831174527363, 14.0833156876293]],
    [[49.5831174527363, 14.0833156876293], [49.5833297320536, 14.0831470314544]],
    [[49.5833297320536, 14.0831470314544], [49.5835680757055, 14.0829567482956]],
    [[49.5835680757055, 14.0829567482956], [49.5836335130072, 14.0828658858027]],
    [[49.5836335130072, 14.0828658858027], [49.583626241793, 14.0827980506455]],
    [[49.583626241793, 14.0827980506455], [49.583701793315, 14.0827262083214]],
    [[49.583701793315, 14.0827262083214], [49.5838955252161, 14.0825416286263]],
    [[49.5838955252161, 14.0825416286263], [49.5841898165785, 14.0822334956295]],
    [[49.5841898165785, 14.0822334956295], [49.5844434652963, 14.0819582763087]],
    [[49.5844434652963, 14.0819582763087], [49.5847884814614, 14.0815838608194]],
    [[49.5847884814614, 14.0815838608194], [49.5848161386467, 14.0815539230508]],
    [[49.5848161386467, 14.0815539230508], [49.584935659945, 14.0814242637338]],
    [[49.584935659945, 14.0814242637338], [49.5850839832138, 14.0812632976408]],
    [[49.5850839832138, 14.0812632976408], [49.585183265501, 14.0811514751586]],
    [[49.585183265501, 14.0811514751586], [49.5853349176615, 14.0809322248539]],
    [[49.5853349176615, 14.0809322248539], [49.5853937258323, 14.0808472765246]],
    [[49.5853937258323, 14.0808472765246], [49.5856225061305, 14.0805192301789]],
    [[49.5856225061305, 14.0805192301789], [49.5856376597328, 14.0804974749683]],
    [[49.5856376597328, 14.0804974749683], [49.5857087836078, 14.0803954423127]],
    [[49.5857087836078, 14.0803954423127], [49.5862027298234, 14.0799167544565]],
    [[49.5862027298234, 14.0799167544565], [49.5866172997086, 14.07946953453]],
    [[49.5866172997086, 14.07946953453], [49.5866457068733, 14.0794388714113]],
    [[49.5866457068733, 14.0794388714113], [49.5869225092108, 14.0791194397573]],
    [[49.5869225092108, 14.0791194397573], [49.5869708821457, 14.0790569341882]],
    [[49.5871006722817, 14.0788892237395], [49.5871152677081, 14.0788703639445]],
    [[49.5871152677081, 14.0788703639445], [49.5867949010532, 14.078308328154]],
    [[49.5867949010532, 14.078308328154], [49.5867834072122, 14.0783234311257]],
    [[49.5867239797759, 14.07840151144], [49.5866145340445, 14.0785450539398]],
    [[49.5866145340445, 14.0785450539398], [49.586360274113, 14.0789118014774]],
    [[49.586360274113, 14.0789118014774], [49.5859970962004, 14.0794501769868]],
    [[49.5859970962004, 14.0794501769868], [49.5859695244734, 14.0794908547582]],
    [[49.5859695244734, 14.0794908547582], [49.585951558872, 14.0795175563604]],
    [[49.585951558872, 14.0795175563604], [49.5858838503849, 14.0795691116847]],
    [[49.5858838503849, 14.0795691116847], [49.5856909261357, 14.0797162362686]],
    [[49.5856909261357, 14.0797162362686], [49.5852931606291, 14.080046615927]],
    [[49.5852931606291, 14.080046615927], [49.5852713070691, 14.0800646683414]],
    [[49.5852713070691, 14.0800646683414], [49.5850165941076, 14.0802762959282]],
    [[49.5850165941076, 14.0802762959282], [49.5848967694365, 14.0804046279967]],
    [[49.5848967694365, 14.0804046279967], [49.5848222335425, 14.0804844957134]],
    [[49.5848222335425, 14.0804844957134], [49.5845350682417, 14.0807920631618]],
    [[49.5845350682417, 14.0807920631618], [49.5844506218751, 14.08088261899]],
    [[49.5844506218751, 14.08088261899], [49.5842727093117, 14.0810732462476]],
    [[49.5842727093117, 14.0810732462476], [49.5840359741211, 14.0812877757906]],
    [[49.5840359741211, 14.0812877757906], [49.5836389961895, 14.0816404480796]],
    [[49.5836389961895, 14.0816404480796], [49.5836129821033, 14.0816635999173]],
    [[49.5836129821033, 14.0816635999173], [49.5835309189958, 14.0818339523535]],
    [[49.5835309189958, 14.0818339523535], [49.5833194194565, 14.0820601361901]],
    [[49.5876190507864, 14.0771087041956], [49.5873863597928, 14.0774474171582]],
    [[49.5873863597928, 14.0774474171582], [49.5873334918294, 14.0774649948615]],
    [[49.5873334918294, 14.0774649948615], [49.5872753926189, 14.077565442578]],
    [[49.5872753926189, 14.077565442578], [49.5870408836525, 14.0778992399112]],
    [[49.5870408836525, 14.0778992399112], [49.5870052593318, 14.0779500638438]],
    [[49.5870052593318, 14.0779500638438], [49.5869523996377, 14.0780482459786]],
    [[49.5869523996377, 14.0780482459786], [49.5868999676123, 14.0781245411073]],
    [[49.5868999676123, 14.0781245411073], [49.5868887699, 14.0781852339118]],
    [[49.5868759538968, 14.0782546977799], [49.5868738339346, 14.0782661881364]],
    [[49.5868738339346, 14.0782661881364], [49.5871350797808, 14.0787349895652]],
    [[49.5871350797808, 14.0787349895652], [49.5871526461749, 14.078733158015]],
    [[49.5872712089132, 14.0786928409561], [49.587337071543, 14.0786161872743]],
    [[49.587337071543, 14.0786161872743], [49.5874947574103, 14.0784013256512]],
    [[49.5874947574103, 14.0784013256512], [49.5876047346995, 14.0782460783107]],
    [[49.5876047346995, 14.0782460783107], [49.587627741972, 14.0782365728896]],
    [[49.587627741972, 14.0782365728896], [49.587657070109, 14.0782247062706]],
    [[49.587657070109, 14.0782247062706], [49.5876740568998, 14.0781962615645]],
    [[49.5876740568998, 14.0781962615645], [49.5877106388548, 14.0781351690019]],
    [[49.5877106388548, 14.0781351690019], [49.5877248008284, 14.0781115334946]],
    [[49.5877248008284, 14.0781115334946], [49.5878784262467, 14.0779048214908]],
    [[49.5878784262467, 14.0779048214908], [49.5879878705299, 14.0777447986975]],
    [[49.5879878705299, 14.0777447986975], [49.5880007731028, 14.0777222767692]],
    [[49.5880007731028, 14.0777222767692], [49.588048566319, 14.0776380536395]],
    [[49.588048566319, 14.0776380536395], [49.5881546564997, 14.0775036309377]],
    [[49.5881546564997, 14.0775036309377], [49.588212176395, 14.0774224475331]],
    [[49.588212176395, 14.0774224475331], [49.5882444303721, 14.0774422115142]],
    [[49.5882444303721, 14.0774422115142], [49.588242685076, 14.0775205482784]],
    [[49.588242685076, 14.0775205482784], [49.5882228824427, 14.0776687772275]],
    [[49.5882228824427, 14.0776687772275], [49.5882223118623, 14.0776989384212]],
    [[49.5882223118623, 14.0776989384212], [49.588220118843, 14.0778185857015]],
    [[49.588220118843, 14.0778185857015], [49.5882431722772, 14.0780972787765]],
    [[49.5882431722772, 14.0780972787765], [49.5882494008048, 14.0781724733394]],
    [[49.5882494008048, 14.0781724733394], [49.5882849168286, 14.0784130941839]],
    [[49.5882849168286, 14.0784130941839], [49.5883337250368, 14.0784046481713]],
    [[49.5883337250368, 14.0784046481713], [49.5883089552682, 14.0780926615575]],
    [[49.5883089552682, 14.0780926615575], [49.5882963249156, 14.077825517665]],
    [[49.5882963249156, 14.077825517665], [49.5883008378728, 14.0776906935429]],
    [[49.5883008378728, 14.0776906935429], [49.5883192593512, 14.0775638619725]],
    [[49.5883192593512, 14.0775638619725], [49.5883556304674, 14.0774523809628]],
    [[49.5883556304674, 14.0774523809628], [49.5884054632845, 14.0773538797773]],
    [[49.5884054632845, 14.0773538797773], [49.5885951434334, 14.0770617346147]],
    [[49.5885951434334, 14.0770617346147], [49.5886293285869, 14.0770159732505]],
    [[49.5886293285869, 14.0770159732505], [49.5886293285869, 14.0770159732505]],
    [[49.5886293285869, 14.0770159732505], [49.588800038036, 14.0767867936347]],
    [[49.588800038036, 14.0767867936347], [49.5890280855028, 14.0764126202435]],
    [[49.5890280855028, 14.0764126202435], [49.5892299222292, 14.0760781293272]],
    [[49.5892299222292, 14.0760781293272], [49.5895292793171, 14.0755607853471]],
    [[49.5895292793171, 14.0755607853471], [49.5896982642643, 14.075235434574]],
    [[49.5896982642643, 14.075235434574], [49.5898202506346, 14.0749793276085]],
    [[49.5898202506346, 14.0749793276085], [49.5898379644704, 14.0749420591955]],
    [[49.5898379644704, 14.0749420591955], [49.5899164744566, 14.0747942433513]],
    [[49.5899164744566, 14.0747942433513], [49.5899509185882, 14.0747367576402]],
    [[49.5900093488841, 14.0746230242927], [49.5899881716072, 14.074561997059]],
    [[49.5899881716072, 14.074561997059], [49.5899756583375, 14.0745258973048]],
    [[49.5899756583375, 14.0745258973048], [49.5898536961919, 14.0741883762229]],
    [[49.5898536961919, 14.0741883762229], [49.5897132230537, 14.0737980478848]],
    [[49.5897132230537, 14.0737980478848], [49.5896348911561, 14.0739114576265]],
    [[49.5896348911561, 14.0739114576265], [49.5895502509234, 14.0740656459372]],
    [[49.5895502509234, 14.0740656459372], [49.5895001658836, 14.0741663042646]],
    [[49.5895001658836, 14.0741663042646], [49.5894830764291, 14.0742005021806]],
    [[49.5894830764291, 14.0742005021806], [49.5894830764291, 14.0742005021806]],
    [[49.5894830764291, 14.0742005021806], [49.5892988452812, 14.0745701279099]],
    [[49.5892988452812, 14.0745701279099], [49.5890649515302, 14.0749154164678]],
    [[49.5890649515302, 14.0749154164678], [49.588645827777, 14.0755645951207]],
    [[49.588645827777, 14.0755645951207], [49.5884252207897, 14.0759062666823]],
    [[49.5884252207897, 14.0759062666823], [49.5883822822334, 14.0759663785117]],
    [[49.5883822822334, 14.0759663785117], [49.5883181860848, 14.0760559888529]],
    [[49.5883181860848, 14.0760559888529], [49.5882772449469, 14.0761131485077]],
    [[49.5882772449469, 14.0761131485077], [49.5881040574203, 14.0763773775572]],
    [[49.5881040574203, 14.0763773775572], [49.5880893076217, 14.0763780894319]],
    [[49.5880893076217, 14.0763780894319], [49.5880870211063, 14.0764033194192]],
    [[49.5880870211063, 14.0764033194192], [49.5879882509746, 14.0765540244389]],
    [[49.5879882509746, 14.0765540244389], [49.5878886791054, 14.0767058822059]],
    [[49.5878886791054, 14.0767058822059], [49.5878405603869, 14.076777743802]],
    [[49.5878405603869, 14.076777743802], [49.58769853223, 14.0769900241027]],
    [[49.58769853223, 14.0769900241027], [49.5876190507864, 14.0771087041956]],
    [[49.5987437263397, 14.0667827211096], [49.5987013590073, 14.0668175770252]],
    [[49.5987013590073, 14.0668175770252], [49.5986460849577, 14.0668513485228]],
    [[49.5986460849577, 14.0668513485228], [49.5985165609376, 14.0669307349557]],
    [[49.5985165609376, 14.0669307349557], [49.5982794988495, 14.0670905577323]],
    [[49.5982794988495, 14.0670905577323], [49.5980607386089, 14.0672381242353]],
    [[49.5980607386089, 14.0672381242353], [49.5977186506155, 14.0674505803105]],
    [[49.5977186506155, 14.0674505803105], [49.597544382348, 14.0675587712662]],
    [[49.597544382348, 14.0675587712662], [49.5974545688237, 14.0676903765071]],
    [[49.5974545688237, 14.0676903765071], [49.5974627634107, 14.0677622202628]],
    [[49.5974627634107, 14.0677622202628], [49.5974712624944, 14.067836372776]],
    [[49.5974712624944, 14.067836372776], [49.59777798637, 14.0680346616679]],
    [[49.59777798637, 14.0680346616679], [49.5980622253141, 14.0681960987989]],
    [[49.5980622253141, 14.0681960987989], [49.5981454697415, 14.0682721808253]],
    [[49.5981454697415, 14.0682721808253], [49.5982455663682, 14.0683370251198]],
    [[49.5982455663682, 14.0683370251198], [49.5983427361214, 14.068453654058]],
    [[49.5983427361214, 14.068453654058], [49.5984528372601, 14.0686177550393]],
    [[49.5984528372601, 14.0686177550393], [49.5985277943801, 14.0687681768689]],
    [[49.5985277943801, 14.0687681768689], [49.5985984194241, 14.0689473556669]],
    [[49.5985984194241, 14.0689473556669], [49.5986086813263, 14.0689914997342]],
    [[49.5986086813263, 14.0689914997342], [49.5986533855192, 14.069186977831]],
    [[49.5986533855192, 14.069186977831], [49.5987011205918, 14.0692797848766]],
    [[49.5987011205918, 14.0692797848766], [49.5986949947284, 14.0693980860176]],
    [[49.5986949947284, 14.0693980860176], [49.5986822516061, 14.0695322295897]],
    [[49.5986822516061, 14.0695322295897], [49.5986616665713, 14.069652300852]],
    [[49.5986616665713, 14.069652300852], [49.5985704373565, 14.0701297808707]],
    [[49.5985704373565, 14.0701297808707], [49.598523618818, 14.0703314744524]],
    [[49.598523618818, 14.0703314744524], [49.598524135925, 14.0702192934462]],
    [[49.598524135925, 14.0702192934462], [49.5985594317563, 14.0699804390504]],
    [[49.5985594317563, 14.0699804390504], [49.5985767623284, 14.0697731875133]],
    [[49.5985767623284, 14.0697731875133], [49.5985787589244, 14.0696014342313]],
    [[49.5985787589244, 14.0696014342313], [49.5985633791684, 14.0694058199809]],
    [[49.5985633791684, 14.0694058199809], [49.5985225587598, 14.0691934216647]],
    [[49.5985225587598, 14.0691934216647], [49.5985225587598, 14.0691934216647]],
    [[49.5985225587598, 14.0691934216647], [49.5984700771255, 14.0690248004058]],
    [[49.5984700771255, 14.0690248004058], [49.5984586940202, 14.0689881683445]],
    [[49.5984586940202, 14.0689881683445], [49.598388891038, 14.0688247397357]],
    [[49.598388891038, 14.0688247397357], [49.5983036330908, 14.0686768545602]],
    [[49.5983036330908, 14.0686768545602], [49.5982214546076, 14.0685651851831]],
    [[49.5982214546076, 14.0685651851831], [49.5981645539479, 14.0685048499426]],
    [[49.5981645539479, 14.0685048499426], [49.5981239759564, 14.0684618988061]],
    [[49.5981239759564, 14.0684618988061], [49.5980118335308, 14.0683650395021]],
    [[49.5980118335308, 14.0683650395021], [49.597862064806, 14.0682639881619]],
    [[49.597862064806, 14.0682639881619], [49.5977970708996, 14.0682231730977]],
    [[49.5977970708996, 14.0682231730977], [49.5975354933334, 14.0680583086202]],
    [[49.5975354933334, 14.0680583086202], [49.5974936766064, 14.0680333758674]],
    [[49.5974936766064, 14.0680333758674], [49.5972787699783, 14.0679046787324]],
    [[49.5972787699783, 14.0679046787324], [49.5972579052824, 14.0678951372893]],
    [[49.5972579052824, 14.0678951372893], [49.597231446148, 14.0678830488443]],
    [[49.597231446148, 14.0678830488443], [49.5971902076063, 14.0678820236306]],
    [[49.5971902076063, 14.0678820236306], [49.5971223437544, 14.0679171539294]],
    [[49.5971223437544, 14.0679171539294], [49.5970725056065, 14.0679733466576]],
    [[49.5970725056065, 14.0679733466576], [49.5969840341464, 14.0680340913121]],
    [[49.5969840341464, 14.0680340913121], [49.5968900785008, 14.0680620826266]],
    [[49.5968900785008, 14.0680620826266], [49.5966553856715, 14.0681572387818]],
    [[49.5966553856715, 14.0681572387818], [49.5965686380609, 14.0681924534281]],
    [[49.5965686380609, 14.0681924534281], [49.5963881353502, 14.0682656751842]],
    [[49.5963881353502, 14.0682656751842], [49.5961220532636, 14.0684240171002]],
    [[49.5961220532636, 14.0684240171002], [49.5960542281721, 14.0685007764889]],
    [[49.5960542281721, 14.0685007764889], [49.596052663573, 14.0685025165561]],
    [[49.596052663573, 14.0685025165561], [49.5960304849244, 14.0684189273385]],
    [[49.5960304849244, 14.0684189273385], [49.5959615129554, 14.0684597481895]],
    [[49.5959615129554, 14.0684597481895], [49.5959424098506, 14.0684711992771]],
    [[49.5959424098506, 14.0684711992771], [49.5959625435429, 14.0685464334852]],
    [[49.5959625435429, 14.0685464334852], [49.5959373084809, 14.0685230383052]],
    [[49.5959373084809, 14.0685230383052], [49.5958819916325, 14.0685269133683]],
    [[49.5958819916325, 14.0685269133683], [49.5953020407164, 14.0688807456838]],
    [[49.5953020407164, 14.0688807456838], [49.5951149251871, 14.068997329303]],
    [[49.5951149251871, 14.068997329303], [49.595031945884, 14.0690604992242]],
    [[49.595031945884, 14.0690604992242], [49.5949743751417, 14.0691185219197]],
    [[49.5949743751417, 14.0691185219197], [49.5949426186774, 14.0692189549554]],
    [[49.5949426186774, 14.0692189549554], [49.5949377091814, 14.0693372071259]],
    [[49.5949377091814, 14.0693372071259], [49.5949681650353, 14.0695234091858]],
    [[49.5949681650353, 14.0695234091858], [49.5949289920683, 14.0695466615005]],
    [[49.5949289920683, 14.0695466615005], [49.5948733266811, 14.0693093064814]],
    [[49.5948733266811, 14.0693093064814], [49.5948266273933, 14.069109949073]],
    [[49.5948266273933, 14.069109949073], [49.5947625309126, 14.0692191430496]],
    [[49.5947625309126, 14.0692191430496], [49.5947029546861, 14.0692643307059]],
    [[49.5947029546861, 14.0692643307059], [49.594232080374, 14.0696220564259]],
    [[49.594232080374, 14.0696220564259], [49.5939977396528, 14.069811295325]],
    [[49.5939977396528, 14.069811295325], [49.5938238408613, 14.0699519432831]],
    [[49.5938238408613, 14.0699519432831], [49.5928942383239, 14.0707672537767]],
    [[49.5928942383239, 14.0707672537767], [49.592803714285, 14.0708401737141]],
    [[49.592803714285, 14.0708401737141], [49.5927318949356, 14.0709159826089]],
    [[49.5927318949356, 14.0709159826089], [49.5928100607532, 14.0712482992463]],
    [[49.5928100607532, 14.0712482992463], [49.5926326167899, 14.0714154227927]],
    [[49.5926326167899, 14.0714154227927], [49.5925918628761, 14.0713283619632]],
    [[49.5925918628761, 14.0713283619632], [49.5924863981025, 14.0712339571389]],
    [[49.5924863981025, 14.0712339571389], [49.5924450770626, 14.0711299753492]],
    [[49.556157333974, 14.0818192499585], [49.5560961990889, 14.0819006391274]],
    [[49.5560961990889, 14.0819006391274], [49.5560704292268, 14.0819968512348]],
    [[49.5560704292268, 14.0819968512348], [49.5556990124509, 14.0818059505023]],
    [[49.5556647872365, 14.0817884427727], [49.5552826222048, 14.081604083198]],
    [[49.5552826222048, 14.081604083198], [49.554831432532, 14.0818310215477]],
    [[49.554831432532, 14.0818310215477], [49.5556068363158, 14.0822026278316]],
    [[49.5556068363158, 14.0822026278316], [49.5557514667734, 14.0822766941309]],
    [[49.5557514667734, 14.0822766941309], [49.5559744640717, 14.0823908691562]],
    [[49.5559744640717, 14.0823908691562], [49.5559686609237, 14.0824950303711]],
    [[49.5559686609237, 14.0824950303711], [49.5559953202132, 14.0826045203669]],
    [[49.5559953202132, 14.0826045203669], [49.5560454708123, 14.0826724369224]],
    [[49.556438242127, 14.0819209234201], [49.5563163460989, 14.0818082221893]],
    [[49.5563163460989, 14.0818082221893], [49.5563163460989, 14.0818082221893]],
    [[49.5563163460989, 14.0818082221893], [49.5562365969895, 14.0817850988995]],
    [[49.5562365969895, 14.0817850988995], [49.556157333974, 14.0818192499585]],
    [[49.5560454708123, 14.0826724369224], [49.5561376846475, 14.0827149636372]],
    [[49.5561376846475, 14.0827149636372], [49.5562570341658, 14.0827288019982]],
    [[49.5562570341658, 14.0827288019982], [49.5563987175224, 14.0827945798981]],
    [[49.5563987175224, 14.0827945798981], [49.5564301710442, 14.0828106002952]],
    [[49.5564301710442, 14.0828106002952], [49.5565047490101, 14.0828490249868]],
    [[49.5565047490101, 14.0828490249868], [49.5566049314067, 14.0828716609476]],
    [[49.5566049314067, 14.0828716609476], [49.5569699592072, 14.0830406542525]],
    [[49.5569699592072, 14.0830406542525], [49.5571245897974, 14.0831010906705]],
    [[49.5571245897974, 14.0831010906705], [49.5574806256033, 14.0832730303416]],
    [[49.5574806256033, 14.0832730303416], [49.5576316902851, 14.0833459752199]],
    [[49.5576316902851, 14.0833459752199], [49.5578350599589, 14.083472826708]],
    [[49.5578350599589, 14.083472826708], [49.5581094499932, 14.0836300881929]],
    [[49.5581094499932, 14.0836300881929], [49.5583046109751, 14.0837418428065]],
    [[49.5583046109751, 14.0837418428065], [49.5586462584563, 14.0839261509723]],
    [[49.5586462584563, 14.0839261509723], [49.5588115530615, 14.08402726339]],
    [[49.5588115530615, 14.08402726339], [49.5588701425169, 14.0840712164385]],
    [[49.5588701425169, 14.0840712164385], [49.5591370376171, 14.0842715142867]],
    [[49.5591370376171, 14.0842715142867], [49.5591370376171, 14.0842715142867]],
    [[49.5591370376171, 14.0842715142867], [49.5591227989695, 14.084322795053]],
    [[49.5591227989695, 14.084322795053], [49.5592870492996, 14.0844862684477]],
    [[49.5592870492996, 14.0844862684477], [49.5595004191725, 14.084657436191]],
    [[49.5595004191725, 14.084657436191], [49.5596346189776, 14.0847650700829]],
    [[49.5596346189776, 14.0847650700829], [49.5599469759894, 14.0849567695607]],
    [[49.5599469759894, 14.0849567695607], [49.5601232093327, 14.0850494933354]],
    [[49.5601232093327, 14.0850494933354], [49.5602128018294, 14.0850646765385]],
    [[49.5602128018294, 14.0850646765385], [49.5604677031479, 14.0851457914182]],
    [[49.5604677031479, 14.0851457914182], [49.5606536167374, 14.0851428537594]],
    [[49.5606536167374, 14.0851428537594], [49.5609005336994, 14.0852790510787]],
    [[49.5609005336994, 14.0852790510787], [49.5613962306381, 14.0855647257717]],
    [[49.5627168256984, 14.0863862638028], [49.5628564594175, 14.0864525084949]],
    [[49.5628564594175, 14.0864525084949], [49.5629784159717, 14.0865220584826]],
    [[49.563191540076, 14.0864326257152], [49.56315746414, 14.0862867467713]],
    [[49.56315746414, 14.0862867467713], [49.5631363392443, 14.0861963164159]],
    [[49.5630861703103, 14.0859838982169], [49.563045007689, 14.0858101954947]],
    [[49.563045007689, 14.0858101954947], [49.5628066555888, 14.0856890127117]],
    [[49.5628066555888, 14.0856890127117], [49.5626827286615, 14.0856168237904]],
    [[49.5626827286615, 14.0856168237904], [49.5624762079161, 14.0855187052729]],
    [[49.5624762079161, 14.0855187052729], [49.5623185780204, 14.0854572349318]],
    [[49.5623185780204, 14.0854572349318], [49.562167276344, 14.0853767887801]],
    [[49.562167276344, 14.0853767887801], [49.5618501503386, 14.0852169778681]],
    [[49.5618501503386, 14.0852169778681], [49.5616275237818, 14.0851047902002]],
    [[49.5616275237818, 14.0851047902002], [49.5613108910098, 14.084920859474]],
    [[49.5613108910098, 14.084920859474], [49.5608679237831, 14.0846426543472]],
    [[49.5608679237831, 14.0846426543472], [49.5606341083676, 14.0844959170998]],
    [[49.5606341083676, 14.0844959170998], [49.5604797628412, 14.0844247984901]],
    [[49.5613962306381, 14.0855647257717], [49.561496778516, 14.0856227543124]],
    [[49.561496778516, 14.0856227543124], [49.5616774107801, 14.0857453790953]],
    [[49.5616774107801, 14.0857453790953], [49.5618906689583, 14.0858770705307]],
    [[49.5618906689583, 14.0858770705307], [49.5620151150583, 14.0859538927423]],
    [[49.5620151150583, 14.0859538927423], [49.5621896728539, 14.0860668170903]],
    [[49.5621896728539, 14.0860668170903], [49.562398537925, 14.0861824368314]],
    [[49.562398537925, 14.0861824368314], [49.5625043243085, 14.0862411380032]],
    [[49.5625043243085, 14.0862411380032], [49.5626373303963, 14.0863417827761]],
    [[49.5626373303963, 14.0863417827761], [49.5626733323339, 14.0863657495086]],
    [[49.5626733323339, 14.0863657495086], [49.5627168256984, 14.0863862638028]],
    [[49.5604797628412, 14.0844247984901], [49.5604481690601, 14.0844102026614]],
    [[49.5604481690601, 14.0844102026614], [49.560298845459, 14.0843413377705]],
    [[49.560298845459, 14.0843413377705], [49.5601043316608, 14.0842640609837]],
    [[49.5601043316608, 14.0842640609837], [49.5601043316608, 14.0842640609837]],
    [[49.5601043316608, 14.0842640609837], [49.5599465730481, 14.0841747014583]],
    [[49.5599465730481, 14.0841747014583], [49.5598324118906, 14.0841010837798]],
    [[49.5598324118906, 14.0841010837798], [49.559783505794, 14.0840691899732]],
    [[49.559783505794, 14.0840691899732], [49.5592914310631, 14.0837490990623]],
    [[49.5592914310631, 14.0837490990623], [49.5590168199428, 14.0835432942567]],
    [[49.5590168199428, 14.0835432942567], [49.558671540299, 14.0833158017273]],
    [[49.558671540299, 14.0833158017273], [49.5584221328568, 14.0831694139765]],
    [[49.5584221328568, 14.0831694139765], [49.5583879470492, 14.0831493826467]],
    [[49.5583879470492, 14.0831493826467], [49.5582063389451, 14.0830427650918]],
    [[49.5582063389451, 14.0830427650918], [49.5577424775098, 14.0827567356254]],
    [[49.5577424775098, 14.0827567356254], [49.557697104591, 14.0827286793492]],
    [[49.557697104591, 14.0827286793492], [49.5576525961644, 14.0827011322099]],
    [[49.56722875696, 14.0879166699782], [49.5671509818445, 14.0878937301388]],
    [[49.5671509818445, 14.0878937301388], [49.5671288584435, 14.0878872542248]],
    [[49.5671288584435, 14.0878872542248], [49.566911299215, 14.0878229490509]],
    [[49.566911299215, 14.0878229490509], [49.5668014485466, 14.0877831411067]],
    [[49.5668014485466, 14.0877831411067], [49.5667662048116, 14.0877703164772]],
    [[49.5667662048116, 14.0877703164772], [49.5665682147925, 14.0877084381633]],
    [[49.5665682147925, 14.0877084381633], [49.5665682147925, 14.0877084381633]],
    [[49.5665682147925, 14.0877084381633], [49.5658423114495, 14.0875260863087]],
    [[49.5658423114495, 14.0875260863087], [49.5657756248222, 14.0875032378182]],
    [[49.5657756248222, 14.0875032378182], [49.5653750345364, 14.0873659717203]],
    [[49.5653750345364, 14.0873659717203], [49.5651870554601, 14.0872964630885]],
    [[49.5651870554601, 14.0872964630885], [49.565136364064, 14.0872776597475]],
    [[49.565136364064, 14.0872776597475], [49.5649528268242, 14.0872277072962]],
    [[49.5649528268242, 14.0872277072962], [49.5648753050967, 14.0872065294512]],
    [[49.5648753050967, 14.0872065294512], [49.5646048895475, 14.0871294856695]],
    [[49.5646048895475, 14.0871294856695], [49.5644248935539, 14.0870657744821]],
    [[49.5644248935539, 14.0870657744821], [49.5642491461869, 14.086998901091]],
    [[49.5642491461869, 14.086998901091], [49.5639505670124, 14.0869148892259]],
    [[49.5639505670124, 14.0869148892259], [49.56360904523, 14.0867731011415]],
    [[49.56360904523, 14.0867731011415], [49.5635428310545, 14.086745543617]],
    [[49.5635428310545, 14.086745543617], [49.5633440645626, 14.0866841223467]],
    [[49.5633440645626, 14.0866841223467], [49.5632866095116, 14.0866619115003]],
    [[49.5631894445986, 14.0862603485455], [49.5631335023803, 14.0860303683227]],
    [[49.5635807017371, 14.0856484252461], [49.5636357202724, 14.0855431258346]],
    [[49.5636357202724, 14.0855431258346], [49.5637479555302, 14.0854820100456]],
    [[49.5637479555302, 14.0854820100456], [49.5638465932655, 14.085487957159]],
    [[49.5638465932655, 14.085487957159], [49.5640772855019, 14.0855911276804]],
    [[49.5640772855019, 14.0855911276804], [49.5641135538065, 14.0856169912284]],
    [[49.5641135538065, 14.0856169912284], [49.5641637802763, 14.0856818332871]],
    [[49.5641637802763, 14.0856818332871], [49.5641980349779, 14.0857468153511]],
    [[49.5641980349779, 14.0857468153511], [49.5642164328733, 14.0857817515428]],
    [[49.5642164328733, 14.0857817515428], [49.5643056650688, 14.085951170944]],
    [[49.5643056650688, 14.085951170944], [49.5642814703054, 14.0862297208909]],
    [[49.5642814703054, 14.0862297208909], [49.564337996058, 14.0862831333407]],
    [[49.564337996058, 14.0862831333407], [49.5644557640381, 14.0864213226728]],
    [[49.5644557640381, 14.0864213226728], [49.5645257382756, 14.0864865973203]],
    [[49.5645257382756, 14.0864865973203], [49.5646104177977, 14.086540000834]],
    [[49.5646104177977, 14.086540000834], [49.5648972034894, 14.0866654075877]],
    [[49.5648972034894, 14.0866654075877], [49.5650085352113, 14.0867212251323]],
    [[49.5650085352113, 14.0867212251323], [49.565206712521, 14.0868204789424]],
    [[49.565206712521, 14.0868204789424], [49.5652468382127, 14.0868360056627]],
    [[49.5652468382127, 14.0868360056627], [49.5653775899133, 14.0868867417559]],
    [[49.5653775899133, 14.0868867417559], [49.5655171614193, 14.086920888667]],
    [[49.5655171614193, 14.086920888667], [49.5656315447192, 14.0869488113966]],
    [[49.5656315447192, 14.0869488113966], [49.5659074326779, 14.0870162815167]],
    [[49.5659074326779, 14.0870162815167], [49.5660829718312, 14.0870936755608]],
    [[49.5660829718312, 14.0870936755608], [49.5662545089666, 14.0871346613916]],
    [[49.5662545089666, 14.0871346613916], [49.5664341250387, 14.0871382738947]],
    [[49.5664341250387, 14.0871382738947], [49.5666111003608, 14.0871889634742]],
    [[49.5666111003608, 14.0871889634742], [49.5667939011096, 14.0872083576582]],
    [[49.5667939011096, 14.0872083576582], [49.5670518587959, 14.0872479115596]],
    [[49.5670518587959, 14.0872479115596], [49.5672055246306, 14.0872714395792]],
    [[49.5672055246306, 14.0872714395792], [49.567518008718, 14.087313320329]],
    [[49.567518008718, 14.087313320329], [49.5675796276941, 14.0873226145414]],
    [[49.5675796276941, 14.0873226145414], [49.5676873019464, 14.0873388792994]],
    [[49.5676873019464, 14.0873388792994], [49.5679412330966, 14.0873682828233]],
    [[49.5681485233247, 14.0873922404344], [49.568374352958, 14.0874182905938]],
    [[49.568374352958, 14.0874182905938], [49.5684890823802, 14.0874116481984]],
    [[49.5684890823802, 14.0874116481984], [49.5688263959855, 14.0874372117364]],
    [[49.5688263959855, 14.0874372117364], [49.568918209892, 14.0874322263177]],
    [[49.568918209892, 14.0874322263177], [49.5689604500979, 14.0874676808512]],
    [[49.5689604500979, 14.0874676808512], [49.5697990265771, 14.087522915224]],
    [[49.5697990265771, 14.087522915224], [49.5698572038642, 14.0875784877757]],
    [[49.5698572038642, 14.0875784877757], [49.5698878115927, 14.0875741342938]],
    [[49.5699377032005, 14.0875670379626], [49.5699188072831, 14.0876220000814]],
    [[49.5699188072831, 14.0876220000814], [49.5696816634518, 14.0883125805346]],
    [[49.5696346046216, 14.0883091451174], [49.569333922602, 14.0882871946993]],
    [[49.569333922602, 14.0882871946993], [49.5692519640071, 14.0882917004528]],
    [[49.5692519640071, 14.0882917004528], [49.5689852630573, 14.0882685733458]],
    [[49.5689852630573, 14.0882685733458], [49.5688890449075, 14.0882553882735]],
    [[49.5688890449075, 14.0882553882735], [49.5687869336431, 14.0882541042102]],
    [[49.5687869336431, 14.0882541042102], [49.5687428137859, 14.0882140327372]],
    [[49.5687428137859, 14.0882140327372], [49.5685437147437, 14.0881863262076]],
    [[49.5685437147437, 14.0881863262076], [49.5682083723482, 14.088132956701]],
    [[49.5682083723482, 14.088132956701], [49.5680739709327, 14.0881114993237]],
    [[49.5680739709327, 14.0881114993237], [49.5677398065011, 14.0880404180953]],
    [[49.5677398065011, 14.0880404180953], [49.5674473120614, 14.0879721005554]],
    [[49.5674473120614, 14.0879721005554], [49.56722875696, 14.0879166699782]],
    [[49.5731110249952, 14.0906801985133], [49.5730810601824, 14.0906910756313]],
    [[49.5730810601824, 14.0906910756313], [49.572999677698, 14.0903773911361]],
    [[49.572999677698, 14.0903773911361], [49.5729291260273, 14.0901054569535]],
    [[49.5729291260273, 14.0901054569535], [49.57287011575, 14.0898974144239]],
    [[49.57287011575, 14.0898974144239], [49.5727962971148, 14.0896371671726]],
    [[49.5727962971148, 14.0896371671726], [49.5727030863264, 14.0892860514984]],
    [[49.5727030863264, 14.0892860514984], [49.5726147090901, 14.0889017517168]],
    [[49.5726147090901, 14.0889017517168], [49.5725611038953, 14.0886686581578]],
    [[49.5725611038953, 14.0886686581578], [49.5725332292892, 14.0885046493431]],
    [[49.5725332292892, 14.0885046493431], [49.5725293565514, 14.0884922284448]],
    [[49.5725293565514, 14.0884922284448], [49.5724747313764, 14.0883178663432]],
    [[49.5724747313764, 14.0883178663432], [49.5724693966651, 14.0881383176387]],
    [[49.5724693966651, 14.0881383176387], [49.5721580876191, 14.0881415585972]],
    [[49.5721580876191, 14.0881415585972], [49.5719691552477, 14.0881000371029]],
    [[49.5719691552477, 14.0881000371029], [49.5714491129178, 14.0882317134428]],
    [[49.5714491129178, 14.0882317134428], [49.5711525510459, 14.0882706935305]],
    [[49.5711525510459, 14.0882706935305], [49.5710287154897, 14.0882800227396]],
    [[49.5710287154897, 14.0882800227396], [49.5705930909844, 14.088312945938]],
    [[49.5705930909844, 14.088312945938], [49.5700418462524, 14.0883261703576]],
    [[49.5700418462524, 14.0883261703576], [49.5699997235153, 14.0883293304432]],
    [[49.5699997235153, 14.0883293304432], [49.5699503875037, 14.088333031668]],
    [[49.5699503875037, 14.088333031668], [49.5698625952692, 14.088275411992]],
    [[49.5698625952692, 14.088275411992], [49.5698334837741, 14.0882562186803]],
    [[49.5698084402308, 14.0882397671898], [49.5698297680628, 14.0881786880675]],
    [[49.5698297680628, 14.0881786880675], [49.5698534515563, 14.0881106702212]],
    [[49.5698534515563, 14.0881106702212], [49.5700170699162, 14.0876415801344]],
    [[49.5700170699162, 14.0876415801344], [49.5700444838314, 14.0875628312704]],
    [[49.5701287992127, 14.0875642488096], [49.570203487162, 14.0875655044926]],
    [[49.570203487162, 14.0875655044926], [49.5703544287013, 14.0875674260752]],
    [[49.5703544287013, 14.0875674260752], [49.5708995121197, 14.0875965971671]],
    [[49.5708995121197, 14.0875965971671], [49.5710547087727, 14.0876091799351]],
    [[49.5710547087727, 14.0876091799351], [49.5712265955451, 14.087607780536]],
    [[49.5712265955451, 14.087607780536], [49.5712265955451, 14.087607780536]],
    [[49.5712265955451, 14.087607780536], [49.5712265955451, 14.087607780536]],
    [[49.5712265955451, 14.087607780536], [49.5715832854041, 14.0876405632538]],
    [[49.5715832854041, 14.0876405632538], [49.5716478242566, 14.0875203200981]],
    [[49.5716478242566, 14.0875203200981], [49.5716833198724, 14.0874543260332]],
    [[49.5716833198724, 14.0874543260332], [49.5719112407362, 14.0873831406607]],
    [[49.5719112407362, 14.0873831406607], [49.5720743205642, 14.0873414896469]],
    [[49.5720743205642, 14.0873414896469], [49.5723230485741, 14.0872910327461]],
    [[49.5723230485741, 14.0872910327461], [49.5723507193713, 14.0872907102839]],
    [[49.5723507193713, 14.0872907102839], [49.5723269089022, 14.0870203741003]],
    [[49.5723269089022, 14.0870203741003], [49.5722816367741, 14.0867409035077]],
    [[49.5722816367741, 14.0867409035077], [49.5722714646092, 14.0867104476501]],
    [[49.5722714646092, 14.0867104476501], [49.5722818017201, 14.0867132156229]],
    [[49.5723521706852, 14.0866914091846], [49.5723377685785, 14.0866220760103]],
    [[49.5723377685785, 14.0866220760103], [49.5724054677805, 14.0866558775091]],
    [[49.5724054677805, 14.0866558775091], [49.5724357992548, 14.0867059475672]],
    [[49.5724475258301, 14.0867581294161], [49.5724676475763, 14.0868048450983]],
    [[49.5724676475763, 14.0868048450983], [49.5725072778432, 14.0869329057174]],
    [[49.5725072778432, 14.0869329057174], [49.5725077719577, 14.0870787385165]],
    [[49.5725077719577, 14.0870787385165], [49.5725311609256, 14.0872118857214]],
    [[49.5725311609256, 14.0872118857214], [49.5725586200627, 14.0873143965548]],
    [[49.5725586200627, 14.0873143965548], [49.5730081990055, 14.087270312563]],
    [[49.5730081990055, 14.087270312563], [49.5732077802133, 14.0872442836972]],
    [[49.5732077802133, 14.0872442836972], [49.5732752568572, 14.0872353997947]],
    [[49.5732752568572, 14.0872353997947], [49.5733855381021, 14.0873358691902]],
    [[49.5733855381021, 14.0873358691902], [49.5735154934694, 14.0873190543716]],
    [[49.5735154934694, 14.0873190543716], [49.5737633865541, 14.0872870737654]],
    [[49.5737633865541, 14.0872870737654], [49.5746132245682, 14.087155633908]],
    [[49.5746132245682, 14.087155633908], [49.574819652219, 14.0871181909999]],
    [[49.574819652219, 14.0871181909999], [49.5749526923984, 14.0870846394691]],
    [[49.5749526923984, 14.0870846394691], [49.5751437762828, 14.0870364440919]],
    [[49.5751437762828, 14.0870364440919], [49.5753161409839, 14.08696161423]],
    [[49.5753161409839, 14.08696161423], [49.575608651846, 14.0868522789325]],
    [[49.575608651846, 14.0868522789325], [49.576044359084, 14.0866895707099]],
    [[49.576044359084, 14.0866895707099], [49.57622323094, 14.0866222543048]],
    [[49.57622323094, 14.0866222543048], [49.5763834830572, 14.0865618013217]],
    [[49.5763834830572, 14.0865618013217], [49.5764049008571, 14.086553767021]],
    [[49.5764049008571, 14.086553767021], [49.5765391369152, 14.0864899227441]],
    [[49.5765391369152, 14.0864899227441], [49.5765627052106, 14.0864786248854]],
    [[49.5765627052106, 14.0864786248854], [49.5767297714235, 14.0864221283567]],
    [[49.5767297714235, 14.0864221283567], [49.5769068722848, 14.0863621806084]],
    [[49.5769068722848, 14.0863621806084], [49.5770177389857, 14.0863196458811]],
    [[49.5770177389857, 14.0863196458811], [49.577035888242, 14.0863126052864]],
    [[49.577035888242, 14.0863126052864], [49.5772269883109, 14.0862557416279]],
    [[49.5772269883109, 14.0862557416279], [49.5773133454205, 14.086225129345]],
    [[49.5773133454205, 14.086225129345], [49.5777497857523, 14.0860703502184]],
    [[49.5777497857523, 14.0860703502184], [49.5780087157564, 14.085952423191]],
    [[49.5780087157564, 14.085952423191], [49.578037129611, 14.0859395066091]],
    [[49.578037129611, 14.0859395066091], [49.578092469245, 14.0859142780933]],
    [[49.578092469245, 14.0859142780933], [49.5782834841257, 14.0858191597562]],
    [[49.5782834841257, 14.0858191597562], [49.5784371639423, 14.0857239629632]],
    [[49.5784371639423, 14.0857239629632], [49.5785365685377, 14.0856625613935]],
    [[49.5785365685377, 14.0856625613935], [49.5791437439175, 14.0852866284709]],
    [[49.5791437439175, 14.0852866284709], [49.579158117041, 14.0852780383504]],
    [[49.579158117041, 14.0852780383504], [49.5792045306605, 14.0852505699171]],
    [[49.5792045306605, 14.0852505699171], [49.5792045306605, 14.0852505699171]],
    [[49.5792045306605, 14.0852505699171], [49.5792858976106, 14.0852023290592]],
    [[49.5792858976106, 14.0852023290592], [49.5793723396619, 14.0851510228462]],
    [[49.5793723396619, 14.0851510228462], [49.5794319180189, 14.0851156471994]],
    [[49.5794319180189, 14.0851156471994], [49.5795810900075, 14.0850335833988]],
    [[49.5795810900075, 14.0850335833988], [49.5798173000307, 14.0849037132269]],
    [[49.5798173000307, 14.0849037132269], [49.5799207587097, 14.0848468690796]],
    [[49.5799207587097, 14.0848468690796], [49.5800265450173, 14.0847886776304]],
    [[49.5800265450173, 14.0847886776304], [49.5803210090222, 14.0846267906297]],
    [[49.5803210090222, 14.0846267906297], [49.5804114184196, 14.0845751733934]],
    [[49.5804114184196, 14.0845751733934], [49.5805957604763, 14.0844697710624]],
    [[49.5805957604763, 14.0844697710624], [49.5807365240732, 14.0843635613785]],
    [[49.5807365240732, 14.0843635613785], [49.5809580886103, 14.0841931672124]],
    [[49.5809580886103, 14.0841931672124], [49.5811291424859, 14.0840560270737]],
    [[49.5811291424859, 14.0840560270737], [49.5812513110649, 14.0839579323268]],
    [[49.5815662567443, 14.0837356215933], [49.58189151568, 14.0835257030681]],
    [[49.58189151568, 14.0835257030681], [49.5820668388779, 14.0833335660666]],
    [[49.5820668388779, 14.0833335660666], [49.5823262425012, 14.0830783386007]],
    [[49.5823262425012, 14.0830783386007], [49.5828436232717, 14.082569164944]],
    [[49.5828436232717, 14.082569164944], [49.5831828890771, 14.0822062216382]],
    [[49.5831828890771, 14.0822062216382], [49.5831999998957, 14.0821879512657]],
    [[49.5831999998957, 14.0821879512657], [49.5832195024351, 14.0821670623917]],
    [[49.5832195024351, 14.0821670623917], [49.5833194194565, 14.0820601361901]],
    [[49.5820634324813, 14.0840275655641], [49.5819304253142, 14.0841056826389]],
    [[49.5819304253142, 14.0841056826389], [49.5816750972171, 14.0843107025942]],
    [[49.5816750972171, 14.0843107025942], [49.5815484380057, 14.0844122935762]],
    [[49.5815484380057, 14.0844122935762], [49.5813314473752, 14.0845614346081]],
    [[49.5813314473752, 14.0845614346081], [49.5810557027444, 14.0847509396111]],
    [[49.5810557027444, 14.0847509396111], [49.5810420283457, 14.0847611932969]],
    [[49.5810420283457, 14.0847611932969], [49.5809000944362, 14.084867518657]],
    [[49.5809000944362, 14.084867518657], [49.5807269954404, 14.0849972801892]],
    [[49.5807269954404, 14.0849972801892], [49.5803812931626, 14.0852562729498]],
    [[49.5803812931626, 14.0852562729498], [49.5802728793686, 14.0853322188027]],
    [[49.5802728793686, 14.0853322188027], [49.5801822991466, 14.0853957444549]],
    [[49.5801822991466, 14.0853957444549], [49.5799666670061, 14.085546814423]],
    [[49.5799666670061, 14.085546814423], [49.5798178282753, 14.0856511527776]],
    [[49.5798178282753, 14.0856511527776], [49.5798178282753, 14.0856511527776]],
    [[49.5798178282753, 14.0856511527776], [49.57972429394, 14.0856650392778]],
    [[49.57972429394, 14.0856650392778], [49.579635515764, 14.0856783048223]],
    [[49.579635515764, 14.0856783048223], [49.5795578557304, 14.0856821555048]],
    [[49.5795578557304, 14.0856821555048], [49.5794996683831, 14.0857640180552]],
    [[49.5794996683831, 14.0857640180552], [49.5794885109792, 14.0857759556]],
    [[49.5794885109792, 14.0857759556], [49.5793676493557, 14.0859058816561]],
    [[49.5793676493557, 14.0859058816561], [49.5792584791872, 14.0859657873462]],
    [[49.5792584791872, 14.0859657873462], [49.5788263530052, 14.0861711690107]],
    [[49.5788263530052, 14.0861711690107], [49.5787202133454, 14.0862216113512]],
    [[49.5787202133454, 14.0862216113512], [49.5786892688602, 14.086234662203]],
    [[49.5786892688602, 14.086234662203], [49.5782821063878, 14.0864065069277]],
    [[49.5782821063878, 14.0864065069277], [49.5782420680498, 14.0864233614537]],
    [[49.5782420680498, 14.0864233614537], [49.5781899597306, 14.0864452292112]],
    [[49.5781899597306, 14.0864452292112], [49.5780741819231, 14.0864935872336]],
    [[49.5780741819231, 14.0864935872336], [49.5780422708115, 14.0865069887315]],
    [[49.5780422708115, 14.0865069887315], [49.5779350653311, 14.0865517962781]],
    [[49.5779350653311, 14.0865517962781], [49.5778436079887, 14.0865272356877]],
    [[49.5778436079887, 14.0865272356877], [49.5775387896963, 14.0866616160673]],
    [[49.5775387896963, 14.0866616160673], [49.5775017017218, 14.0866779647808]],
    [[49.5775017017218, 14.0866779647808], [49.5774641040481, 14.0866966596275]],
    [[49.5774641040481, 14.0866966596275], [49.5771453377707, 14.0868550375129]],
    [[49.5771453377707, 14.0868550375129], [49.5771152197236, 14.0868681861883]],
    [[49.5771152197236, 14.0868681861883], [49.5769460599279, 14.0869423185637]],
    [[49.5769460599279, 14.0869423185637], [49.5768839809575, 14.0869663648397]],
    [[49.5768839809575, 14.0869663648397], [49.5766537379567, 14.0870556654282]],
    [[49.5766537379567, 14.0870556654282], [49.5765017009846, 14.0871146005326]],
    [[49.5765017009846, 14.0871146005326], [49.5764655686404, 14.0871265496018]],
    [[49.5764655686404, 14.0871265496018], [49.576275622781, 14.0871890225476]],
    [[49.576275622781, 14.0871890225476], [49.5761315131668, 14.0872364471693]],
    [[49.5761315131668, 14.0872364471693], [49.5759373945024, 14.0873029040608]],
    [[49.5759373945024, 14.0873029040608], [49.5757431410109, 14.0873580769803]],
    [[49.5757431410109, 14.0873580769803], [49.5756122368645, 14.0873921390308]],
    [[49.5756122368645, 14.0873921390308], [49.5753816506832, 14.0874521806647]],
    [[49.5753816506832, 14.0874521806647], [49.5753816506832, 14.0874521806647]],
    [[49.5753816506832, 14.0874521806647], [49.5751099195262, 14.0875573884869]],
    [[49.5751099195262, 14.0875573884869], [49.5748570497914, 14.0876553996797]],
    [[49.5748570497914, 14.0876553996797], [49.5744539395351, 14.0877757751276]],
    [[49.5744539395351, 14.0877757751276], [49.5743271917922, 14.0878135354229]],
    [[49.5743271917922, 14.0878135354229], [49.5742303732853, 14.0878341393715]],
    [[49.5742303732853, 14.0878341393715], [49.5740474316612, 14.0878731489023]],
    [[49.5740474316612, 14.0878731489023], [49.5737958153895, 14.0879267497826]],
    [[49.5737958153895, 14.0879267497826], [49.5736078757896, 14.0879667113708]],
    [[49.5736078757896, 14.0879667113708], [49.5734188805515, 14.0880070430359]],
    [[49.5734188805515, 14.0880070430359], [49.5728354660217, 14.0880986678756]],
    [[49.5728354660217, 14.0880986678756], [49.5728486028852, 14.0881798700706]],
    [[49.5728486028852, 14.0881798700706], [49.5728505788751, 14.0882798515266]],
    [[49.5728505788751, 14.0882798515266], [49.572870866907, 14.0884059965596]],
    [[49.572870866907, 14.0884059965596], [49.5729150052698, 14.0886811103241]],
    [[49.5729150052698, 14.0886811103241], [49.572930700353, 14.0887565863008]],
    [[49.572930700353, 14.0887565863008], [49.5729530717669, 14.0887765558926]],
    [[49.5729530717669, 14.0887765558926], [49.5730139666772, 14.0887839163252]],
    [[49.5730139666772, 14.0887839163252], [49.5731591785877, 14.0888014787293]],
    [[49.5731591785877, 14.0888014787293], [49.5731877487474, 14.088814293671]],
    [[49.5731877487474, 14.088814293671], [49.573203142374, 14.0888211983791]],
    [[49.573203142374, 14.0888211983791], [49.5733602684472, 14.0888409054445]],
    [[49.5733602684472, 14.0888409054445], [49.573475535287, 14.0887997922166]],
    [[49.573475535287, 14.0887997922166], [49.5735867789584, 14.0887141689329]],
    [[49.5735867789584, 14.0887141689329], [49.5736680312951, 14.0886145702606]],
    [[49.5736680312951, 14.0886145702606], [49.5736976601474, 14.0885597724442]],
    [[49.5736976601474, 14.0885597724442], [49.5737137981588, 14.0885299893326]],
    [[49.5737137981588, 14.0885299893326], [49.5737953737183, 14.0883356301958]],
    [[49.5737953737183, 14.0883356301958], [49.5738508017056, 14.0882022907394]],
    [[49.5738508017056, 14.0882022907394], [49.5738639946918, 14.0881887929964]],
    [[49.5738639946918, 14.0881887929964], [49.5739223515514, 14.0881293914414]],
    [[49.5739223515514, 14.0881293914414], [49.5739466944539, 14.0880930662158]],
    [[49.5739466944539, 14.0880930662158], [49.5739818027287, 14.0880405615598]],
    [[49.5739818027287, 14.0880405615598], [49.5740706779984, 14.0879546572882]],
    [[49.5740706779984, 14.0879546572882], [49.5741176521732, 14.0879263736104]],
    [[49.5741176521732, 14.0879263736104], [49.5741646389947, 14.0878982267729]],
    [[49.5741646389947, 14.0878982267729], [49.5742699408893, 14.0878740927601]],
    [[49.5742699408893, 14.0878740927601], [49.5742881626029, 14.0878756963219]],
    [[49.5742881626029, 14.0878756963219], [49.5743804664297, 14.087883871874]],
    [[49.5743804664297, 14.087883871874], [49.5744924897451, 14.0879275407729]],
    [[49.5744924897451, 14.0879275407729], [49.5745816890734, 14.0880229854565]],
    [[49.5745816890734, 14.0880229854565], [49.5745679562836, 14.088076824068]],
    [[49.5745679562836, 14.088076824068], [49.5745547585021, 14.0881285904384]],
    [[49.5745547585021, 14.0881285904384], [49.5743829654688, 14.088064187154]],
    [[49.5743829654688, 14.088064187154], [49.5742838981474, 14.088052601456]],
    [[49.5742838981474, 14.088052601456], [49.574181405198, 14.0880796126384]],
    [[49.574181405198, 14.0880796126384], [49.5740958877434, 14.0881360128637]],
    [[49.5740958877434, 14.0881360128637], [49.5740881902243, 14.088145097338]],
    [[49.5740881902243, 14.088145097338], [49.5740139254375, 14.088231857993]],
    [[49.5740139254375, 14.088231857993], [49.5739466094456, 14.0883613718214]],
    [[49.5739466094456, 14.0883613718214], [49.5739284434445, 14.0884320997877]],
    [[49.5739284434445, 14.0884320997877], [49.5739127998615, 14.0884927795073]],
    [[49.5739127998615, 14.0884927795073], [49.5738771726127, 14.0885671840758]],
    [[49.5738771726127, 14.0885671840758], [49.5738303573021, 14.088691518074]],
    [[49.5738303573021, 14.088691518074], [49.5737662565107, 14.0887968651802]],
    [[49.5737662565107, 14.0887968651802], [49.5737573483056, 14.0888115211379]],
    [[49.5737573483056, 14.0888115211379], [49.5737393841425, 14.0888303908781]],
    [[49.5737393841425, 14.0888303908781], [49.5736489396637, 14.0889258532778]],
    [[49.5736489396637, 14.0889258532778], [49.573547194278, 14.088985518642]],
    [[49.573547194278, 14.088985518642], [49.5734320023756, 14.0890294082676]],
    [[49.5734320023756, 14.0890294082676], [49.5732057575897, 14.0890764798522]],
    [[49.5732057575897, 14.0890764798522], [49.573157882068, 14.0891009087428]],
    [[49.573157882068, 14.0891009087428], [49.5729610347073, 14.0891535655242]],
    [[49.5729610347073, 14.0891535655242], [49.5729517812549, 14.0891645932816]],
    [[49.5729443431948, 14.089224528043], [49.5730055568842, 14.0895822189724]],
    [[49.5730055568842, 14.0895822189724], [49.5731116792499, 14.0901094165298]],
    [[49.5731116792499, 14.0901094165298], [49.5731544299286, 14.0903793889315]],
    [[49.5731544299286, 14.0903793889315], [49.573204429291, 14.0906472190894]],
    [[49.573204429291, 14.0906472190894], [49.5731926519633, 14.0906514685104]],
    [[49.5924450770626, 14.0711299753492], [49.5923902673026, 14.0711795634057]],
    [[49.5923902673026, 14.0711795634057], [49.5922166129635, 14.0713464146855]],
    [[49.5922166129635, 14.0713464146855], [49.5919328532312, 14.0716189723018]],
    [[49.5919328532312, 14.0716189723018], [49.5916945103733, 14.0718670501817]],
    [[49.5916945103733, 14.0718670501817], [49.5916281103657, 14.0719337051239]],
    [[49.5916281103657, 14.0719337051239], [49.5916463473374, 14.0720080915446]],
    [[49.5916463473374, 14.0720080915446], [49.5916553642678, 14.0720446782935]],
    [[49.5916553642678, 14.0720446782935], [49.5917074713892, 14.0721376346229]],
    [[49.5917074713892, 14.0721376346229], [49.5917235737384, 14.0721663826217]],
    [[49.5917235737384, 14.0721663826217], [49.5918041703179, 14.0722433202913]],
    [[49.5918041703179, 14.0722433202913], [49.5917396756399, 14.072236346759]],
    [[49.5917396756399, 14.072236346759], [49.5916316509295, 14.0722762096448]],
    [[49.5916316509295, 14.0722762096448], [49.5915321949748, 14.0723222993797]],
    [[49.5915321949748, 14.0723222993797], [49.5914384763134, 14.0724009434432]],
    [[49.5914384763134, 14.0724009434432], [49.5909045958384, 14.0730318557385]],
    [[49.5909045958384, 14.0730318557385], [49.5907316284781, 14.0732670032519]],
    [[49.5907316284781, 14.0732670032519], [49.5905908388583, 14.073483786859]],
    [[49.5905908388583, 14.073483786859], [49.5903698010665, 14.0738776893984]],
    [[49.5903698010665, 14.0738776893984], [49.5903325253152, 14.0740048844121]],
    [[49.5903325253152, 14.0740048844121], [49.5902498547698, 14.0741626951129]],
    [[49.5902498547698, 14.0741626951129], [49.5901815370824, 14.0742557506221]],
    [[49.5901815370824, 14.0742557506221], [49.5900739188702, 14.0743324020243]],
    [[49.5900739188702, 14.0743324020243], [49.5899618556406, 14.0743168398897]],
    [[49.5899618556406, 14.0743168398897], [49.5899048768534, 14.0741574718065]],
    [[49.5899048768534, 14.0741574718065], [49.5897168517913, 14.0736311392333]],
    [[49.5897168517913, 14.0736311392333], [49.5896070338612, 14.0733237941836]],
    [[49.5896070338612, 14.0733237941836], [49.5895038615004, 14.0730293852243]],
    [[49.5895038615004, 14.0730293852243], [49.5897036601207, 14.0729104735318]],
    [[49.5897036601207, 14.0729104735318], [49.5897985225018, 14.0727568382698]],
    [[49.5897985225018, 14.0727568382698], [49.589909773339, 14.0726900061175]],
    [[49.589909773339, 14.0726900061175], [49.5900474334162, 14.0727093202055]],
    [[49.5900474334162, 14.0727093202055], [49.5902090322156, 14.0729038988766]],
    [[49.5902090322156, 14.0729038988766], [49.5902793977409, 14.0729232792327]],
    [[49.5902793977409, 14.0729232792327], [49.5905823377264, 14.0724899221815]],
    [[49.5905823377264, 14.0724899221815], [49.5907988048695, 14.0721781845473]],
    [[49.5907988048695, 14.0721781845473], [49.5912221824356, 14.0715682498587]],
    [[49.5912221824356, 14.0715682498587], [49.5913511996334, 14.0713714996201]],
    [[49.5913511996334, 14.0713714996201], [49.5914116676335, 14.0712829507321]],
    [[49.5914116676335, 14.0712829507321], [49.5914481451891, 14.071177580762]],
    [[49.5914481451891, 14.071177580762], [49.5914626941692, 14.0710904258152]],
    [[49.5915364180406, 14.0710656172168], [49.5915339727551, 14.0711599015441]],
    [[49.5915339727551, 14.0711599015441], [49.591498814191, 14.0712765791855]],
    [[49.591498814191, 14.0712765791855], [49.5915243461992, 14.0714062315851]],
    [[49.5915243461992, 14.0714062315851], [49.5915862509129, 14.0713184855028]],
    [[49.5915862509129, 14.0713184855028], [49.5916951246832, 14.0711641472582]],
    [[49.5916951246832, 14.0711641472582], [49.591946840061, 14.0708446758414]],
    [[49.591946840061, 14.0708446758414], [49.5920709594326, 14.070692165665]],
    [[49.5920709594326, 14.070692165665], [49.5921499698655, 14.0705949426275]],
    [[49.5921499698655, 14.0705949426275], [49.5922098078693, 14.0705270675899]],
    [[49.5922098078693, 14.0705270675899], [49.5921508499615, 14.0701246021726]],
    [[49.5921508499615, 14.0701246021726], [49.5921296980043, 14.070056023438]],
    [[49.5921296980043, 14.070056023438], [49.5923792665047, 14.0698468312108]],
    [[49.5923792665047, 14.0698468312108], [49.5923371939821, 14.0696572284946]],
    [[49.5923371939821, 14.0696572284946], [49.5924259440468, 14.0696034176899]],
    [[49.5924259440468, 14.0696034176899], [49.5935711993413, 14.0688994324144]],
    [[49.5935711993413, 14.0688994324144], [49.5946245845646, 14.0682518686964]],
    [[49.5946245845646, 14.0682518686964], [49.5946600691647, 14.068229982392]],
    [[49.5946600691647, 14.068229982392], [49.5946919543972, 14.0682104215954]],
    [[49.5946919543972, 14.0682104215954], [49.595550296802, 14.0676880006513]],
    [[49.595550296802, 14.0676880006513], [49.5956539774352, 14.0676247631439]],
    [[49.5956539774352, 14.0676247631439], [49.5956746067343, 14.0676121390152]],
    [[49.5956746067343, 14.0676121390152], [49.5958546486556, 14.0675025476863]],
    [[49.5958546486556, 14.0675025476863], [49.5958677231671, 14.0674946527704]],
    [[49.5958677231671, 14.0674946527704], [49.5959169816035, 14.0674645774321]],
    [[49.5959169816035, 14.0674645774321], [49.5963369051004, 14.0672089451732]],
    [[49.5963369051004, 14.0672089451732], [49.5963369051004, 14.0672089451732]],
    [[49.5963369051004, 14.0672089451732], [49.5969368323111, 14.0668393886933]],
    [[49.5969368323111, 14.0668393886933], [49.5969694421311, 14.0668198069687]],
    [[49.5969694421311, 14.0668198069687], [49.5973299812866, 14.0666035857549]],
    [[49.5973299812866, 14.0666035857549], [49.5976152403259, 14.0664326430087]],
    [[49.5976152403259, 14.0664326430087], [49.5978949794002, 14.066264864127]],
    [[49.5978949794002, 14.066264864127], [49.5978884238864, 14.0662421266281]],
    [[49.5978884238864, 14.0662421266281], [49.5980381338189, 14.066141407751]],
    [[49.5980381338189, 14.066141407751], [49.5980473573162, 14.0661733420918]],
    [[49.5980473573162, 14.0661733420918], [49.5982059036066, 14.066077812718]],
    [[49.5982059036066, 14.066077812718], [49.598245657094, 14.0660206126389]],
    [[49.598245657094, 14.0660206126389], [49.5983953233418, 14.0658919546481]],
    [[49.5983953233418, 14.0658919546481], [49.5986120472466, 14.0656959191023]],
    [[49.5986120472466, 14.0656959191023], [49.598742556619, 14.0655408550177]],
    [[49.598742556619, 14.0655408550177], [49.5987247890946, 14.065552015269]],
    [[49.5987247890946, 14.065552015269], [49.5986967458437, 14.0655669646357]],
    [[49.5986967458437, 14.0655669646357], [49.5986525130645, 14.0655885362652]],
    [[49.5986525130645, 14.0655885362652], [49.5986078537501, 14.065587843447]],
    [[49.5986078537501, 14.065587843447], [49.5983887235184, 14.0656990280819]],
    [[49.5983887235184, 14.0656990280819], [49.5979881112678, 14.0659856661506]],
    [[49.5979881112678, 14.0659856661506], [49.5978577998688, 14.0660634099359]],
    [[49.5978577998688, 14.0660634099359], [49.5974025096667, 14.0663392470879]],
    [[49.5974025096667, 14.0663392470879], [49.597272147828, 14.0664144847953]],
    [[49.597272147828, 14.0664144847953], [49.5972266398385, 14.0664487706249]],
    [[49.5972266398385, 14.0664487706249], [49.5972240474476, 14.0664423520751]],
    [[49.5972240474476, 14.0664423520751], [49.5969414227694, 14.0666054500614]],
    [[49.5969414227694, 14.0666054500614], [49.5970819331455, 14.066479921683]],
    [[49.5970819331455, 14.066479921683], [49.5971927450329, 14.0663289017875]],
    [[49.5971927450329, 14.0663289017875], [49.5973403095712, 14.0662148212178]],
    [[49.5973403095712, 14.0662148212178], [49.5973749710472, 14.0662262292331]],
    [[49.5973749710472, 14.0662262292331], [49.5974145914441, 14.0662392055029]],
    [[49.5974145914441, 14.0662392055029], [49.5977563290802, 14.066092633362]],
    [[49.5977563290802, 14.066092633362], [49.5978517667875, 14.0660365056842]],
    [[49.5978517667875, 14.0660365056842], [49.597944864354, 14.0659815892871]],
    [[49.597944864354, 14.0659815892871], [49.5980876432583, 14.0658825281071]],
    [[49.5980876432583, 14.0658825281071], [49.5984028701092, 14.0656271775904]],
    [[49.5984028701092, 14.0656271775904], [49.5985083287625, 14.0655193853044]],
    [[49.5985083287625, 14.0655193853044], [49.5985698028314, 14.0654564459049]],
    [[49.5985698028314, 14.0654564459049], [49.5986251340554, 14.0653968089336]],
    [[49.5986251340554, 14.0653968089336], [49.5986470121604, 14.0653731495936]],
    [[49.5986470121604, 14.0653731495936], [49.5988263741401, 14.0651797095462]],
    [[49.5988263741401, 14.0651797095462], [49.59894653893, 14.0650463340451]],
    [[49.59894653893, 14.0650463340451], [49.5992149534576, 14.0647484121336]],
    [[49.5992149534576, 14.0647484121336], [49.5993622109788, 14.0645849212727]],
    [[49.5993622109788, 14.0645849212727], [49.599416867394, 14.0645268275382]],
    [[49.599416867394, 14.0645268275382], [49.5996995996371, 14.0642265996992]],
    [[49.5996995996371, 14.0642265996992], [49.5999713586065, 14.0639196903505]],
    [[49.5999713586065, 14.0639196903505], [49.6001743754889, 14.0637571072527]],
    [[49.6001743754889, 14.0637571072527], [49.6004637465603, 14.0635491818341]],
    [[49.6004637465603, 14.0635491818341], [49.6007070989549, 14.0634386814415]],
    [[49.6007070989549, 14.0634386814415], [49.6008928158557, 14.0633501773692]],
    [[49.6008928158557, 14.0633501773692], [49.6011728091443, 14.0632165501414]],
    [[49.6011728091443, 14.0632165501414], [49.6011728091443, 14.0632165501414]],
    [[49.6011728091443, 14.0632165501414], [49.6016907012675, 14.0629625718495]],
    [[49.6016907012675, 14.0629625718495], [49.6017532259579, 14.0629139280931]],
    [[49.6017532259579, 14.0629139280931], [49.6018901290305, 14.0628073336394]],
    [[49.6018901290305, 14.0628073336394], [49.602019248196, 14.0627069169757]],
    [[49.602019248196, 14.0627069169757], [49.6021449714296, 14.0626090609918]],
    [[49.6021449714296, 14.0626090609918], [49.6022755246421, 14.0625202074266]],
    [[49.6022755246421, 14.0625202074266], [49.6023509058341, 14.0624691630525]],
    [[49.6023509058341, 14.0624691630525], [49.6024377230584, 14.0624003786565]],
    [[49.6024377230584, 14.0624003786565], [49.6024002447836, 14.0621967366866]],
    [[49.6024002447836, 14.0621967366866], [49.6023999607493, 14.0619396615326]],
    [[49.6023999607493, 14.0619396615326], [49.6024563266581, 14.0616842799684]],
    [[49.6024563266581, 14.0616842799684], [49.6024785902757, 14.0615833909785]],
    [[49.6024785902757, 14.0615833909785], [49.6024919408412, 14.0613078133808]],
    [[49.6024919408412, 14.0613078133808], [49.602501522632, 14.061165124818]],
    [[49.602501522632, 14.061165124818], [49.6025312874151, 14.0607225805981]],
    [[49.6025312874151, 14.0607225805981], [49.6025419401042, 14.0605640048878]],
    [[49.6025419401042, 14.0605640048878], [49.6025887655978, 14.0600124866773]],
    [[49.6025887655978, 14.0600124866773], [49.6025963433746, 14.0597775826161]],
    [[49.6025963433746, 14.0597775826161], [49.602584311783, 14.0595447427746]],
    [[49.602584311783, 14.0595447427746], [49.6025532118153, 14.0592805883472]],
    [[49.6025532118153, 14.0592805883472], [49.6025087808371, 14.0590970586336]],
    [[49.6025087808371, 14.0590970586336], [49.6024329977042, 14.0588819740852]],
    [[49.6024329977042, 14.0588819740852], [49.6023372238488, 14.0586687590015]],
    [[49.6023372238488, 14.0586687590015], [49.6022252981686, 14.0584645373689]],
    [[49.6020974764762, 14.0580492570965], [49.6021009147003, 14.0580435036232]],
    [[49.6021009147003, 14.0580435036232], [49.6022670107233, 14.0582559667056]],
    [[49.6022670107233, 14.0582559667056], [49.6023943670755, 14.0584326271145]],
    [[49.6023943670755, 14.0584326271145], [49.6024879911514, 14.0585883174209]],
    [[49.6025580173773, 14.0587255660137], [49.6025988672432, 14.0588253323416]],
    [[49.6025988672432, 14.0588253323416], [49.6026491928986, 14.0589764051746]],
    [[49.6026491928986, 14.0589764051746], [49.602675393685, 14.0590749125834]],
    [[49.602675393685, 14.0590749125834], [49.6026868168019, 14.0591178261586]],
    [[49.6026868168019, 14.0591178261586], [49.6027146383095, 14.0592495183119]],
    [[49.6027146383095, 14.0592495183119], [49.6027416531076, 14.0594685916716]],
    [[49.6027416531076, 14.0594685916716], [49.6027509464097, 14.0596620642216]],
    [[49.6027509464097, 14.0596620642216], [49.6027448886406, 14.0599261230493]],
    [[49.6027448886406, 14.0599261230493], [49.6027343520583, 14.0600810407869]],
    [[49.6027343520583, 14.0600810407869], [49.6026974214199, 14.0606236827702]],
    [[49.6026974214199, 14.0606236827702], [49.602641545527, 14.0613167952277]],
    [[49.602641545527, 14.0613167952277], [49.6026331056211, 14.0615826324913]],
    [[49.6026331056211, 14.0615826324913], [49.602693481489, 14.0618658032047]],
    [[49.602693481489, 14.0618658032047], [49.6027079460957, 14.0621151591302]],
    [[49.6027079460957, 14.0621151591302], [49.6026852052984, 14.0623521290052]],
    [[49.6026852052984, 14.0623521290052], [49.6026813466658, 14.0623761733785]],
    [[49.6026813466658, 14.0623761733785], [49.6026820648167, 14.062388968258]],
    [[49.6026820648167, 14.062388968258], [49.6030981656201, 14.0625070738618]],
    [[49.6030981656201, 14.0625070738618], [49.6032751296563, 14.0625557443793]],
    [[49.6032751296563, 14.0625557443793], [49.6033162314334, 14.0625371623252]],
    [[49.6033162314334, 14.0625371623252], [49.6033242632921, 14.0625709096428]],
    [[49.6033242632921, 14.0625709096428], [49.6034335301417, 14.0626021431404]],
    [[49.6034335301417, 14.0626021431404], [49.6034581019033, 14.0625948694167]],
    [[49.60351775116, 14.0625772120346], [49.6036394778838, 14.0625411783331]],
    [[49.6036394778838, 14.0625411783331], [49.6036198514952, 14.0624536851712]],
    [[49.6036198514952, 14.0624536851712], [49.6036178359579, 14.0623720926405]],
    [[49.6036178359579, 14.0623720926405], [49.6036323947115, 14.0623233414813]],
    [[49.6036323947115, 14.0623233414813], [49.6036723767473, 14.0624775264663]],
    [[49.6036723767473, 14.0624775264663], [49.6036756043201, 14.0625418035832]],
    [[49.6036756043201, 14.0625418035832], [49.603784928009, 14.062478704154]],
    [[49.603784928009, 14.062478704154], [49.6037998315047, 14.0625327351366]],
    [[49.6037998315047, 14.0625327351366], [49.6038391802186, 14.0626751806458]],
    [[49.603165944033, 14.0653604113265], [49.6031892747132, 14.0654084974432]],
    [[49.6031892747132, 14.0654084974432], [49.6032348961235, 14.0655235743604]],
    [[49.6032348961235, 14.0655235743604], [49.6033232806543, 14.0657414982092]],
    [[49.6033232806543, 14.0657414982092], [49.6033383523514, 14.0657787224451]],
    [[49.6033383523514, 14.0657787224451], [49.6034127461834, 14.0659092949066]],
    [[49.6034127461834, 14.0659092949066], [49.6034724848802, 14.066014011578]],
    [[49.6034724848802, 14.066014011578], [49.6035859478763, 14.0661731956968]],
    [[49.6035859478763, 14.0661731956968], [49.6036181723888, 14.0662151857028]],
    [[49.6036181723888, 14.0662151857028], [49.6037488881799, 14.0663851236369]],
    [[49.6037488881799, 14.0663851236369], [49.6039047834265, 14.0665494042975]],
    [[49.6039047834265, 14.0665494042975], [49.6039706984696, 14.0666188093599]],
    [[49.6039706984696, 14.0666188093599], [49.6040508585981, 14.0667243629996]],
    [[49.6040508585981, 14.0667243629996], [49.6043218971307, 14.0670817051522]],
    [[49.6043218971307, 14.0670817051522], [49.6045105811475, 14.0673578739018]],
    [[49.6045105811475, 14.0673578739018], [49.6046339308137, 14.0675797431918]],
    [[49.6046339308137, 14.0675797431918], [49.6047412012142, 14.0678212088094]],
    [[49.6047412012142, 14.0678212088094], [49.6048309596658, 14.0680667923648]],
    [[49.6048309596658, 14.0680667923648], [49.6048994781924, 14.0682791576356]],
    [[49.6048994781924, 14.0682791576356], [49.6049425935781, 14.0684319644383]],
    [[49.6049425935781, 14.0684319644383], [49.6049822916462, 14.0685723833795]],
    [[49.6049822916462, 14.0685723833795], [49.6050097096117, 14.068566375424]],
    [[49.6049350909866, 14.0678484572075], [49.6049265894297, 14.0677830983878]],
    [[49.6049265894297, 14.0677830983878], [49.6049349149303, 14.0677052475341]],
    [[49.6049349149303, 14.0677052475341], [49.6049677049809, 14.0676326567173]],
    [[49.6049677049809, 14.0676326567173], [49.6049952675372, 14.0676095664747]],
    [[49.6049952675372, 14.0676095664747], [49.6050169283755, 14.0675914031613]],
    [[49.6050169283755, 14.0675914031613], [49.6051410454035, 14.0675683960685]],
    [[49.6051410454035, 14.0675683960685], [49.6052305897268, 14.0675810561868]],
    [[49.6052305897268, 14.0675810561868], [49.6053142605516, 14.0676342748116]],
    [[49.6053142605516, 14.0676342748116], [49.6050972680243, 14.0673682175507]],
    [[49.6050972680243, 14.0673682175507], [49.6050773657672, 14.0673729984018]],
    [[49.6050773657672, 14.0673729984018], [49.6049625431816, 14.0674002580566]],
    [[49.6049625431816, 14.0674002580566], [49.6048717616639, 14.0674468457819]],
    [[49.6048717616639, 14.0674468457819], [49.604825115615, 14.0674570681482]],
    [[49.6043705652936, 14.0646581991257], [49.6043774689689, 14.0645120408092]],
    [[49.6043774689689, 14.0645120408092], [49.6043630097516, 14.0643127073608]],
    [[49.6043630097516, 14.0643127073608], [49.6043608201307, 14.0641183707269]],
    [[49.6043608201307, 14.0641183707269], [49.6043832717817, 14.0639341448693]],
    [[49.6043832717817, 14.0639341448693], [49.6044223447194, 14.0637440389429]],
    [[49.6044223447194, 14.0637440389429], [49.6044862604704, 14.0635721048739]],
    [[49.6044862604704, 14.0635721048739], [49.6046092584876, 14.0633359276669]],
    [[49.6046092584876, 14.0633359276669], [49.6048215508598, 14.0630410408997]],
    [[49.6048215508598, 14.0630410408997], [49.6048299972639, 14.0630292664819]],
    [[49.6048299972639, 14.0630292664819], [49.6050202254143, 14.0629562685395]],
    [[49.6050202254143, 14.0629562685395], [49.6052445976342, 14.0628701674273]],
    [[49.6052445976342, 14.0628701674273], [49.605401939515, 14.0628008713234]],
    [[49.605401939515, 14.0628008713234], [49.605731649481, 14.0626264195905]],
    [[49.605731649481, 14.0626264195905], [49.6059425949889, 14.0625367040537]],
    [[49.6059425949889, 14.0625367040537], [49.605992342782, 14.062474644833]],
    [[49.605992342782, 14.062474644833], [49.6059959171146, 14.0624700876465]],
    [[49.6059959171146, 14.0624700876465], [49.6060131002846, 14.0624006334322]],
    [[49.6060131002846, 14.0624006334322], [49.6060676519343, 14.0624051640567]],
    [[49.6060676519343, 14.0624051640567], [49.6062045336253, 14.0624425154837]],
    [[49.6062045336253, 14.0624425154837], [49.6062695402292, 14.062546219305]],
    [[49.6062695402292, 14.062546219305], [49.6064418857611, 14.0627527318144]],
    [[49.6064418857611, 14.0627527318144], [49.6066439054704, 14.0629275827389]],
    [[49.6066439054704, 14.0629275827389], [49.6068591696132, 14.0630728368598]],
    [[49.6068591696132, 14.0630728368598], [49.6069256405655, 14.0631129098593]],
    [[49.6069256405655, 14.0631129098593], [49.6070869609798, 14.0632101744857]],
    [[49.6070869609798, 14.0632101744857], [49.6072214719504, 14.0633219828356]],
    [[49.6072214719504, 14.0633219828356], [49.6073344013453, 14.063442017454]],
    [[49.6073344013453, 14.063442017454], [49.6074304163876, 14.063568695937]],
    [[49.6074304163876, 14.063568695937], [49.6075211832597, 14.0637318855015]],
    [[49.6075211832597, 14.0637318855015], [49.6075873955323, 14.0639437853828]],
    [[49.6075873955323, 14.0639437853828], [49.6076350405772, 14.0641601755298]],
    [[49.6076350405772, 14.0641601755298], [49.6076486587335, 14.0642660655027]],
    [[49.6076486587335, 14.0642660655027], [49.6076631398994, 14.0643783351874]],
    [[49.6076631398994, 14.0643783351874], [49.6076673656658, 14.0646152855963]],
    [[49.6076673656658, 14.0646152855963], [49.6076540746613, 14.064825887077]],
    [[49.6076540746613, 14.064825887077], [49.6076161653799, 14.0651325910133]],
    [[49.6076161653799, 14.0651325910133], [49.6076141239787, 14.0652577068956]],
    [[49.6076141239787, 14.0652577068956], [49.6076982714436, 14.0648451294105]],
    [[49.6076982714436, 14.0648451294105], [49.6078044310117, 14.0643240200489]],
    [[49.6078044310117, 14.0643240200489], [49.6078182464895, 14.0642505504755]],
    [[49.6078182464895, 14.0642505504755], [49.6077881259098, 14.0640802131188]],
    [[49.6077881259098, 14.0640802131188], [49.6077561079122, 14.0639197958184]],
    [[49.6077561079122, 14.0639197958184], [49.6077061744818, 14.0637512867736]],
    [[49.6077061744818, 14.0637512867736], [49.6076338246421, 14.0635721789081]],
    [[49.6076338246421, 14.0635721789081], [49.6075611329168, 14.0634384297099]],
    [[49.6075611329168, 14.0634384297099], [49.6074568207292, 14.0632839405168]],
    [[49.6074568207292, 14.0632839405168], [49.607287750358, 14.0631049359571]],
    [[49.607287750358, 14.0631049359571], [49.6072514653483, 14.0630750158599]],
    [[49.6072514653483, 14.0630750158599], [49.6071487382244, 14.0629906208477]],
    [[49.6071487382244, 14.0629906208477], [49.6069628905584, 14.0628825221233]],
    [[49.6069628905584, 14.0628825221233], [49.6068852735911, 14.0628162421298]],
    [[49.6068852735911, 14.0628162421298], [49.6067369575849, 14.0627358341368]],
    [[49.6067369575849, 14.0627358341368], [49.6066363629624, 14.0626666263002]],
    [[49.6066363629624, 14.0626666263002], [49.6065326104262, 14.0625613541646]],
    [[49.6065326104262, 14.0625613541646], [49.6064337189506, 14.0624409008529]],
    [[49.6064337189506, 14.0624409008529], [49.6063648738319, 14.0623290938071]],
    [[49.6063648738319, 14.0623290938071], [49.6063638058974, 14.0622734239921]],
    [[49.6063638058974, 14.0622734239921], [49.6065140205056, 14.0621742409437]],
    [[49.6065140205056, 14.0621742409437], [49.6065759166994, 14.0620923262764]],
    [[49.6065759166994, 14.0620923262764], [49.6068619920156, 14.0618801937791]],
    [[49.6068619920156, 14.0618801937791], [49.6071316573291, 14.0616929009382]],
    [[49.6071316573291, 14.0616929009382], [49.6072547064299, 14.0616073582511]],
    [[49.6072547064299, 14.0616073582511], [49.6073830994241, 14.0615079248492]],
    [[49.6073830994241, 14.0615079248492], [49.6074000531324, 14.0614948430445]],
    [[49.6074000531324, 14.0614948430445], [49.6075754880635, 14.0613747508255]],
    [[49.6075754880635, 14.0613747508255], [49.6077298360775, 14.0612692051293]],
    [[49.6077298360775, 14.0612692051293], [49.6077639969316, 14.0612467592023]],
    [[49.6077639969316, 14.0612467592023], [49.6078972444948, 14.0611595367975]],
    [[49.6078972444948, 14.0611595367975], [49.6082113380461, 14.0609773077831]],
    [[49.6082113380461, 14.0609773077831], [49.608227884012, 14.0609676693493]],
    [[49.608227884012, 14.0609676693493], [49.6082555436808, 14.0609593673546]],
    [[49.6082555436808, 14.0609593673546], [49.6083524733575, 14.0609301440319]],
    [[49.6083524733575, 14.0609301440319], [49.6083524733575, 14.0609301440319]],
    [[49.6083524733575, 14.0609301440319], [49.6084047914478, 14.0609144776548]],
    [[49.6084047914478, 14.0609144776548], [49.6086788284253, 14.0607322243869]],
    [[49.6090104994823, 14.0605299214791], [49.6090899354443, 14.0604824500308]],
    [[49.6090899354443, 14.0604824500308], [49.6092836957669, 14.0603513422261]],
    [[49.6092836957669, 14.0603513422261], [49.6092836957669, 14.0603513422261]],
    [[49.6092836957669, 14.0603513422261], [49.609642523273, 14.0601117695512]],
    [[49.609642523273, 14.0601117695512], [49.6096755520356, 14.0600898497086]],
    [[49.6096755520356, 14.0600898497086], [49.6097334573408, 14.0600511524042]],
    [[49.6097334573408, 14.0600511524042], [49.6099064577151, 14.0599508711797]],
    [[49.6099064577151, 14.0599508711797], [49.6101664175858, 14.0598225708932]],
    [[49.6101664175858, 14.0598225708932], [49.6103002572581, 14.0597564554749]],
    [[49.6103002572581, 14.0597564554749], [49.6103752786241, 14.0597172178538]],
    [[49.6103752786241, 14.0597172178538], [49.6105722875083, 14.0596143237431]],
    [[49.6105722875083, 14.0596143237431], [49.6105722875083, 14.0596143237431]],
    [[49.6105722875083, 14.0596143237431], [49.6109267150314, 14.0594292373873]],
    [[49.6109267150314, 14.0594292373873], [49.6110396324631, 14.0593814068625]],
    [[49.6110396324631, 14.0593814068625], [49.611098882643, 14.0593562502337]],
    [[49.611098882643, 14.0593562502337], [49.6114469046715, 14.0591520179628]],
    [[49.6114469046715, 14.0591520179628], [49.6119307305963, 14.0588479647524]],
    [[49.6119307305963, 14.0588479647524], [49.6122189552725, 14.0586669066409]],
    [[49.6122189552725, 14.0586669066409], [49.612255811559, 14.0585804062678]],
    [[49.612255811559, 14.0585804062678], [49.6122884948785, 14.0585675057032]],
    [[49.6122884948785, 14.0585675057032], [49.6124785667505, 14.0584926823251]],
    [[49.6124785667505, 14.0584926823251], [49.6127299009708, 14.058376183617]],
    [[49.6127299009708, 14.058376183617], [49.6129380415867, 14.0582414829637]],
    [[49.6129380415867, 14.0582414829637], [49.6132844950256, 14.0580497395672]],
    [[49.6132844950256, 14.0580497395672], [49.6134744576674, 14.0579462812975]],
    [[49.6134744576674, 14.0579462812975], [49.6136219331265, 14.0578538208194]],
    [[49.6136219331265, 14.0578538208194], [49.6137618302801, 14.0577579902148]],
    [[49.6137618302801, 14.0577579902148], [49.614130293518, 14.057505777194]],
    [[49.614130293518, 14.057505777194], [49.6141339781291, 14.0575220223834]],
    [[49.6141339781291, 14.0575220223834], [49.6141888473582, 14.0574623179053]],
    [[49.6141888473582, 14.0574623179053], [49.6143367500866, 14.0573274061681]],
    [[49.6143367500866, 14.0573274061681], [49.6143394027333, 14.0573540820775]],
    [[49.6143394027333, 14.0573540820775], [49.6144485849664, 14.0572835788815]],
    [[49.6144485849664, 14.0572835788815], [49.6147845217596, 14.0570165488169]],
    [[49.6147845217596, 14.0570165488169], [49.6149443357468, 14.0569680641148]],
    [[49.6149443357468, 14.0569680641148], [49.615790858198, 14.0565397889716]],
    [[49.615790858198, 14.0565397889716], [49.6160619403638, 14.0564139060101]],
    [[49.6162660929296, 14.0563096977532], [49.6163057459364, 14.0562886950091]],
    [[49.6163057459364, 14.0562886950091], [49.6164725434652, 14.0562253937621]],
    [[49.6164725434652, 14.0562253937621], [49.6165243291358, 14.0561255425442]],
    [[49.6165243291358, 14.0561255425442], [49.6165326641064, 14.0560213866698]],
    [[49.6165326641064, 14.0560213866698], [49.6165184841133, 14.055957538563]],
    [[49.6165184841133, 14.055957538563], [49.6167767017269, 14.0558035794582]],
    [[49.6167767017269, 14.0558035794582], [49.616805443443, 14.0557863684292]],
    [[49.616805443443, 14.0557863684292], [49.6171083248898, 14.0556056909875]],
    [[49.6171083248898, 14.0556056909875], [49.6172034267662, 14.0555488938437]],
    [[49.6172034267662, 14.0555488938437], [49.6172643976205, 14.055787420511]],
    [[49.6172643976205, 14.055787420511], [49.6173519844468, 14.05601814983]],
    [[49.6173519844468, 14.05601814983], [49.6174167911152, 14.0561343554954]],
    [[49.6174167911152, 14.0561343554954], [49.6174468953675, 14.0561779351598]],
    [[49.6174468953675, 14.0561779351598], [49.6174843349776, 14.0562320672276]],
    [[49.6174843349776, 14.0562320672276], [49.6175892735402, 14.0563500912663]],
    [[49.6175892735402, 14.0563500912663], [49.617697103576, 14.0564405010418]],
    [[49.617697103576, 14.0564405010418], [49.6177974612591, 14.056504172662]],
    [[49.6177974612591, 14.056504172662], [49.6178993741969, 14.0565463942743]],
    [[49.6178993741969, 14.0565463942743], [49.6179942710218, 14.0565707241611]],
    [[49.6179942710218, 14.0565707241611], [49.618106007285, 14.0565777988247]],
    [[49.618106007285, 14.0565777988247], [49.6182568001078, 14.0565574306491]],
    [[49.6182568001078, 14.0565574306491], [49.6183677539675, 14.0565315453689]],
    [[49.6183677539675, 14.0565315453689], [49.6184593421035, 14.0564995646585]],
    [[49.6184593421035, 14.0564995646585], [49.6185334585445, 14.0564652667867]],
    [[49.6185334585445, 14.0564652667867], [49.6186798068637, 14.0564106445727]],
    [[49.6186798068637, 14.0564106445727], [49.6187936614159, 14.0563768527266]],
    [[49.6187936614159, 14.0563768527266], [49.6188376099823, 14.0563698646163]],
    [[49.6188376099823, 14.0563698646163], [49.6188852209432, 14.0563622125973]],
    [[49.6188852209432, 14.0563622125973], [49.6188852209432, 14.0563622125973]],
    [[49.6188852209432, 14.0563622125973], [49.6189796651489, 14.0563581232021]],
    [[49.6189796651489, 14.0563581232021], [49.619069083138, 14.0563723319269]],
    [[49.619069083138, 14.0563723319269], [49.6191491262852, 14.0564029971869]],
    [[49.6191491262852, 14.0564029971869], [49.6191491262852, 14.0564029971869]],
    [[49.6191491262852, 14.0564029971869], [49.6192349015824, 14.0564494600669]],
    [[49.6192349015824, 14.0564494600669], [49.6193483755502, 14.0565507970593]],
    [[49.6193483755502, 14.0565507970593], [49.6194023190944, 14.0566134723339]],
    [[49.6194023190944, 14.0566134723339], [49.6195243633802, 14.0568544061475]],
    [[49.6195243633802, 14.0568544061475], [49.619576759133, 14.0570337346132]],
    [[49.619576759133, 14.0570337346132], [49.6196015064442, 14.0571616727879]],
    [[49.6196015064442, 14.0571616727879], [49.6196152515067, 14.0573032092174]],
    [[49.6196152515067, 14.0573032092174], [49.6196132707679, 14.0574887381216]],
    [[49.6196132707679, 14.0574887381216], [49.6195883448664, 14.0576991530645]],
    [[49.6195883448664, 14.0576991530645], [49.6195645309184, 14.0578127225931]],
    [[49.6195645309184, 14.0578127225931], [49.6195077462814, 14.0579782618764]],
    [[49.6195077462814, 14.0579782618764], [49.6194885054507, 14.0581794600337]],
    [[49.6194885054507, 14.0581794600337], [49.6196883241436, 14.0576106710307]],
    [[49.6196883241436, 14.0576106710307], [49.6197669917133, 14.0573842692064]],
    [[49.6197669917133, 14.0573842692064], [49.619764396305, 14.0573238860208]],
    [[49.619764396305, 14.0573238860208], [49.6197856475769, 14.0572434513752]],
    [[49.6197856475769, 14.0572434513752], [49.6198209124094, 14.057229002795]],
    [[49.6198209124094, 14.057229002795], [49.6199058135291, 14.0569845954782]],
    [[49.6199058135291, 14.0569845954782], [49.6198801615001, 14.0570028064799]],
    [[49.6198801615001, 14.0570028064799], [49.6198492226563, 14.0570041433658]],
    [[49.6198492226563, 14.0570041433658], [49.619746461936, 14.05700865852]],
    [[49.6043244992244, 14.0623901710981], [49.6044850780698, 14.062344063012]],
    [[49.6044850780698, 14.062344063012], [49.6045175575202, 14.0623348454719]],
    [[49.6045175575202, 14.0623348454719], [49.6045780822858, 14.062299913206]],
    [[49.6045780822858, 14.062299913206], [49.6047440805794, 14.0621338243139]],
    [[49.6047440805794, 14.0621338243139], [49.6050068213448, 14.0619786660711]],
    [[49.6050068213448, 14.0619786660711], [49.6056349252325, 14.0615995822447]],
    [[49.6056349252325, 14.0615995822447], [49.6057568143945, 14.0615260360087]],
    [[49.6057568143945, 14.0615260360087], [49.6057442616303, 14.0614777767265]],
    [[49.6057442616303, 14.0614777767265], [49.6061654145641, 14.0612354647134]],
    [[49.6061654145641, 14.0612354647134], [49.6061749432219, 14.0612736260139]],
    [[49.6061749432219, 14.0612736260139], [49.6063303821366, 14.0611798631887]],
    [[49.6063303821366, 14.0611798631887], [49.6065048592387, 14.0610768935033]],
    [[49.6065048592387, 14.0610768935033], [49.6065709923973, 14.061037793309]],
    [[49.6065709923973, 14.061037793309], [49.6067917880034, 14.0609073348138]],
    [[49.6067917880034, 14.0609073348138], [49.6070292947569, 14.0607670608452]],
    [[49.6070292947569, 14.0607670608452], [49.607034985225, 14.0607382797719]],
    [[49.607034985225, 14.0607382797719], [49.6076959591414, 14.0603463638383]],
    [[49.6076959591414, 14.0603463638383], [49.6079476202919, 14.060197109969]],
    [[49.6079476202919, 14.060197109969], [49.6079967307051, 14.0601958433916]],
    [[49.6079967307051, 14.0601958433916], [49.6082449068513, 14.0600491690539]],
    [[49.6082449068513, 14.0600491690539], [49.6083697581588, 14.0599742662503]],
    [[49.6062457084605, 14.0608755854775], [49.6063401344267, 14.0608977849817]],
    [[49.6063401344267, 14.0608977849817], [49.6064545554866, 14.0608965923724]],
    [[49.6064545554866, 14.0608965923724], [49.6063390639992, 14.0609656636631]],
    [[49.6063390639992, 14.0609656636631], [49.6059948530065, 14.0611718201253]],
    [[49.6059948530065, 14.0611718201253], [49.6059528583512, 14.0611969614251]],
    [[49.6059528583512, 14.0611969614251], [49.6059936541748, 14.0611304347785]],
    [[49.6059936541748, 14.0611304347785], [49.6059936541748, 14.0611304347785]],
    [[49.6059936541748, 14.0611304347785], [49.6060927876711, 14.0609690764853]],
    [[49.6060927876711, 14.0609690764853], [49.6060927876711, 14.0609690764853]],
    [[49.6060927876711, 14.0609690764853], [49.6062457084605, 14.0608755854775]],
    [[49.6083697581588, 14.0599742662503], [49.6088556559105, 14.0596857201841]],
    [[49.6088556559105, 14.0596857201841], [49.6094700663982, 14.0593206201894]],
    [[49.6094700663982, 14.0593206201894], [49.6095021788508, 14.0593015564636]],
    [[49.6095021788508, 14.0593015564636], [49.609783000547, 14.0591347134623]],
    [[49.609783000547, 14.0591347134623], [49.6099913306439, 14.0590108850606]],
    [[49.6099913306439, 14.0590108850606], [49.6104151502952, 14.0587601170129]],
    [[49.6104151502952, 14.0587601170129], [49.6108040209939, 14.0585301496746]],
    [[49.6108040209939, 14.0585301496746], [49.6109931466384, 14.0584176572042]],
    [[49.6109931466384, 14.0584176572042], [49.6112359574133, 14.058318965812]],
    [[49.6112359574133, 14.058318965812], [49.6112760422391, 14.0583026246023]],
    [[49.6112760422391, 14.0583026246023], [49.6113301174389, 14.0582735698096]],
    [[49.6113301174389, 14.0582735698096], [49.6116131997028, 14.0580487703325]],
    [[49.6116131997028, 14.0580487703325], [49.6122191095185, 14.0576880086053]],
    [[49.6122191095185, 14.0576880086053], [49.6122545412799, 14.057665558661]],
    [[49.6122545412799, 14.057665558661], [49.6132864401378, 14.0570539318436]],
    [[49.6132864401378, 14.0570539318436], [49.6135688480779, 14.0568865742555]],
    [[49.6135688480779, 14.0568865742555], [49.6139395923747, 14.0566668435577]],
    [[49.6139395923747, 14.0566668435577], [49.6146190674926, 14.0562707535445]],
    [[49.6146190674926, 14.0562707535445], [49.6150675541733, 14.0559990071678]],
    [[49.6150675541733, 14.0559990071678], [49.615254223024, 14.0558864743441]],
    [[49.615254223024, 14.0558864743441], [49.6159849373477, 14.0554424928088]],
    [[49.6159849373477, 14.0554424928088], [49.6160087319674, 14.0554277653777]],
    [[49.6160087319674, 14.0554277653777], [49.6160678179314, 14.0553910346442]],
    [[49.6160678179314, 14.0553910346442], [49.6161970795415, 14.0553108057318]],
    [[49.6161970795415, 14.0553108057318], [49.6167674755658, 14.0549569290311]],
    [[49.6167674755658, 14.0549569290311], [49.6167808798674, 14.0549486751738]],
    [[49.6167808798674, 14.0549486751738], [49.6167301495863, 14.0547520782395]],
    [[49.6167301495863, 14.0547520782395], [49.6167599596731, 14.0547326750122]],
    [[49.6167599596731, 14.0547326750122], [49.6174993443572, 14.0542734779027]],
    [[49.6174993443572, 14.0542734779027], [49.6177481367546, 14.0541207361782]],
    [[49.6177481367546, 14.0541207361782], [49.6178784147212, 14.0540406972111]],
    [[49.6178784147212, 14.0540406972111], [49.6182840207108, 14.0538247353413]],
    [[49.6182840207108, 14.0538247353413], [49.6184900532294, 14.0537182808937]],
    [[49.6184900532294, 14.0537182808937], [49.618649060363, 14.0536159965647]],
    [[49.618649060363, 14.0536159965647], [49.6189077862207, 14.0534440118045]],
    [[49.6189077862207, 14.0534440118045], [49.6193345604456, 14.0531653765759]],
    [[49.6193345604456, 14.0531653765759], [49.6195415077386, 14.0530599742752]],
    [[49.6195415077386, 14.0530599742752], [49.6196519591923, 14.0529874970465]],
    [[49.6196519591923, 14.0529874970465], [49.6198905591508, 14.0528678751232]],
    [[49.6198905591508, 14.0528678751232], [49.6198870398072, 14.0528475377064]],
    [[49.6198870398072, 14.0528475377064], [49.6198646403714, 14.0527185253198]],
    [[49.6198646403714, 14.0527185253198], [49.6198190138066, 14.0526378105843]],
    [[49.619746461936, 14.05700865852], [49.6197127084861, 14.0568962568647]],
    [[49.6197127084861, 14.0568962568647], [49.6196047450997, 14.0566337785635]],
    [[49.6196047450997, 14.0566337785635], [49.6195202409338, 14.0565206314376]],
    [[49.6195202409338, 14.0565206314376], [49.6194547804845, 14.0564355991968]],
    [[49.6194547804845, 14.0564355991968], [49.6193772525965, 14.0563487417729]],
    [[49.6193772525965, 14.0563487417729], [49.6192844757454, 14.0562737586943]],
    [[49.6192844757454, 14.0562737586943], [49.6191958645214, 14.0562280582018]],
    [[49.6191958645214, 14.0562280582018], [49.6191062838035, 14.0561993464052]],
    [[49.6191062838035, 14.0561993464052], [49.6190073793862, 14.0561856819944]],
    [[49.6190073793862, 14.0561856819944], [49.6189048222869, 14.0561894551269]],
    [[49.6189048222869, 14.0561894551269], [49.6188567274759, 14.0562007084367]],
    [[49.6188567274759, 14.0562007084367], [49.6187543193418, 14.0562247194195]],
    [[49.6187543193418, 14.0562247194195], [49.6186986947392, 14.0562458730663]],
    [[49.6186986947392, 14.0562458730663], [49.6185524480317, 14.0563015918889]],
    [[49.6185524480317, 14.0563015918889], [49.6183893911555, 14.0563533104523]],
    [[49.6183893911555, 14.0563533104523], [49.6183293925248, 14.0563723481222]],
    [[49.6183293925248, 14.0563723481222], [49.6182478018538, 14.0563906718979]],
    [[49.6182478018538, 14.0563906718979], [49.61820331865, 14.0564007129289]],
    [[49.61820331865, 14.0564007129289], [49.61820331865, 14.0564007129289]],
    [[49.61820331865, 14.0564007129289], [49.6180735612404, 14.0564059809318]],
    [[49.6180735612404, 14.0564059809318], [49.6179723177181, 14.0563886358342]],
    [[49.6179723177181, 14.0563886358342], [49.6178568115392, 14.0563438056022]],
    [[49.6178568115392, 14.0563438056022], [49.6177469934466, 14.0562770378344]],
    [[49.6177469934466, 14.0562770378344], [49.6176793158683, 14.0562161207007]],
    [[49.6176793158683, 14.0562161207007], [49.6176530483803, 14.0561923883661]],
    [[49.6176530483803, 14.0561923883661], [49.6175729527208, 14.0560993878773]],
    [[49.6175729527208, 14.0560993878773], [49.6175729527208, 14.0560993878773]],
    [[49.6175729527208, 14.0560993878773], [49.6174579669215, 14.0559160489398]],
    [[49.6174579669215, 14.0559160489398], [49.6173637650205, 14.0556992125514]],
    [[49.6173637650205, 14.0556992125514], [49.6173353867738, 14.0556036251999]],
    [[49.6173353867738, 14.0556036251999], [49.6173069948742, 14.0555079922396]],
    [[49.6173069948742, 14.0555079922396], [49.6174664218061, 14.0554141518973]],
    [[49.6174664218061, 14.0554141518973], [49.6175971719905, 14.0553294002204]],
    [[49.6175971719905, 14.0553294002204], [49.6176061633785, 14.0553235127315]],
    [[49.6176061633785, 14.0553235127315], [49.6176588376522, 14.0553763979221]],
    [[49.6176588376522, 14.0553763979221], [49.6176998277824, 14.0553845188977]],
    [[49.6176998277824, 14.0553845188977], [49.6177140750915, 14.0553873415817]],
    [[49.6177140750915, 14.0553873415817], [49.6177626160895, 14.0553691398686]],
    [[49.6177626160895, 14.0553691398686], [49.6177626160895, 14.0553691398686]],
    [[49.6177626160895, 14.0553691398686], [49.6181209336254, 14.0550740991864]],
    [[49.6181209336254, 14.0550740991864], [49.6185689237454, 14.054788171132]],
    [[49.6185689237454, 14.054788171132], [49.61862826401, 14.0547502634383]],
    [[49.61862826401, 14.0547502634383], [49.6189475306088, 14.0545190039357]],
    [[49.6189475306088, 14.0545190039357], [49.6192411555145, 14.0543982186511]],
    [[49.6192411555145, 14.0543982186511], [49.6193209425289, 14.054365328443]],
    [[49.6193209425289, 14.054365328443], [49.6195919349399, 14.0542286787094]],
    [[49.6195919349399, 14.0542286787094], [49.6198118036488, 14.054118626973]],
    [[49.6198118036488, 14.054118626973], [49.6201421041024, 14.0539829364358]],
    [[49.6201421041024, 14.0539829364358], [49.6203804951839, 14.0538238001636]],
    [[49.6207913341498, 14.0535623587201], [49.6208895120201, 14.0535073942742]],
    [[49.6208895120201, 14.0535073942742], [49.6213487857209, 14.0532501341492]],
    [[49.6213487857209, 14.0532501341492], [49.6214397777336, 14.0532019186753]],
    [[49.6214397777336, 14.0532019186753], [49.621507230064, 14.0531662802551]],
    [[49.621507230064, 14.0531662802551], [49.6215910738317, 14.053129140494]],
    [[49.6215910738317, 14.053129140494], [49.6216146608402, 14.0531396208431]],
    [[49.6216146608402, 14.0531396208431], [49.6216146608402, 14.0531396208431]],
    [[49.6216146608402, 14.0531396208431], [49.6216388200331, 14.0531503950121]],
    [[49.6216388200331, 14.0531503950121], [49.6218181546811, 14.0530822262599]],
    [[49.6218181546811, 14.0530822262599], [49.6218181546811, 14.0530822262599]],
    [[49.6218181546811, 14.0530822262599], [49.6220816412052, 14.0530410206052]],
    [[49.6220816412052, 14.0530410206052], [49.6222633610586, 14.0530083976627]],
    [[49.6222633610586, 14.0530083976627], [49.6223562683616, 14.0529916366346]],
    [[49.6223562683616, 14.0529916366346], [49.6225895092822, 14.0529082765827]],
    [[49.6225895092822, 14.0529082765827], [49.6230623366355, 14.0527394618789]],
    [[49.6230623366355, 14.0527394618789], [49.6232025398106, 14.0526940014403]],
    [[49.6231661413401, 14.0524668306866], [49.6233762017586, 14.0524136990988]],
    [[49.6233762017586, 14.0524136990988], [49.6235513936958, 14.0523693866039]],
    [[49.6235513936958, 14.0523693866039], [49.6242049485833, 14.0519856963673]],
    [[49.6242049485833, 14.0519856963673], [49.624218817115, 14.0519305007657]],
    [[49.624218817115, 14.0519305007657], [49.6236932732398, 14.05207343218]],
    [[49.6236932732398, 14.05207343218], [49.6236342209269, 14.0520605322397]],
    [[49.6236342209269, 14.0520605322397], [49.6236976657535, 14.0520355572878]],
    [[49.6236976657535, 14.0520355572878], [49.6237476703575, 14.0520047249902]],
    [[49.6237476703575, 14.0520047249902], [49.6237973965991, 14.0519640270388]],
    [[49.6237973965991, 14.0519640270388], [49.6238755865497, 14.05188939651]],
    [[49.6238755865497, 14.05188939651], [49.6240609876828, 14.0517190822097]],
    [[49.6240609876828, 14.0517190822097], [49.624214223801, 14.0515878464543]],
    [[49.624214223801, 14.0515878464543], [49.6243255860473, 14.051450704243]],
    [[49.6243255860473, 14.051450704243], [49.6243998423855, 14.0513120617266]],
    [[49.6243998423855, 14.0513120617266], [49.6244383822534, 14.0512408224482]],
    [[49.6244383822534, 14.0512408224482], [49.6244610736102, 14.0511994880639]],
    [[49.6244610736102, 14.0511994880639], [49.6245337400503, 14.0510613336393]],
    [[49.6245337400503, 14.0510613336393], [49.6246123082204, 14.0509113966794]],
    [[49.6246123082204, 14.0509113966794], [49.6246666213161, 14.0507996419955]],
    [[49.6246666213161, 14.0507996419955], [49.6248698402572, 14.0504021212443]],
    [[49.6248698402572, 14.0504021212443], [49.6248698402572, 14.0504021212443]],
    [[49.6248698402572, 14.0504021212443], [49.6249535381212, 14.0502056151353]],
    [[49.6249535381212, 14.0502056151353], [49.6249771719171, 14.0501499513073]],
    [[49.6249771719171, 14.0501499513073], [49.625067997694, 14.0498951132125]],
    [[49.625067997694, 14.0498951132125], [49.6250997856156, 14.0498137496227]],
    [[49.6250997856156, 14.0498137496227], [49.6251583113407, 14.0496641554511]],
    [[49.6251583113407, 14.0496641554511], [49.6252423166981, 14.0493318162274]],
    [[49.6252423166981, 14.0493318162274], [49.62541539227, 14.0486081365169]],
    [[49.62541539227, 14.0486081365169], [49.6252882762998, 14.0485360860658]],
    [[49.6252882762998, 14.0485360860658], [49.6252081918533, 14.048850928023]],
    [[49.6252081918533, 14.048850928023], [49.6250838910052, 14.0492835584276]],
    [[49.6250838910052, 14.0492835584276], [49.6250128234942, 14.0495722288143]],
    [[49.6250128234942, 14.0495722288143], [49.624944928272, 14.0498069314071]],
    [[49.624944928272, 14.0498069314071], [49.6247733369914, 14.0502017047167]],
    [[49.6247733369914, 14.0502017047167], [49.6246460131707, 14.050470427596]],
    [[49.6246460131707, 14.050470427596], [49.6244769016636, 14.0507508416974]],
    [[49.6244769016636, 14.0507508416974], [49.6244237249655, 14.0508278122045]],
    [[49.6244237249655, 14.0508278122045], [49.6243720744488, 14.050902629815]],
    [[49.6243720744488, 14.050902629815], [49.6242666770744, 14.0510374850799]],
    [[49.6242666770744, 14.0510374850799], [49.6239855452387, 14.0513303277094]],
    [[49.6239855452387, 14.0513303277094], [49.6236688002327, 14.0515819120538]],
    [[49.6236688002327, 14.0515819120538], [49.6234965964144, 14.0516780238043]],
    [[49.6234965964144, 14.0516780238043], [49.6229995423238, 14.051874539558]],
    [[49.6229995423238, 14.051874539558], [49.6226622693666, 14.0519673244424]],
    [[49.6226622693666, 14.0519673244424], [49.6225864788958, 14.0519796304586]],
    [[49.6225864788958, 14.0519796304586], [49.6224246102779, 14.0520059418234]],
    [[49.6224246102779, 14.0520059418234], [49.6220744418128, 14.0521065889321]],
    [[49.6220744418128, 14.0521065889321], [49.6217525588887, 14.052174321505]],
    [[49.6217525588887, 14.052174321505], [49.6215139655136, 14.0522538258132]],
    [[49.6215139655136, 14.0522538258132], [49.621334121084, 14.0523331538537]],
    [[49.621334121084, 14.0523331538537], [49.6210960364388, 14.0524112867357]],
    [[49.6210960364388, 14.0524112867357], [49.6210378841528, 14.052417901941]],
    [[49.6210378841528, 14.052417901941], [49.6209178791188, 14.0524313854724]],
    [[49.6209178791188, 14.0524313854724], [49.6208640460063, 14.0523807113195]],
    [[49.6208640460063, 14.0523807113195], [49.6206987652455, 14.0524348913817]],
    [[49.6206987652455, 14.0524348913817], [49.6206473400285, 14.0525268476848]],
    [[49.6206473400285, 14.0525268476848], [49.6206037705701, 14.052551651198]],
    [[49.6206037705701, 14.052551651198], [49.6205151923604, 14.052617092206]],
    [[49.6205151923604, 14.052617092206], [49.6204288818256, 14.0526540745695]],
    [[49.6204288818256, 14.0526540745695], [49.6203343195879, 14.0526833617049]],
    [[49.6203343195879, 14.0526833617049], [49.6202178943034, 14.0527384186025]],
    [[49.6202178943034, 14.0527384186025], [49.6201903876124, 14.0527483715196]],
    [[49.6201903876124, 14.0527483715196], [49.6200728323742, 14.0527912337807]],
    [[49.6200728323742, 14.0527912337807], [49.6199922208903, 14.0528132638488]],
    [[49.6199922208903, 14.0528132638488], [49.6199158010832, 14.0526962499049]],
    [[49.6199158010832, 14.0526962499049], [49.6198621773058, 14.052606665817]],
    [[49.6198621773058, 14.052606665817], [49.6197805699642, 14.0525699186819]],
    [[49.6197805699642, 14.0525699186819], [49.6198190138066, 14.0526378105843]],
    [[49.570002024447, 14.0872510547592], [49.5699364953389, 14.0872856802151]],
    [[49.5699364953389, 14.0872856802151], [49.569889048366, 14.0872865378242]],
    [[49.569889048366, 14.0872865378242], [49.5698402824844, 14.0873083714697]],
    [[49.5698402824844, 14.0873083714697], [49.5697892964048, 14.0873463596079]],
    [[49.5697892964048, 14.0873463596079], [49.5697456328664, 14.0873992434128]],
    [[49.5697456328664, 14.0873992434128], [49.5696997793881, 14.0875163780301]],
    [[49.5731765888619, 14.0906565147805], [49.5731462168862, 14.0906678998712]],
    [[49.5722378220082, 14.0881407285077], [49.5723270484848, 14.0885515122701]],
    [[49.5723270484848, 14.0885515122701], [49.5724046017048, 14.0888117332419]],
    [[49.5724046017048, 14.0888117332419], [49.5724928724741, 14.0890511734864]],
    [[49.5724928724741, 14.0890511734864], [49.572564660193, 14.0892954742138]],
    [[49.572564660193, 14.0892954742138], [49.5726990579258, 14.0898075381484]],
    [[49.5726990579258, 14.0898075381484], [49.5727607928268, 14.0900665803117]],
    [[49.5727607928268, 14.0900665803117], [49.5727859572899, 14.0901983537891]],
    [[49.5727859572899, 14.0901983537891], [49.5728298115155, 14.0903445488757]],
    [[49.5728298115155, 14.0903445488757], [49.5729080352426, 14.0904665019481]],
    [[49.5729080352426, 14.0904665019481], [49.5730546954421, 14.0905857039977]],
    [[49.5723017227382, 14.0864394649714], [49.5723377685785, 14.0866220760103]],
    [[49.5445282340465, 14.0797447779554], [49.5445181929624, 14.0799028464708]],
    [[49.5445181929624, 14.0799028464708], [49.5444409566844, 14.0811185971214]],
    [[49.5395795433162, 14.0800427942252], [49.5395725933291, 14.0803421079744]],
    [[49.5395725933291, 14.0803421079744], [49.5400204316093, 14.0803896095649]],
    [[49.54013316171, 14.0792742661326], [49.5402453318105, 14.0792497270491]],
    [[49.5694825502873, 14.0882980447991], [49.5694821667327, 14.0883360504164]],
    [[49.5694821667327, 14.0883360504164], [49.5695054851343, 14.0884924653221]],
    [[49.5695054851343, 14.0884924653221], [49.569531904128, 14.0886185688247]],
    [[49.569531904128, 14.0886185688247], [49.5695303374029, 14.0887089290925]],
    [[49.5899926074142, 14.0744029388803], [49.5900431245916, 14.0745443777878]],
    [[49.5900431245916, 14.0745443777878], [49.5900759003757, 14.0746400417338]],
    [[49.6252184064448, 14.0494383263575], [49.6250450816177, 14.0502103993613]],
    [[49.6250450816177, 14.0502103993613], [49.6250387835177, 14.0502384874431]],
    [[49.6250387835177, 14.0502384874431], [49.6249967636195, 14.0503809603271]],
    [[49.6249967636195, 14.0503809603271], [49.6248797752593, 14.0508611932441]],
    [[49.6248797752593, 14.0508611932441], [49.6248006105142, 14.0512860002776]],
    [[49.6248006105142, 14.0512860002776], [49.6247854058445, 14.0513502985694]],
    [[49.6247854058445, 14.0513502985694], [49.6247258974724, 14.0516010516678]],
    [[49.6247258974724, 14.0516010516678], [49.6246777944966, 14.0517651309681]],
    [[49.6246777944966, 14.0517651309681], [49.6246861701885, 14.051795729098]],
    [[49.6246861701885, 14.051795729098], [49.6246171167009, 14.051826829892]],
    [[49.6246171167009, 14.051826829892], [49.624218817115, 14.0519305007657]],
    [[49.6199308888374, 14.0571268619606], [49.6197545845826, 14.0576308023073]],
    [[49.6167301495863, 14.0547520782395], [49.6163498261803, 14.054993331846]],
    [[49.6163498261803, 14.054993331846], [49.616398104367, 14.0551860898994]],
    [[49.6199058135291, 14.0569845954782], [49.6199481991509, 14.0568625805821]],
    [[49.6199481991509, 14.0568625805821], [49.6200078972151, 14.0569068058541]],
    [[49.6200078972151, 14.0569068058541], [49.6199308888374, 14.0571268619606]],
    [[49.6197545845826, 14.0576308023073], [49.6195149916925, 14.0583152918876]],
    [[49.6195149916925, 14.0583152918876], [49.6194616971426, 14.0582567791353]],
    [[49.6194616971426, 14.0582567791353], [49.6194892471515, 14.0581782356]],
    [[49.6182840207108, 14.0538247353413], [49.6183225862293, 14.0537716120405]],
    [[49.6183225862293, 14.0537716120405], [49.6182303170145, 14.0536377398795]],
    [[49.6182303170145, 14.0536377398795], [49.6181867103913, 14.0536998676792]],
    [[49.6181867103913, 14.0536998676792], [49.6180498964921, 14.0538603273207]],
    [[49.6180498964921, 14.0538603273207], [49.6178784147212, 14.0540406972111]],
    [[49.6077896921688, 14.0640890705582], [49.607847810656, 14.0640929513048]],
    [[49.6077373605702, 14.0651457166126], [49.6076669013335, 14.065508780741]],
    [[49.6076669013335, 14.065508780741], [49.6075731206624, 14.0654696469834]],
    [[49.6075731206624, 14.0654696469834], [49.6075748801623, 14.0654597408334]],
    [[49.6084883403686, 14.059903847832], [49.6084781369535, 14.0598664529447]],
    [[49.6084781369535, 14.0598664529447], [49.6081810078574, 14.0600365873638]],
    [[49.6081810078574, 14.0600365873638], [49.6073671363029, 14.0605233353126]],
    [[49.6073671363029, 14.0605233353126], [49.6071869386541, 14.0606296043631]],
    [[49.6057442616303, 14.0614777767265], [49.6057089626879, 14.0613451178005]],
    [[49.6057089626879, 14.0613451178005], [49.6055927745716, 14.0614141419903]],
    [[49.6055927745716, 14.0614141419903], [49.6049485970562, 14.0617971716906]],
    [[49.6065371543573, 14.060889403247], [49.6065204263004, 14.0608994264026]],
    [[49.6065204263004, 14.0608994264026], [49.6065140498472, 14.0608610208111]],
    [[49.6065140498472, 14.0608610208111], [49.6064545554866, 14.0608965923724]],
    [[49.6057232853614, 14.0613366090664], [49.6057089626879, 14.0613451178005]],
    [[49.6049485970561, 14.0617971716907], [49.6048681787705, 14.0617156169972]],
    [[49.6048681787705, 14.0617156169972], [49.6048385693087, 14.0617726973138]],
    [[49.6048385693087, 14.0617726973138], [49.6048923835985, 14.0618306005385]],
    [[49.6019944454587, 14.0580883769674], [49.6019465060747, 14.0580189554834]],
    [[49.6019465060747, 14.0580189554834], [49.6019379249971, 14.0580067230061]],
    [[49.6019379249971, 14.0580067230061], [49.6019283014686, 14.0579930421964]],
    [[49.6016463256638, 14.0573867556765], [49.6020974764762, 14.0580492570965]],
    [[49.602675393685, 14.0590749125834], [49.602707466915, 14.0591230798498]],
    [[49.602707466915, 14.0591230798498], [49.6027903189076, 14.0592811347033]],
    [[49.6027903189076, 14.0592811347033], [49.6028302001621, 14.0593890800741]],
    [[49.6028302001621, 14.0593890800741], [49.6028762729002, 14.0595638660844]],
    [[49.6028762729002, 14.0595638660844], [49.6030632655228, 14.0602235696403]],
    [[49.6030632655228, 14.0602235696403], [49.6033474741137, 14.0612092428048]],
    [[49.6033474741137, 14.0612092428048], [49.6034268605282, 14.061157316904]],
    [[49.6025580173773, 14.0587255660137], [49.6025675898671, 14.0587396775699]],
    [[49.6025675898671, 14.0587396775699], [49.6025941619688, 14.0587637562863]],
    [[49.6025941619688, 14.0587637562863], [49.6027069723761, 14.0589294944217]],
    [[49.6027069723761, 14.0589294944217], [49.6027726193506, 14.0590263383282]],
    [[49.6027726193506, 14.0590263383282], [49.6028333541667, 14.0591407503504]],
    [[49.6049952675372, 14.0676095664747], [49.6051400218463, 14.0685357243687]],
    [[49.6051400218463, 14.0685357243687], [49.6051620947951, 14.0686770711599]],
    [[49.6051620947951, 14.0686770711599], [49.6051626658149, 14.0686812784354]],
    [[49.6051626658149, 14.0686812784354], [49.6052590674339, 14.0694246143873]],
    [[49.6052590674339, 14.0694246143873], [49.6052691032801, 14.0695016564888]],
    [[49.6052691032801, 14.0695016564888], [49.6052796210421, 14.0695829254232]],
    [[49.6052796210421, 14.0695829254232], [49.6051479917863, 14.0696162216297]],
    [[49.5969414227694, 14.0666054500614], [49.5969209967158, 14.0666192880203]],
    [[49.5969209967158, 14.0666192880203], [49.596912958581, 14.0666246824273]],
    [[49.596912958581, 14.0666246824273], [49.5968508763672, 14.0666617269757]],
    [[49.5960603493881, 14.0685249717617], [49.5960754928769, 14.0685848293999]],
    [[49.5960754928769, 14.0685848293999], [49.5960061369484, 14.0686260723419]],
    [[49.5960061369484, 14.0686260723419], [49.5959901085908, 14.0685663307326]],
    [[49.5915364180406, 14.0710656172168], [49.5915862509129, 14.0713184855028]],
    [[49.5739818027287, 14.0880405615598], [49.5739507928999, 14.0880182357103]],
    [[49.5723017227382, 14.0864394649714], [49.5722970917429, 14.0864158978822]],
    [[49.5722970917429, 14.0864158978822], [49.5722741457858, 14.0863140772111]],
    [[49.5722741457858, 14.0863140772111], [49.5722076397183, 14.0863474731774]],
    [[49.5722076397183, 14.0863474731774], [49.5722365691313, 14.086486247292]],
    [[49.5723017227382, 14.0864394649714], [49.572381359035, 14.0864972886615]],
    [[49.572381359035, 14.0864972886615], [49.5723865231329, 14.0864755222533]],
    [[49.5723865231329, 14.0864755222533], [49.5724313844738, 14.0865054316318]],
    [[49.5724313844738, 14.0865054316318], [49.5724255483589, 14.0865293744952]],
    [[49.5724255483589, 14.0865293744952], [49.5724621634257, 14.0865559607158]],
    [[49.5724621634257, 14.0865559607158], [49.5724298481693, 14.0866737574724]],
    [[49.5724298481693, 14.0866737574724], [49.5724054677805, 14.0866558775091]],
    [[49.5268903610095, 14.0742482136308], [49.526861279181, 14.0742318447726]],
    [[49.526861279181, 14.0742318447726], [49.5265203340279, 14.074040818866]],
    [[49.5265203340279, 14.074040818866], [49.5263964158593, 14.0739706074672]],
    [[49.5263964158593, 14.0739706074672], [49.5261073480547, 14.0738199577264]],
    [[49.5261073480547, 14.0738199577264], [49.5261073480547, 14.0738199577264]],
    [[49.5261073480547, 14.0738199577264], [49.5261073480547, 14.0738199577264]],
    [[49.5261073480547, 14.0738199577264], [49.5261073480547, 14.0738199577264]],
    [[49.5261073480547, 14.0738199577264], [49.5259733937966, 14.0737502977853]],
    [[49.5259733937966, 14.0737502977853], [49.5259732524558, 14.0737809599982]],
    [[49.5259732524558, 14.0737809599982], [49.525970427367, 14.0738249703958]],
    [[49.525970427367, 14.0738249703958], [49.5259330433763, 14.0739981575174]],
    [[49.5290209683001, 14.0768903373242], [49.5289914055206, 14.0768829858986]],
    [[49.5289914055206, 14.0768829858986], [49.5289499691797, 14.0768760083615]],
    [[49.5289499691797, 14.0768760083615], [49.5289504804473, 14.0767994713646]],
    [[49.5318896171461, 14.0785162000886], [49.5318757458679, 14.0785496393646]],
    [[49.5318757458679, 14.0785496393646], [49.5318185158764, 14.0785259789871]],
    [[49.5318185158764, 14.0785259789871], [49.531817338702, 14.0784851447121]],
    [[49.6251137567, 14.0490909678966], [49.6251349362645, 14.0491058958207]],
    [[49.616735691654, 14.056212153025], [49.6167371968792, 14.0561369840912]],
    [[49.6167371968792, 14.0561369840912], [49.6167400742112, 14.0559910601917]],
    [[49.616717056555, 14.0559753669149], [49.6167400742112, 14.0559910601917]],
    [[49.6060447247241, 14.0624032599044], [49.6060378116277, 14.0625402291469]],
    [[49.6116336651841, 14.0592183231198], [49.6112614356741, 14.0594361864623]],
    [[49.6112614356741, 14.0594361864623], [49.6106676186264, 14.0597474052098]],
    [[49.6106676186264, 14.0597474052098], [49.6098237263979, 14.0601906692002]],
    [[49.6098237263979, 14.0601906692002], [49.608983292883, 14.060736718072]],
    [[49.608983292883, 14.060736718072], [49.6088333906782, 14.0608237036939]],
    [[49.6088333906782, 14.0608237036939], [49.6084407258902, 14.0610813578369]],
    [[49.6084407258902, 14.0610813578369], [49.6084253964272, 14.0610218282675]],
    [[49.6116336651841, 14.0592183231198], [49.6116199481189, 14.0591584982351]],
    [[49.6081103539869, 14.0611852538797], [49.6073810944206, 14.0617136152638]],
    [[49.6073810944206, 14.0617136152638], [49.6065779720696, 14.06231217779]],
    [[49.6065779720696, 14.06231217779], [49.6064207853744, 14.0624198962041]],
    [[49.6058622971188, 14.0626051062582], [49.6054133729664, 14.0628315040729]],
    [[49.6054133729664, 14.0628315040729], [49.6052688755738, 14.0628949294754]],
    [[49.6060378116277, 14.0625402291469], [49.6060348881998, 14.0625981500675]],
    [[49.6081103539869, 14.0611852538797], [49.6080935910874, 14.0611271926272]],
    [[49.6049076844427, 14.0630373862178], [49.6048451408518, 14.0630577520038]],
    [[49.6048451408518, 14.0630577520038], [49.6047455261839, 14.0631466441267]],
    [[49.6053638932512, 14.0677376743727], [49.6053475009815, 14.0677217421619]],
    [[49.6053093496015, 14.0676846615732], [49.6052605665615, 14.0676372478239]],
    [[49.6052605665615, 14.0676372478239], [49.6052096951207, 14.0676195253465]],
    [[49.6053638932512, 14.0677376743727], [49.6053786184178, 14.0677018025628]],
    [[49.6052096951207, 14.0676195253465], [49.6051684766419, 14.0676141542971]],
    [[49.6051684766419, 14.0676141542971], [49.605025175916, 14.067635961712]],
    [[49.605025175916, 14.067635961712], [49.605002934074, 14.067658616694]],
    [[49.5946214083007, 14.0693262824873], [49.5946893378586, 14.069569897032]],
    [[49.5945939605482, 14.0693471348262], [49.5946612417997, 14.0695884382023]],
    [[49.5946893378586, 14.069569897032], [49.5946612417997, 14.0695884382023]],
    [[49.5916534773471, 14.0722681888032], [49.5916432896214, 14.072315556437]],
    [[49.5916432896214, 14.072315556437], [49.5913884357979, 14.0725118420585]],
    [[49.5913884357979, 14.0725118420585], [49.5908870899743, 14.0731166795449]],
    [[49.5908870899743, 14.0731166795449], [49.5905200076891, 14.0736825918414]],
    [[49.5905200076891, 14.0736825918414], [49.5903609875966, 14.0740158636181]],
    [[49.5903609875966, 14.0740158636181], [49.5901995363309, 14.0742919743853]],
    [[49.5901995363309, 14.0742919743853], [49.5900809768982, 14.0743779800251]],
    [[49.5900809768982, 14.0743779800251], [49.5899926074142, 14.0744029388803]],
    [[49.5883282804428, 14.0759319821542], [49.5881947652412, 14.07618786871]],
    [[49.5881947652412, 14.07618786871], [49.5879420799722, 14.0765697968648]],
    [[49.5883282804428, 14.0759319821542], [49.5883490013784, 14.0759629801341]],
    [[49.5883490013784, 14.0759629801341], [49.5883627220125, 14.0759937249338]],
    [[49.5879420799722, 14.0765697968648], [49.587554609978, 14.0771554376752]],
    [[49.587554609978, 14.0771554376752], [49.5873184752198, 14.0774372412784]],
    [[49.5873184752198, 14.0774372412784], [49.586987549035, 14.0779410702986]],
    [[49.5844431646109, 14.0820036274446], [49.5844434652963, 14.0819582763087]],
    [[49.5874090697748, 14.079015083338], [49.5873848388904, 14.0790335006823]],
    [[49.5814790226165, 14.0845416261396], [49.5810425258804, 14.0848019568857]],
    [[49.5810425258804, 14.0848019568857], [49.5805736029994, 14.0851488325673]],
    [[49.5805736029994, 14.0851488325673], [49.5796608436336, 14.0858037358721]],
    [[49.5781650611465, 14.0864932211457], [49.5778557020925, 14.0866234607116]],
    [[49.5778557020925, 14.0866234607116], [49.5771526173151, 14.0868880469959]],
    [[49.5771526173151, 14.0868880469959], [49.5766024008897, 14.0871115442808]],
    [[49.5766024008897, 14.0871115442808], [49.5756146841703, 14.0874471307985]],
    [[49.5779350653311, 14.0865517962781], [49.5772025376493, 14.0868266181192]],
    [[49.5739507928999, 14.0880182357103], [49.5739085460611, 14.0880799018948]],
    [[49.5739085460611, 14.0880799018948], [49.5739223515514, 14.0881293914414]],
    [[49.5719317824355, 14.0873691840519], [49.5713807186146, 14.0874452699004]],
    [[49.5713807186146, 14.0874452699004], [49.5710577090816, 14.0875728494914]],
    [[49.5710577090816, 14.0875728494914], [49.5701678904665, 14.0875269161265]],
    [[49.5701678904665, 14.0875269161265], [49.5701408552036, 14.0875233398076]],
    [[49.5719317824355, 14.0873691840519], [49.5719322835942, 14.0873777662759]],
    [[49.5697165917951, 14.0874734300983], [49.568919167511, 14.0873961544786]],
    [[49.568919167511, 14.0873961544786], [49.5682261763507, 14.0873640898124]],
    [[49.5682261763507, 14.0873640898124], [49.5681454175034, 14.0873538918719]],
    [[49.5655647546211, 14.0868949781501], [49.5653789528552, 14.0868506579357]],
    [[49.5653789528552, 14.0868506579357], [49.5646457955245, 14.0865179328246]],
    [[49.5646457955245, 14.0865179328246], [49.5644212663003, 14.0855726850407]],
    [[49.5644212663003, 14.0855726850407], [49.563872984183, 14.0853326697157]],
    [[49.563872984183, 14.0853326697157], [49.5632588170669, 14.0854416335603]],
    [[49.5632588170669, 14.0854416335603], [49.5631572120891, 14.0854392617944]],
    [[49.5680662507876, 14.0873438950126], [49.5673305656318, 14.0872509971415]],
    [[49.5673305656318, 14.0872509971415], [49.566437069432, 14.0871030602593]],
    [[49.566437069432, 14.0871030602593], [49.5655647546211, 14.0868949781501]],
    [[49.5671272355679, 14.0879197160412], [49.5667298309315, 14.0877940399222]],
    [[49.5667298309315, 14.0877940399222], [49.5658378684627, 14.087561390088]],
    [[49.5658378684627, 14.087561390088], [49.5649536960428, 14.0872648170052]],
    [[49.5649536960428, 14.0872648170052], [49.5640656651703, 14.0869851688373]],
    [[49.5640656651703, 14.0869851688373], [49.5633793134152, 14.0867322038287]],
    [[49.5569463045827, 14.0830684245695], [49.556426070883, 14.0828654958943]],
    [[49.5543145050672, 14.0817921383882], [49.5542678936566, 14.0818084453397]],
    [[49.5542678936566, 14.0818084453397], [49.55358519035, 14.0814387204343]],
    [[49.55358519035, 14.0814387204343], [49.5531923562382, 14.0812042175443]],
    [[49.5531923562382, 14.0812042175443], [49.5524130818524, 14.0810425002385]],
    [[49.5524130818524, 14.0810425002385], [49.5524190331531, 14.0809896241993]],
    [[49.554294618853, 14.0809136238822], [49.5542905016027, 14.0809503195921]],
    [[49.5527631763328, 14.0804300205949], [49.5531399323267, 14.0806383343035]],
    [[49.5531399323267, 14.0806383343035], [49.5536384078578, 14.0807396657749]],
    [[49.5536384078578, 14.0807396657749], [49.554294618853, 14.0809136238822]],
    [[49.5404735376838, 14.0791013947729], [49.5404060855901, 14.0789314851217]],
    [[49.5404060855901, 14.0789314851217], [49.5403541073341, 14.0789388939012]],
    [[49.5427789869283, 14.0790270509116], [49.5427789869283, 14.0790270509116]],
    [[49.5427789869283, 14.0790270509116], [49.5427786025155, 14.0789789438607]],
    [[49.5427786025155, 14.0789789438607], [49.541819358018, 14.0790212547678]],
    [[49.541819358018, 14.0790212547678], [49.5409253417083, 14.0790906616138]],
    [[49.5409253417083, 14.0790906616138], [49.5404902266653, 14.0790585312644]],
    [[49.5404902266653, 14.0790585312644], [49.540421301228, 14.0788849104848]],
    [[49.540421301228, 14.0788849104848], [49.540353253642, 14.0788946097899]],
    [[49.5402542891807, 14.0789410302453], [49.5402272625234, 14.0792107653639]],
    [[49.5402542891807, 14.0789410302453], [49.5402773877719, 14.0789463677722]],
    [[49.5397390280386, 14.0791691387069], [49.5393041583959, 14.0792390824786]],
    [[49.5393041583959, 14.0792390824786], [49.5385977451321, 14.0792793825058]],
    [[49.5385977451321, 14.0792793825058], [49.5378870106833, 14.0792327943818]],
    [[49.5378870106833, 14.0792327943818], [49.5369892760541, 14.0791731561149]],
    [[49.5369892760541, 14.0791731561149], [49.5361497179995, 14.0790159656601]],
    [[49.5361497179995, 14.0790159656601], [49.5351660380339, 14.0786848386359]],
    [[49.5351660380339, 14.0786848386359], [49.5342031456338, 14.0783072119607]],
    [[49.5342031456338, 14.0783072119607], [49.533510446689, 14.0780816717897]],
    [[49.533510446689, 14.0780816717897], [49.5332062894014, 14.0779418409122]],
    [[49.5332062894014, 14.0779418409122], [49.5330200647781, 14.0778572320946]],
    [[49.5330200647781, 14.0778572320946], [49.5324061482489, 14.0775926606807]],
    [[49.5308738525961, 14.076919665533], [49.5308756093846, 14.0768827841662]],
    [[49.5308756093846, 14.0768827841662], [49.5316391733657, 14.077312273126]],
    [[49.5316391733657, 14.077312273126], [49.5316553574178, 14.0773587541353]],
    [[49.5305138604425, 14.0778318572054], [49.5305084574172, 14.0778288183497]],
    [[49.5305084574172, 14.0778288183497], [49.5303498715642, 14.0777349919624]],
    [[49.5303498715642, 14.0777349919624], [49.5301689308629, 14.0775930055281]],
    [[49.5301689308629, 14.0775930055281], [49.529946536148, 14.0774225490747]],
    [[49.529946536148, 14.0774225490747], [49.5297800021964, 14.0773384277477]],
    [[49.5297800021964, 14.0773384277477], [49.5296852478148, 14.0773169695188]],
    [[49.5296852478148, 14.0773169695188], [49.5294910634941, 14.0771976314787]],
    [[49.5294910634941, 14.0771976314787], [49.5291448447304, 14.0769655896943]],
    [[49.5291448447304, 14.0769655896943], [49.5290621982228, 14.0768989907522]],
    [[49.5284788999019, 14.0762944183813], [49.5283341668056, 14.076191998529]],
    [[49.5283341668056, 14.076191998529], [49.5282449307832, 14.0761567000973]],
    [[49.5282449307832, 14.0761567000973], [49.5280487258327, 14.0760170638925]],
    [[49.5280487258327, 14.0760170638925], [49.5276600080743, 14.0757415888457]],
    [[49.5276600080743, 14.0757415888457], [49.5273722844095, 14.0755576337844]],
    [[49.5273722844095, 14.0755576337844], [49.5270734251849, 14.0753548788674]],
    [[49.5270734251849, 14.0753548788674], [49.5270509635607, 14.0753535573606]],
    [[49.5270509635607, 14.0753535573606], [49.5270178895249, 14.0753610404996]],
    [[49.5270178895249, 14.0753610404996], [49.5269855506417, 14.075370948613]],
    [[49.5259330433763, 14.0739981575174], [49.5258905219971, 14.073976244958]],
    [[49.5258905219971, 14.073976244958], [49.5258791930803, 14.0740353169586]],
    [[49.5258791930803, 14.0740353169586], [49.5259205913085, 14.0740558433207]],
    [[49.5259205913085, 14.0740558433207], [49.5259126970794, 14.0740924142659]],
    [[49.5257940252777, 14.0745430380014], [49.5258044573502, 14.0745528939771]],
    [[49.5258044573502, 14.0745528939771], [49.5258337352536, 14.0745660782821]],
    [[49.5258337352536, 14.0745660782821], [49.5259148475611, 14.07463174137]],
    [[49.5259148475611, 14.07463174137], [49.5260822961839, 14.0747357146704]],
    [[49.5260822961839, 14.0747357146704], [49.5263649425259, 14.074898122734]],
    [[49.5263649425259, 14.074898122734], [49.5264050891934, 14.0749213551419]],
    [[49.5264050891934, 14.0749213551419], [49.5264162739287, 14.0749289748278]],
    [[49.5257958784642, 14.0744961390541], [49.5257940252777, 14.0745430380014]],
    [[49.6028858374077, 14.0592484474948], [49.6028974159482, 14.0592773521205]],
    [[49.6028974159482, 14.0592773521205], [49.602920809013, 14.0593622213119]],
    [[49.602920809013, 14.0593622213119], [49.6029623709321, 14.0595206677658]],
    [[49.6029623709321, 14.0595206677658], [49.6031733524825, 14.0602716799405]],
    [[49.6031733524825, 14.0602716799405], [49.6031733524825, 14.0602716799405]],
    [[49.6031733524825, 14.0602716799405], [49.6031951822413, 14.0603494859279]],
    [[49.6031951822413, 14.0603494859279], [49.6034268605282, 14.061157316904]],
    [[49.603741482387, 14.0623449832896], [49.603761963703, 14.0624190336367]],
    [[49.603761963703, 14.0624190336367], [49.603777620174, 14.0624615795451]],
    [[49.603777620174, 14.0624615795451], [49.603784928009, 14.062478704154]],
    [[49.6065371543573, 14.060889403247], [49.6065642483245, 14.0609964093619]],
    [[49.5334726831004, 14.0793019614059], [49.5335171125079, 14.079331400612]],
    [[49.5335171125079, 14.079331400612], [49.5335677878501, 14.0791630857732]],
    [[49.5335677878501, 14.0791630857732], [49.5334874699621, 14.0791067506861]],
    [[49.5722365691313, 14.086486247292], [49.5721436424957, 14.0865351012801]],
    [[49.5721436424957, 14.0865351012801], [49.5721414818033, 14.0866748989259]],
    [[49.5721414818033, 14.0866748989259], [49.5721822360651, 14.0866860447026]],
    [[49.6076350405772, 14.0641601755298], [49.6075701550425, 14.0641969502484]],
    [[49.6075701550425, 14.0641969502484], [49.6075839506019, 14.0643053739418]],
    [[49.6075839506019, 14.0643053739418], [49.6076455071714, 14.0643040431469]],
    [[49.6180697311506, 14.0565755019711], [49.6180769793533, 14.0566035290181]],
    [[49.6180769793533, 14.0566035290181], [49.6181362611912, 14.0566031308831]],
    [[49.6181362611912, 14.0566031308831], [49.6181445506003, 14.0565725926407]],
    [[49.6027514140735, 14.060093599924], [49.6027654904672, 14.0597856749573]],
    [[49.6027654904672, 14.0597856749573], [49.6027654918926, 14.0597856579511]],
    [[49.6141888473582, 14.0574623179053], [49.6143041065898, 14.0573769733923]],
    [[49.6143041065898, 14.0573769733923], [49.6143394027333, 14.0573540820775]],
    [[49.6050350348254, 14.0687183293091], [49.6050235338956, 14.0687201290484]],
    [[49.6050235338956, 14.0687201290484], [49.6049822916462, 14.0685723833795]],
    [[49.6065213080601, 14.061067168406], [49.6066985431208, 14.0608808855281]],
    [[49.6066985431208, 14.0608808855281], [49.6069168341081, 14.0606739612901]],
    [[49.6069168341081, 14.0606739612901], [49.6070377866316, 14.0605893589958]],
    [[49.6070377866316, 14.0605893589958], [49.6071612244355, 14.0605197300998]],
    [[49.6071612244355, 14.0605197300998], [49.6071907566158, 14.0606459181764]],
    [[49.6066189063828, 14.0609645879147], [49.6065642483245, 14.0609964093619]],
    [[49.5254031843795, 14.0737293855503], [49.5255342469191, 14.0738227529835]],
    [[49.5255342469191, 14.0738227529835], [49.5255870032043, 14.0739145159411]],
    [[49.5255870032043, 14.0739145159411], [49.5256328652653, 14.0740404153379]],
    [[49.5256924002156, 14.0742693487512], [49.5257958784642, 14.0744961390541]],
    [[49.5259126970794, 14.0740924142659], [49.5258070342121, 14.0740171048583]],
    [[49.5258070342121, 14.0740171048583], [49.5255100864728, 14.0735074263722]],
    [[49.5255100864728, 14.0735074263722], [49.5254112671442, 14.073439220537]],
    [[49.5254112671442, 14.073439220537], [49.5253699214311, 14.0734104187287]],
    [[49.5253699214311, 14.0734104187287], [49.5253047565274, 14.0736592976347]],
    [[49.5253047565274, 14.0736592976347], [49.5254031843795, 14.0737293855503]],
    [[49.6027654904672, 14.0597856749573], [49.6029056675886, 14.0596675683179]],
    [[49.602574571753, 14.0587101182634], [49.6025570550624, 14.0586677880215]],
    [[49.6025570550624, 14.0586677880215], [49.6024879911514, 14.0585883174209]],
    [[49.5916945103733, 14.0718670501817], [49.5917144047432, 14.0719681785029]],
    [[49.5346403031948, 14.0786603120875], [49.534352446662, 14.0785164173779]],
    [[49.534352446662, 14.0785164173779], [49.5341784572081, 14.0784470434535]],
    [[49.5341784572081, 14.0784470434535], [49.5339914800544, 14.0784327066228]],
    [[49.5339914800544, 14.0784327066228], [49.5337707140065, 14.0783526924876]],
    [[49.5346403031948, 14.0786603120875], [49.5346339653334, 14.0786902440604]],
    [[49.5337707140065, 14.0783526924876], [49.5337662601604, 14.0783817027196]],
    [[49.6176998277824, 14.0553845188977], [49.6173353867738, 14.0556036251999]],
    [[49.6172331313342, 14.0556651020292], [49.6165618581749, 14.056068671007]],
    [[49.5946214083007, 14.0693262824873], [49.5944401995389, 14.0686761783189]],
    [[49.5944401995389, 14.0686761783189], [49.5941427052607, 14.0687436486814]],
    [[49.5941427052607, 14.0687436486814], [49.5937868683643, 14.0688242424489]],
    [[49.5945939605482, 14.0693471348262], [49.5944213766752, 14.0687279872886]],
    [[49.5944213766752, 14.0687279872886], [49.594147162639, 14.0687901775045]],
    [[49.594147162639, 14.0687901775045], [49.5941471597369, 14.0687901781623]],
    [[49.5941471597369, 14.0687901781623], [49.5937913213564, 14.0688707719259]],
    [[49.5937868683643, 14.0688242424489], [49.5937913213564, 14.0688707719259]],
    [[49.5878988917609, 14.076635074294], [49.58789884861, 14.0766351395149]],
    [[49.6040363125206, 14.0623284166372], [49.6039021373821, 14.0624085648984]],
    [[49.6039021373821, 14.0624085648984], [49.603784928009, 14.062478704154]],
    [[49.6043004407119, 14.0621686252017], [49.6042210420684, 14.0622165001142]],
    [[49.6042210420684, 14.0622165001142], [49.6041623737986, 14.0622518637094]],
    [[49.6041623737986, 14.0622518637094], [49.6040363125206, 14.0623284166372]],
    [[49.6043004407119, 14.0621686252017], [49.6043885767131, 14.0621150610332]],
    [[49.6043885767131, 14.0621150610332], [49.6044267435653, 14.0620917389417]],
    [[49.6044267435653, 14.0620917389417], [49.6048923835985, 14.0618306005385]],
    [[49.6048923835985, 14.0618306005385], [49.6049485970562, 14.0617971716906]],
    [[49.6058439673585, 14.0612617887029], [49.6059528583512, 14.0611969614251]],
    [[49.5816646748447, 14.0843190622068], [49.5816737338377, 14.0843462053536]],
    [[49.5816144457865, 14.0843593500464], [49.5816227639225, 14.0843842733953]],
    [[49.5814675048925, 14.0845010311847], [49.5811856543026, 14.0846616309313]],
    [[49.5543060907462, 14.0818769035013], [49.5543145050672, 14.0817921383882]],
    [[49.6053786184178, 14.0677018025628], [49.6055662480569, 14.0678986749759]],
    [[49.6055662480569, 14.0678986749759], [49.6056307960683, 14.0680779525867]],
    [[49.6056307960683, 14.0680779525867], [49.6057530371943, 14.0678982710191]],
    [[49.6057530371943, 14.0678982710191], [49.6054320285799, 14.0675964561096]],
    [[49.6053844454574, 14.0675517184728], [49.60523384169, 14.0674101215418]],
    [[49.5745558472689, 14.0871645082924], [49.5746152578358, 14.0877275915631]],
    [[49.5745878811862, 14.0877357653696], [49.5745280609547, 14.0871688030484]],
    [[49.602574571753, 14.0587101182634], [49.6025675898671, 14.0587396775699]],
    [[49.5871846410683, 14.0787867299256], [49.5871526162308, 14.0787331078771]],
    [[49.5919605077625, 14.0722318580024], [49.5921137959008, 14.0723317561552]],
    [[49.5921137959008, 14.0723317561552], [49.5920862903924, 14.0724317747738]],
    [[49.5920862903924, 14.0724317747738], [49.5919282045883, 14.0723234600989]],
    [[49.6051479917863, 14.0696162216297], [49.6051445876585, 14.0695812067925]],
    [[49.6051445876585, 14.0695812067925], [49.6051273718467, 14.0694452243548]],
    [[49.6051273718467, 14.0694452243548], [49.6050350348254, 14.0687183293091]],
    [[49.6037351804691, 14.0623220480697], [49.603722195405, 14.0622750034584]],
    [[49.603722195405, 14.0622750034584], [49.6036323947115, 14.0623233414813]],
    [[49.6028333541667, 14.0591407503504], [49.6028858374077, 14.0592484474948]],
    [[49.6077373605702, 14.0651457166126], [49.6078284266783, 14.0646773928652]],
    [[49.6076141239787, 14.0652577068956], [49.6075748801623, 14.0654597408334]],
    [[49.6082555436808, 14.0609593673546], [49.6082548246057, 14.0614468878777]],
    [[49.6082548246057, 14.0614468878777], [49.6082530883751, 14.0618135940838]],
    [[49.6082530883751, 14.0618135940838], [49.6082450113748, 14.0619343056167]],
    [[49.6082450113748, 14.0619343056167], [49.6082433144175, 14.061959136673]],
    [[49.6082433144175, 14.061959136673], [49.6082243937853, 14.0620647552027]],
    [[49.6082243937853, 14.0620647552027], [49.6080444122418, 14.0630224747234]],
    [[49.6080444122418, 14.0630224747234], [49.6079619658596, 14.0634739604983]],
    [[49.6079619658596, 14.0634739604983], [49.6078657409659, 14.0639973686575]],
    [[49.6078657409659, 14.0639973686575], [49.6078182464895, 14.0642505504755]],
    [[49.6078284266783, 14.0646773928652], [49.6079396687797, 14.0641179901111]],
    [[49.6079396687797, 14.0641179901111], [49.6080076871015, 14.0637607957222]],
    [[49.6080076871015, 14.0637607957222], [49.6081031362939, 14.0632329431729]],
    [[49.6081031362939, 14.0632329431729], [49.6082869258639, 14.0622574770358]],
    [[49.6082869258639, 14.0622574770358], [49.6083092687764, 14.0621456572071]],
    [[49.6083092687764, 14.0621456572071], [49.6083337382595, 14.0620205125027]],
    [[49.6083337382595, 14.0620205125027], [49.608342748424, 14.0619343978229]],
    [[49.608342748424, 14.0619343978229], [49.6083460162084, 14.0618245242239]],
    [[49.6083460162084, 14.0618245242239], [49.6083471265293, 14.0617511832558]],
    [[49.6083471265293, 14.0617511832558], [49.6083507421339, 14.061451850614]],
    [[49.6083507421339, 14.061451850614], [49.6083524733575, 14.0609301440319]],
    [[49.5256328652653, 14.0740404153379], [49.5256702759726, 14.0741713066347]],
    [[49.5256702759726, 14.0741713066347], [49.5256924002156, 14.0742693487512]],
    [[49.5965861215854, 14.068220417988], [49.5965818596184, 14.0682225656262]],
    [[49.5549942589965, 14.0819412416632], [49.5549718460277, 14.0819584942454]],
    [[49.5549718460277, 14.0819584942454], [49.5549739271448, 14.0819645663394]],
    [[49.5549739271448, 14.0819645663394], [49.5549953414364, 14.0819481351597]],
    [[49.5963720125282, 14.0675151189144], [49.5963762098015, 14.0674767016232]],
    [[49.5963762098015, 14.0674767016232], [49.5963730428825, 14.0674662290984]],
    [[49.5963690276248, 14.067469348622], [49.5963715447863, 14.0674776724962]],
    [[49.5963715447863, 14.0674776724962], [49.5963673719199, 14.0675158663868]],
    [[49.5554517491137, 14.0816382844099], [49.5554474203203, 14.0816651786117]],
    [[49.5554474203203, 14.0816651786117], [49.5552846930156, 14.0818336671648]],
    [[49.5552846930156, 14.0818336671648], [49.5550677443476, 14.0819776207201]],
    [[49.5550677443476, 14.0819776207201], [49.5550086421107, 14.0819479174099]],
    [[49.5550086421107, 14.0819479174099], [49.5549953414364, 14.0819481351597]],
    [[49.5549942589965, 14.0819412416632], [49.5550093350739, 14.0819409948455]],
    [[49.5550093350739, 14.0819409948455], [49.5550675244902, 14.0819702393855]],
    [[49.5550675244902, 14.0819702393855], [49.5552825277309, 14.0818275766621]],
    [[49.5552825277309, 14.0818275766621], [49.5554434720272, 14.0816609342294]],
    [[49.5554434720272, 14.0816609342294], [49.5554470782259, 14.0816385294094]],
    [[49.5285384478681, 14.0763517614429], [49.5285499256617, 14.0763773143472]],
    [[49.5285499256617, 14.0763773143472], [49.5287910066183, 14.0766043468083]],
    [[49.5287910066183, 14.0766043468083], [49.5288566473715, 14.0767056768411]],
    [[49.5288566473715, 14.0767056768411], [49.5289504804473, 14.0767994713646]],
    [[49.5310055896849, 14.0781072910774], [49.530844978093, 14.0780223410645]],
    [[49.530844978093, 14.0780223410645], [49.530695119251, 14.0779377782254]],
    [[49.530695119251, 14.0779377782254], [49.5304489129872, 14.0777939044924]],
    [[49.5319262495343, 14.0785203389368], [49.5319570579058, 14.0785920618169]],
    [[49.5319570579058, 14.0785920618169], [49.5319340870412, 14.0786135752745]],
    [[49.5319340870412, 14.0786135752745], [49.5318927872414, 14.0785176132728]],
    [[49.5318927872414, 14.0785176132728], [49.5318896171461, 14.0785162000886]],
    [[49.531817338702, 14.0784851447121], [49.5317266872679, 14.0784348502992]],
    [[49.5317266872679, 14.0784348502992], [49.5316371321884, 14.0784205560359]],
    [[49.5316371321884, 14.0784205560359], [49.5312938033384, 14.078257498803]],
    [[49.5312938033384, 14.078257498803], [49.5310055896849, 14.0781072910774]],
    [[49.5403549999824, 14.0801344848795], [49.5403140350116, 14.080088205454]],
    [[49.5402768122317, 14.0800981763357], [49.5403070727103, 14.0801321789793]],
    [[49.5403704561723, 14.0802270306471], [49.540365993318, 14.0802267555954]],
    [[49.5736091039391, 14.0872774798307], [49.5736135330538, 14.0873063875247]],
    [[49.5736480198176, 14.0879715608003], [49.5736662473807, 14.0879909540934]],
    [[49.5736662473807, 14.0879909540934], [49.5740733455928, 14.0879041963362]],
    [[49.5743948346037, 14.0878645694046], [49.5744496523299, 14.0878847695304]],
    [[49.5744496523299, 14.0878847695304], [49.5745341765798, 14.0879364987311]],
    [[49.5745341765798, 14.0879364987311], [49.5746181057329, 14.0880223659581]],
    [[49.5746216455106, 14.0880439378788], [49.5746196735742, 14.0880506118699]],
    [[49.5735916573395, 14.0872791543484], [49.5735899682874, 14.0872855123917]],
    [[49.6054224605724, 14.0561937256403], [49.6054630170286, 14.0561726465293]],
    [[49.6054630170286, 14.0561726465293], [49.6054418847511, 14.0560885464785]],
    [[49.6054418847511, 14.0560885464785], [49.6054008162854, 14.0561181507607]],
    [[49.6054008162854, 14.0561181507607], [49.6054129900996, 14.0561407105126]],
    [[49.6250468175649, 14.0507234595961], [49.625034596359, 14.0507072561177]],
    [[49.625034596359, 14.0507072561177], [49.6249549249617, 14.0507185977731]],
    [[49.6249549249617, 14.0507185977731], [49.6247625538234, 14.0506218950944]],
    [[49.6223928752269, 14.0529912546011], [49.6223856386346, 14.0529930164027]],
    [[49.6223856386346, 14.0529930164027], [49.622384933361, 14.0529861623749]],
    [[49.622384933361, 14.0529861623749], [49.6223911060164, 14.0529848925694]],
    [[49.6247647633978, 14.0506157254206], [49.6246459243357, 14.0504641150095]],
    [[49.6246459243357, 14.0504641150095], [49.6246356562337, 14.0504619588445]],
    [[49.6250497515842, 14.050718215733], [49.6250360836362, 14.0507000940947]],
    [[49.6250360836362, 14.0507000940947], [49.6249554367683, 14.0507115746256]],
    [[49.6249554367683, 14.0507115746256], [49.6247647633978, 14.0506157254206]],
    [[49.6225895092822, 14.0529082765827], [49.6225731669962, 14.0529568653762]],
    [[49.6225731669962, 14.0529568653762], [49.6224764856464, 14.0529819694369]],
    [[49.6224764856464, 14.0529819694369], [49.6224255611871, 14.052992305218]],
    [[49.6224255611871, 14.052992305218], [49.6223943281159, 14.0530131671518]],
    [[49.6223943281159, 14.0530131671518], [49.6223624608119, 14.0529894234703]],
    [[49.6250570866314, 14.0507051060725], [49.6250398018272, 14.0506821890351]],
    [[49.6250398018272, 14.0506821890351], [49.624956716283, 14.0506940167564]],
    [[49.624956716283, 14.0506940167564], [49.6249244520571, 14.0506777978759]],
    [[49.6250394825156, 14.0507365692511], [49.6250308781642, 14.0507251611735]],
    [[49.6250308781642, 14.0507251611735], [49.6249536454432, 14.050736155641]],
    [[49.6249536454432, 14.050736155641], [49.6249149715971, 14.0507167146799]],
    [[49.6246253210916, 14.0504423265864], [49.6245131993962, 14.0506507601171]],
    [[49.6245131993962, 14.0506507601171], [49.6244473910298, 14.0507514096003]],
    [[49.6244473910298, 14.0507514096003], [49.6243916723809, 14.0508292383058]],
    [[49.6243916723809, 14.0508292383058], [49.6242607637686, 14.0510054412829]],
    [[49.6242607637686, 14.0510054412829], [49.6241536055849, 14.0511302128278]],
    [[49.6241536055849, 14.0511302128278], [49.6239767314587, 14.0513156591538]],
    [[49.6239767314587, 14.0513156591538], [49.6236629729057, 14.051541718767]],
    [[49.6236629729057, 14.051541718767], [49.6234221250172, 14.0516720468623]],
    [[49.6234221250172, 14.0516720468623], [49.6229950678506, 14.0518409660178]],
    [[49.6229950678506, 14.0518409660178], [49.622662066806, 14.0519375847682]],
    [[49.622662066806, 14.0519375847682], [49.6224276135167, 14.0519996366789]],
    [[49.6224276135167, 14.0519996366789], [49.6222837005755, 14.0520298687835]],
    [[49.6224736861985, 14.0520123534656], [49.6225699770794, 14.0529169990007]],
    [[49.6225699770794, 14.0529169990007], [49.6225642466031, 14.0529311091315]],
    [[49.6225642466031, 14.0529311091315], [49.6224738246568, 14.0529545919755]],
    [[49.6224738246568, 14.0529545919755], [49.6224207488816, 14.0529653609042]],
    [[49.6224207488816, 14.0529653609042], [49.6223911060164, 14.0529848925694]],
    [[49.6250570866314, 14.0507051060725], [49.6250394825156, 14.0507365692511]],
    [[49.6246489243118, 14.0503953538644], [49.6247185842052, 14.0502550722714]],
    [[49.6247185842052, 14.0502550722714], [49.6248001921428, 14.0500696435822]],
    [[49.6248001921428, 14.0500696435822], [49.6249189631244, 14.0497788835617]],
    [[49.6249189631244, 14.0497788835617], [49.6249862663036, 14.0495641324948]],
    [[49.6249862663036, 14.0495641324948], [49.6250920843355, 14.0491610376016]],
    [[49.6250920843355, 14.0491610376016], [49.6251137567, 14.0490909678966]],
    [[49.6246299929789, 14.0504607696367], [49.6245215083796, 14.050662441773]],
    [[49.6245215083796, 14.050662441773], [49.6244551324331, 14.050763959316]],
    [[49.6244551324331, 14.050763959316], [49.6243991487379, 14.0508421582281]],
    [[49.6243991487379, 14.0508421582281], [49.6242678621828, 14.05101886988]],
    [[49.6242678621828, 14.05101886988], [49.6241601635121, 14.0511442707204]],
    [[49.6241601635121, 14.0511442707204], [49.6239823131616, 14.051330740529]],
    [[49.6239823131616, 14.051330740529], [49.62366722958, 14.0515577547111]],
    [[49.62366722958, 14.0515577547111], [49.6234253935304, 14.0516886174412]],
    [[49.6234253935304, 14.0516886174412], [49.6229975100507, 14.0518578632917]],
    [[49.6229975100507, 14.0518578632917], [49.6226640662934, 14.0519546103772]],
    [[49.6226640662934, 14.0519546103772], [49.622472959238, 14.0520055239938]],
    [[49.6247625538234, 14.0506218950944], [49.6246440442624, 14.0504707050286]],
    [[49.6246440442624, 14.0504707050286], [49.624631861733, 14.0504681468578]],
    [[49.624631861733, 14.0504681468578], [49.6245248319725, 14.0506671144365]],
    [[49.6245248319725, 14.0506671144365], [49.624458228994, 14.0507689792033]],
    [[49.624458228994, 14.0507689792033], [49.6244021392802, 14.0508473261981]],
    [[49.6244021392802, 14.0508473261981], [49.6243315768224, 14.0509423033958]],
    [[49.6251330938527, 14.0491045972389], [49.6251064496563, 14.0491709664282]],
    [[49.6245758212849, 14.0513067737478], [49.6244382441068, 14.0511435898989]],
    [[49.6244382441068, 14.0511435898989], [49.6243317350847, 14.0509522503046]],
    [[49.6243213881806, 14.0509560172949], [49.624270701548, 14.0510242413199]],
    [[49.624270701548, 14.0510242413199], [49.6241627866826, 14.0511498938786]],
    [[49.6241627866826, 14.0511498938786], [49.6239845458422, 14.0513367730801]],
    [[49.6239845458422, 14.0513367730801], [49.6236689322491, 14.0515641690895]],
    [[49.6236689322491, 14.0515641690895], [49.6234267009351, 14.0516952456735]],
    [[49.6234267009351, 14.0516952456735], [49.62299848693, 14.0518646222017]],
    [[49.62299848693, 14.0518646222017], [49.6226648660876, 14.0519614206211]],
    [[49.6226648660876, 14.0519614206211], [49.6224781163963, 14.052011173353]],
    [[49.6224781163963, 14.052011173353], [49.6225746878526, 14.0529184549673]],
    [[49.6225746878526, 14.0529184549673], [49.6225669874912, 14.0529374155381]],
    [[49.6225669874912, 14.0529374155381], [49.6224744926179, 14.0529614367153]],
    [[49.6224744926179, 14.0529614367153], [49.6224219495501, 14.0529720975519]],
    [[49.6224219495501, 14.0529720975519], [49.6223928752269, 14.0529912546011]],
    [[49.6245730771791, 14.0513122557937], [49.6244351322345, 14.0511486357135]],
    [[49.6244351322345, 14.0511486357135], [49.6243284023685, 14.0509568993687]],
    [[49.6243284023685, 14.0509568993687], [49.6243213881806, 14.0509560172949]],
    [[49.6246356562337, 14.0504619588445], [49.6247313590896, 14.0502692319631]],
    [[49.6247313590896, 14.0502692319631], [49.6248133701058, 14.0500828874264]],
    [[49.6248133701058, 14.0500828874264], [49.6249327528493, 14.0497906297947]],
    [[49.6249327528493, 14.0497906297947], [49.6250006625964, 14.049573943306]],
    [[49.6250006625964, 14.049573943306], [49.6251064496563, 14.0491709664282]],
    [[49.6243317350847, 14.0509522503046], [49.6243315768224, 14.0509423033958]],
    [[49.6245758212849, 14.0513067737478], [49.6245730771791, 14.0513122557937]],
    [[49.6246491968069, 14.050401906351], [49.6246359944072, 14.0504487575472]],
    [[49.6246472349651, 14.0504262591237], [49.6247277091228, 14.0502651863361]],
    [[49.6247277091228, 14.0502651863361], [49.6248096049736, 14.0500791034702]],
    [[49.6248096049736, 14.0500791034702], [49.624928812928, 14.0497872737274]],
    [[49.624928812928, 14.0497872737274], [49.62499654937, 14.0495711402165]],
    [[49.62499654937, 14.0495711402165], [49.6251023782355, 14.0491680040772]],
    [[49.6251023782355, 14.0491680040772], [49.6251239772278, 14.0490981715964]],
    [[49.6246299929789, 14.0504607696367], [49.6246359944072, 14.0504487575472]],
    [[49.6246403395311, 14.0503902960191], [49.6246489243118, 14.0503953538644]],
    [[49.6246403395311, 14.0503902960191], [49.6246253210916, 14.0504423265864]],
    [[49.6222772099235, 14.0519763636875], [49.6217395544614, 14.0521095887124]],
    [[49.6217395544614, 14.0521095887124], [49.6210962146997, 14.052356528579]],
    [[49.6246533213403, 14.0504046604666], [49.6246472349651, 14.0504262591237]],
    [[49.6224372867174, 14.0520220484169], [49.6220667452482, 14.0520998904544]],
    [[49.6220667452482, 14.0520998904544], [49.6217566925806, 14.0521579576358]],
    [[49.6217566925806, 14.0521579576358], [49.6215287568475, 14.0522319772418]],
    [[49.6215287568475, 14.0522319772418], [49.6214476172306, 14.0522612009402]],
    [[49.6246491968069, 14.050401906351], [49.6246533213403, 14.0504046604666]],
    [[49.6214427648255, 14.0522559001823], [49.6214405015161, 14.052253427748]],
    [[49.6214405015161, 14.052253427748], [49.6214374038591, 14.0522388279747]],
    [[49.6222772099235, 14.0519763636875], [49.6222837005755, 14.0520298687835]],
    [[49.6224366055798, 14.0520152090676], [49.6220661697487, 14.0520930268526]],
    [[49.6220661697487, 14.0520930268526], [49.6217559556535, 14.0521511243099]],
    [[49.6217559556535, 14.0521511243099], [49.6215277805406, 14.0522252216838]],
    [[49.6215277805406, 14.0522252216838], [49.6214485664004, 14.052253751904]],
    [[49.6214485664004, 14.052253751904], [49.6214443143146, 14.0522491069344]],
    [[49.6214443143146, 14.0522491069344], [49.6214416775851, 14.05223667959]],
    [[49.6214416775851, 14.05223667959], [49.6214374038591, 14.0522388279747]],
    [[49.6224366055798, 14.0520152090676], [49.622472959238, 14.0520055239938]],
    [[49.6224372867174, 14.0520220484169], [49.6224736861985, 14.0520123534656]],
    [[49.6210962146997, 14.052356528579], [49.6210487358523, 14.0523692759759]],
    [[49.6210487358523, 14.0523692759759], [49.620922043746, 14.0523900487116]],
    [[49.620922043746, 14.0523900487116], [49.6208716996429, 14.0523580836668]],
    [[49.6208716996429, 14.0523580836668], [49.620779457523, 14.0523572678273]],
    [[49.620779457523, 14.0523572678273], [49.6207203079714, 14.0523796970071]],
    [[49.620680021341, 14.0524619038361], [49.6206752486986, 14.0524716426282]],
    [[49.6206752486986, 14.0524716426282], [49.6205980959344, 14.0525063819016]],
    [[49.6205980959344, 14.0525063819016], [49.6204868942877, 14.0525771386822]],
    [[49.6204868942877, 14.0525771386822], [49.6203471738954, 14.052638791131]],
    [[49.6203471738954, 14.052638791131], [49.6202530801597, 14.0526701590949]],
    [[49.6202530801597, 14.0526701590949], [49.6201666269401, 14.0527133293816]],
    [[49.6201666269401, 14.0527133293816], [49.6200819759314, 14.0527441706513]],
    [[49.6200819759314, 14.0527441706513], [49.620037547758, 14.0527522705913]],
    [[49.620037547758, 14.0527522705913], [49.619974572819, 14.0527862410881]],
    [[49.6214476172306, 14.0522612009402], [49.6212016585106, 14.0523528048088]],
    [[49.6212016585106, 14.0523528048088], [49.6210509235926, 14.0523932750088]],
    [[49.6210509235926, 14.0523932750088], [49.6209197371779, 14.0524147845755]],
    [[49.6209197371779, 14.0524147845755], [49.6208685382191, 14.0523822767276]],
    [[49.6208685382191, 14.0523822767276], [49.6207813226686, 14.0523815053025]],
    [[49.6207813226686, 14.0523815053025], [49.6207295023277, 14.0524011552629]],
    [[49.6207295023277, 14.0524011552629], [49.6206846710423, 14.0524926356283]],
    [[49.6206846710423, 14.0524926356283], [49.6206033256975, 14.0525292626443]],
    [[49.620492084669, 14.0526000444296], [49.620351011639, 14.0526622936708]],
    [[49.620351011639, 14.0526622936708], [49.6202571837956, 14.0526935729496]],
    [[49.6202571837956, 14.0526935729496], [49.6201708740732, 14.052736671539]],
    [[49.6201708740732, 14.052736671539], [49.6200847226178, 14.0527680594311]],
    [[49.6200847226178, 14.0527680594311], [49.6200411180769, 14.0527760091892]],
    [[49.6200411180769, 14.0527760091892], [49.6198786459023, 14.052863651088]],
    [[49.6214427648255, 14.0522559001823], [49.6212007426955, 14.0523460259824]],
    [[49.6212007426955, 14.0523460259824], [49.6210502985245, 14.052386418142]],
    [[49.6210502985245, 14.052386418142], [49.6209203961979, 14.052407717186]],
    [[49.6209203961979, 14.052407717186], [49.6208694414835, 14.0523753644248]],
    [[49.6208694414835, 14.0523753644248], [49.6207807897703, 14.0523745803094]],
    [[49.6207807897703, 14.0523745803094], [49.6207268753692, 14.0523950243319]],
    [[49.6207268753692, 14.0523950243319], [49.6206819789445, 14.0524866376274]],
    [[49.6206819789445, 14.0524866376274], [49.6206018314799, 14.0525227252887]],
    [[49.6206018314799, 14.0525227252887], [49.6204906017034, 14.0525934999298]],
    [[49.6204906017034, 14.0525934999298], [49.6203499151413, 14.052655578659]],
    [[49.6203499151413, 14.052655578659], [49.6202560113287, 14.0526868832764]],
    [[49.6202560113287, 14.0526868832764], [49.6201696606071, 14.0527300023508]],
    [[49.6201696606071, 14.0527300023508], [49.6200839378508, 14.0527612340651]],
    [[49.6200839378508, 14.0527612340651], [49.6200400979862, 14.0527692267323]],
    [[49.6200400979862, 14.0527692267323], [49.6198771589589, 14.0528571204869]],
    [[49.6206033256975, 14.0525292626443], [49.6205661975843, 14.0525652060204]],
    [[49.6205661975843, 14.0525652060204], [49.620492084669, 14.0526000444296]],
    [[49.620680021341, 14.0524619038361], [49.6206725666183, 14.0523938399955]],
    [[49.6206725666183, 14.0523938399955], [49.6207203079714, 14.0523796970071]],
    [[49.6198442966618, 14.052682536668], [49.6196800796187, 14.0526151195772]],
    [[49.6196800796187, 14.0526151195772], [49.6196585299992, 14.0527097206734]],
    [[49.6196585299992, 14.0527097206734], [49.6198718759681, 14.0528415988428]],
    [[49.6223716003434, 14.0529861570264], [49.6223878161766, 14.052998513781]],
    [[49.6223878161766, 14.052998513781], [49.6223944123086, 14.0529780040859]],
    [[49.6208591175785, 14.0535244117607], [49.6208645508039, 14.0535377746438]],
    [[49.6208645508039, 14.0535377746438], [49.6208688828646, 14.0535687346798]],
    [[49.6208688828646, 14.0535687346798], [49.6208355063546, 14.0535797994085]],
    [[49.6208355063546, 14.0535797994085], [49.6208543084374, 14.0536329079779]],
    [[49.6208646410211, 14.0536704998552], [49.6208521877736, 14.0536618173806]],
    [[49.6208521877736, 14.0536618173806], [49.6208477653794, 14.0536632003649]],
    [[49.6208477653794, 14.0536632003649], [49.6208001920406, 14.0537158479739]],
    [[49.6208001920406, 14.0537158479739], [49.6207928797193, 14.0537173842271]],
    [[49.6207928797193, 14.0537173842271], [49.6207847227505, 14.0537127460404]],
    [[49.6207847227505, 14.0537127460404], [49.6207342483399, 14.0536010658342]],
    [[49.6207342483399, 14.0536010658342], [49.620727992509, 14.0535971967196]],
    [[49.620727992509, 14.0535971967196], [49.6206494684336, 14.0536410927364]],
    [[49.6206494684336, 14.0536410927364], [49.6203563610795, 14.0538510472867]],
    [[49.6203563610795, 14.0538510472867], [49.6201636146336, 14.0539816373478]],
    [[49.6201636146336, 14.0539816373478], [49.6198147945654, 14.0541284793061]],
    [[49.6198147945654, 14.0541284793061], [49.619319151913, 14.0543650493233]],
    [[49.619319151913, 14.0543650493233], [49.6192443401014, 14.0543975838866]],
    [[49.6192443401014, 14.0543975838866], [49.6192377151509, 14.0543961674564]],
    [[49.6192377151509, 14.0543961674564], [49.6192321369659, 14.0543883251605]],
    [[49.6192321369659, 14.0543883251605], [49.6191148802086, 14.0539268437259]],
    [[49.6191148802086, 14.0539268437259], [49.6189713478682, 14.0533968376627]],
    [[49.6189713478682, 14.0533968376627], [49.6189669086533, 14.0533899234103]],
    [[49.6189669086533, 14.0533899234103], [49.6189608191089, 14.0533885644641]],
    [[49.6208590759882, 14.0536894108444], [49.6208517815785, 14.0536843251373]],
    [[49.6208517815785, 14.0536843251373], [49.6208485501195, 14.0536879012726]],
    [[49.6208485501195, 14.0536879012726], [49.6208053409089, 14.0537357192395]],
    [[49.6208053409089, 14.0537357192395], [49.6207913659278, 14.0537386552519]],
    [[49.6207913659278, 14.0537386552519], [49.6207760719155, 14.0537299588188]],
    [[49.6207760719155, 14.0537299588188], [49.6207653977037, 14.0537069481137]],
    [[49.6203881474683, 14.0538511768943], [49.6203619239165, 14.0538699608563]],
    [[49.6203619239165, 14.0538699608563], [49.6201681352017, 14.0540012570002]],
    [[49.6201681352017, 14.0540012570002], [49.6198185721196, 14.0541484116033]],
    [[49.6198185721196, 14.0541484116033], [49.6193229835544, 14.0543849556009]],
    [[49.6193229835544, 14.0543849556009], [49.6192452857055, 14.0544187452259]],
    [[49.6192452857055, 14.0544187452259], [49.6192317112121, 14.0544158429611]],
    [[49.6192317112121, 14.0544158429611], [49.619224150724, 14.0544052137683]],
    [[49.6208664960314, 14.0536641961912], [49.6208527179402, 14.0536545900302]],
    [[49.6208527179402, 14.0536545900302], [49.6208459244812, 14.0536567145019]],
    [[49.6208459244812, 14.0536567145019], [49.6207984757505, 14.0537092242196]],
    [[49.6207984757505, 14.0537092242196], [49.6207933843156, 14.0537102938852]],
    [[49.6207933843156, 14.0537102938852], [49.6207876063616, 14.0537070084462]],
    [[49.6207876063616, 14.0537070084462], [49.6207371715552, 14.0535954158804]],
    [[49.6207371715552, 14.0535954158804], [49.6207280736131, 14.0535897889742]],
    [[49.6207280736131, 14.0535897889742], [49.6206477493578, 14.0536346913279]],
    [[49.6206477493578, 14.0536346913279], [49.6203545067998, 14.0538447427644]],
    [[49.6203545067998, 14.0538447427644], [49.6201621077768, 14.0539750974645]],
    [[49.6201621077768, 14.0539750974645], [49.61981353538, 14.0541218352077]],
    [[49.61981353538, 14.0541218352077], [49.6193178746985, 14.0543584138981]],
    [[49.6193178746985, 14.0543584138981], [49.6192440248992, 14.054390530107]],
    [[49.6192440248992, 14.054390530107], [49.6192397164631, 14.0543896089537]],
    [[49.6192397164631, 14.0543896089537], [49.6192359424904, 14.0543843031762]],
    [[49.6192359424904, 14.0543843031762], [49.6191190491429, 14.0539242520178]],
    [[49.6191190491429, 14.0539242520178], [49.6189751609192, 14.0533929318446]],
    [[49.6189751609192, 14.0533929318446], [49.6189681726641, 14.0533828323684]],
    [[49.6208720610602, 14.0536452851963], [49.6208543084374, 14.0536329079779]],
    [[49.6208720610602, 14.0536452851963], [49.6208590759882, 14.0536894108444]],
    [[49.6207653977037, 14.0537069481137], [49.6206779610516, 14.0537703919414]],
    [[49.6206779610516, 14.0537703919414], [49.6204066569894, 14.053967247906]],
    [[49.6204066569894, 14.053967247906], [49.6203836526102, 14.0538921340873]],
    [[49.6203836526102, 14.0538921340873], [49.6207435552729, 14.0536309906313]],
    [[49.6187185751592, 14.0535094360891], [49.6187388398833, 14.0535101074891]],
    [[49.6187388398833, 14.0535101074891], [49.6191312758894, 14.0532471092743]],
    [[49.6191312758894, 14.0532471092743], [49.6193014310267, 14.0531360579247]],
    [[49.6193014310267, 14.0531360579247], [49.6193683435526, 14.0530999520236]],
    [[49.6194010620749, 14.0530822971182], [49.6196487973143, 14.0529486184304]],
    [[49.6196487973143, 14.0529486184304], [49.6197698848217, 14.0528941688609]],
    [[49.6197698848217, 14.0528941688609], [49.6198718759681, 14.0528415988428]],
    [[49.6189681726641, 14.0533828323684], [49.6191374883318, 14.0532693618612]],
    [[49.6191374883318, 14.0532693618612], [49.6193071111479, 14.0531586580085]],
    [[49.6193071111479, 14.0531586580085], [49.6196536099387, 14.0529716867793]],
    [[49.6196536099387, 14.0529716867793], [49.6197745912653, 14.0529172850132]],
    [[49.6197745912653, 14.0529172850132], [49.6198786162443, 14.0528636667297]],
    [[49.6193986054336, 14.0531019549502], [49.6196522349037, 14.052965095822]],
    [[49.6196522349037, 14.052965095822], [49.6197732465676, 14.0529106803978]],
    [[49.6197732465676, 14.0529106803978], [49.6198771886169, 14.0528571048452]],
    [[49.6187185751592, 14.0535094360891], [49.6187177231887, 14.0535703540108]],
    [[49.6193937266475, 14.0531045875411], [49.6193778187518, 14.0530832312502]],
    [[49.6193986054336, 14.0531019549502], [49.6193807739481, 14.0530780162476]],
    [[49.6194010620749, 14.0530822971182], [49.6193881619378, 14.0530649787384]],
    [[49.6193881619378, 14.0530649787384], [49.6193683435526, 14.0530999520236]],
    [[49.6187410646969, 14.0535274848964], [49.6188124720392, 14.0534811011438]],
    [[49.6188124720392, 14.0534811011438], [49.6189575107705, 14.0533832410752]],
    [[49.6187419546217, 14.0535344358598], [49.6188142488102, 14.0534874578912]],
    [[49.6188142488102, 14.0534874578912], [49.6189608191089, 14.0533885644641]],
    [[49.6188568314722, 14.0545847018423], [49.6188653315757, 14.0546300987674]],
    [[49.6188653315757, 14.0546300987674], [49.6186817153521, 14.0547115464054]],
    [[49.6081548638937, 14.0610100728233], [49.608153428242, 14.0610689951363]],
    [[49.608153428242, 14.0610689951363], [49.6080995156619, 14.0610658845418]],
    [[49.6080995156619, 14.0610658845418], [49.6081001013992, 14.0610418447195]],
    [[49.608352391244, 14.0609549051421], [49.6083576566893, 14.0609397730383]],
    [[49.6083576566893, 14.0609397730383], [49.6084180099223, 14.0609084278162]],
    [[49.6084180099223, 14.0609084278162], [49.6085336910934, 14.0608417996355]],
    [[49.6085336910934, 14.0608417996355], [49.6085360070294, 14.0608272106311]],
    [[49.6083405597569, 14.0609478279246], [49.6083408080945, 14.0609345660985]],
    [[49.6083408080945, 14.0609345660985], [49.6083477381438, 14.0609193680262]],
    [[49.6083477381438, 14.0609193680262], [49.6084127346031, 14.0608856112584]],
    [[49.6084127346031, 14.0608856112584], [49.6085203801598, 14.060823611262]],
    [[49.6085203801598, 14.060823611262], [49.6085221251549, 14.060812618834]],
    [[49.6085221251549, 14.060812618834], [49.6083897369906, 14.0602923392944]],
    [[49.6083897369906, 14.0602923392944], [49.6083903957233, 14.0602746601747]],
    [[49.6083903957233, 14.0602746601747], [49.6084992104134, 14.0602110841084]],
    [[49.6084992104134, 14.0602110841084], [49.608501281232, 14.0602019733035]],
    [[49.608501281232, 14.0602019733035], [49.6084580791743, 14.0600229733554]],
    [[49.6084580791743, 14.0600229733554], [49.6083958305744, 14.0599941618548]],
    [[49.6083958305744, 14.0599941618548], [49.608389315597, 14.0599626523595]],
    [[49.6083450537631, 14.0609480272017], [49.608345263242, 14.0609368405294]],
    [[49.608345263242, 14.0609368405294], [49.6083505720143, 14.0609251980288]],
    [[49.6083505720143, 14.0609251980288], [49.6084142418376, 14.0608921302744]],
    [[49.6084142418376, 14.0608921302744], [49.608524183284, 14.0608288079391]],
    [[49.608524183284, 14.0608288079391], [49.6085268301292, 14.0608121343944]],
    [[49.6085268301292, 14.0608121343944], [49.6083942817576, 14.0602912252147]],
    [[49.6083942817576, 14.0602912252147], [49.6083947173214, 14.0602795355245]],
    [[49.6083947173214, 14.0602795355245], [49.6085027631168, 14.0602164087004]],
    [[49.6085027631168, 14.0602164087004], [49.6085060601765, 14.0602019029063]],
    [[49.6085060601765, 14.0602019029063], [49.6084615152823, 14.0600173391628]],
    [[49.6084615152823, 14.0600173391628], [49.6083993959636, 14.0599885875053]],
    [[49.6083993959636, 14.0599885875053], [49.6083935176156, 14.0599601570526]],
    [[49.6083450537631, 14.0609480272017], [49.6083405597569, 14.0609478279246]],
    [[49.6076455071714, 14.0643040431469], [49.607627912597, 14.0643945921223]],
    [[49.607627912597, 14.0643945921223], [49.6076637237091, 14.0644110693821]],
    [[49.6077664305781, 14.0639715145287], [49.6078031202661, 14.0637688031176]],
    [[49.6078031202661, 14.0637688031176], [49.6076956578383, 14.0637252518985]],
    [[49.6062695402292, 14.062546219305], [49.6060247643486, 14.0626067345037]],
    [[49.6060247643486, 14.0626067345037], [49.6058622971188, 14.0626051062582]],
    [[49.6060131002846, 14.0624006334322], [49.6060053330123, 14.0625434677825]],
    [[49.6049989892026, 14.0617672087785], [49.6049904396361, 14.0617362150137]],
    [[49.6049904396361, 14.0617362150137], [49.6049177050123, 14.061638048869]],
    [[49.6049177050123, 14.061638048869], [49.6049176450808, 14.0616116007349]],
    [[49.6049076844427, 14.0630373862178], [49.6049338267326, 14.0630501793988]],
    [[49.6049338267326, 14.0630501793988], [49.6049366795541, 14.0630423379795]],
    [[49.6049366795541, 14.0630423379795], [49.6050699626849, 14.0629975921516]],
    [[49.6050699626849, 14.0629975921516], [49.6052620176869, 14.0629116527137]],
    [[49.6052620176869, 14.0629116527137], [49.6052688755738, 14.0628949294754]],
    [[49.6050241646013, 14.0617522395903], [49.6050128619467, 14.061711265381]],
    [[49.6050128619467, 14.061711265381], [49.604944637672, 14.061619186628]],
    [[49.604944637672, 14.061619186628], [49.6049446201538, 14.0616114560022]],
    [[49.6049188295797, 14.0630428402813], [49.6049218942288, 14.0630287503201]],
    [[49.6049218942288, 14.0630287503201], [49.6049302158763, 14.0630197256698]],
    [[49.6049302158763, 14.0630197256698], [49.6050660840513, 14.0629741119281]],
    [[49.6050660840513, 14.0629741119281], [49.6052518942047, 14.0628909668122]],
    [[49.6052518942047, 14.0628909668122], [49.6052541999912, 14.0628853440565]],
    [[49.6052541999912, 14.0628853440565], [49.6052052205663, 14.0626420737801]],
    [[49.6052052205663, 14.0626420737801], [49.6051479202214, 14.062333126657]],
    [[49.6051479202214, 14.062333126657], [49.6051222797914, 14.0621711160712]],
    [[49.6051222797914, 14.0621711160712], [49.6051027828969, 14.0620685123079]],
    [[49.6051027828969, 14.0620685123079], [49.6050857693384, 14.0620375402356]],
    [[49.6050857693384, 14.0620375402356], [49.6049997822662, 14.0617258193362]],
    [[49.6049997822662, 14.0617258193362], [49.6049289269542, 14.0616301896045]],
    [[49.6049289269542, 14.0616301896045], [49.6049288846946, 14.0616115404296]],
    [[49.6049231144806, 14.0630449371715], [49.6049256896297, 14.063033097725]],
    [[49.6049256896297, 14.063033097725], [49.6049320626419, 14.0630261863291]],
    [[49.6049320626419, 14.0630261863291], [49.6050671922328, 14.062980820563]],
    [[49.6050671922328, 14.062980820563], [49.6052547866286, 14.0628968770689]],
    [[49.6052547866286, 14.0628968770689], [49.6052591073546, 14.0628863407996]],
    [[49.6052591073546, 14.0628863407996], [49.6052095299907, 14.0626401006709]],
    [[49.6052095299907, 14.0626401006709], [49.6051522676201, 14.0623313582966]],
    [[49.6051522676201, 14.0623313582966], [49.6051266237508, 14.0621693259812]],
    [[49.6051266237508, 14.0621693259812], [49.6051068309642, 14.0620651650695]],
    [[49.6051068309642, 14.0620651650695], [49.6050896420703, 14.062033873811]],
    [[49.6050896420703, 14.062033873811], [49.6050035193179, 14.061721661064]],
    [[49.6050035193179, 14.061721661064], [49.6049334157309, 14.0616270458977]],
    [[49.6049334157309, 14.0616270458977], [49.6049333805401, 14.0616115163075]],
    [[49.6049446201538, 14.0616114560022], [49.6049176450808, 14.0616116007349]],
    [[49.6053844454574, 14.0675517184728], [49.6054064753184, 14.0675190658974]],
    [[49.6054064753184, 14.0675190658974], [49.605443903467, 14.0675788552066]],
    [[49.605443903467, 14.0675788552066], [49.6054320285799, 14.0675964561096]],
    [[49.6045327499605, 14.0620322893253], [49.6045228273271, 14.061986775028]],
    [[49.6045228273271, 14.061986775028], [49.6045027360701, 14.0619980505329]],
    [[49.6057208610818, 14.0612532215877], [49.6057562955565, 14.0612414973506]],
    [[49.6057570596735, 14.0612549398567], [49.6057686179504, 14.0612558507167]],
    [[49.6057686179504, 14.0612558507167], [49.6057624066762, 14.0612119688582]],
    [[49.6057624066762, 14.0612119688582], [49.6057095513503, 14.0612301305351]],
    [[49.6057095513503, 14.0612301305351], [49.6057232853614, 14.0613366090664]],
    [[49.6057686179504, 14.0612558507167], [49.6058439673585, 14.0612617887029]],
    [[49.605652716713, 14.0613785320441], [49.6056233656329, 14.0612691562728]],
    [[49.6056233656329, 14.0612691562728], [49.605603221667, 14.0608109125431]],
    [[49.605603221667, 14.0608109125431], [49.6055681747746, 14.060351791075]],
    [[49.6055681747746, 14.060351791075], [49.6055279547547, 14.0596609847617]],
    [[49.6055279547547, 14.0596609847617], [49.6054995984889, 14.0589703085239]],
    [[49.6054995984889, 14.0589703085239], [49.6054630606232, 14.0582827078969]],
    [[49.6054630606232, 14.0582827078969], [49.6054271753568, 14.0575922720851]],
    [[49.6054271753568, 14.0575922720851], [49.6053864722168, 14.0569031752522]],
    [[49.6053864722168, 14.0569031752522], [49.6053478425219, 14.056183876307]],
    [[49.6053478425219, 14.056183876307], [49.6053858416689, 14.0561240649417]],
    [[49.6053858416689, 14.0561240649417], [49.6053814920245, 14.0561100216908]],
    [[49.6053814920245, 14.0561100216908], [49.6054350661243, 14.0560707324984]],
    [[49.6054350661243, 14.0560707324984], [49.6054656750346, 14.0561279833269]],
    [[49.6054656750346, 14.0561279833269], [49.6054692711106, 14.0561911660393]],
    [[49.6054692711106, 14.0561911660393], [49.6054076934689, 14.0562231184671]],
    [[49.6063401344267, 14.0608977849817], [49.6062501599702, 14.0608615993922]],
    [[49.6062501599702, 14.0608615993922], [49.6060921797867, 14.0609556268029]],
    [[49.6060921797867, 14.0609556268029], [49.6060062332038, 14.0611078612202]],
    [[49.6060062332038, 14.0611078612202], [49.6059931601281, 14.0611163653082]],
    [[49.6063402144793, 14.0608912172389], [49.6062497282775, 14.0608544387515]],
    [[49.6062497282775, 14.0608544387515], [49.6060895174189, 14.0609497938478]],
    [[49.6060895174189, 14.0609497938478], [49.6060035226774, 14.0611021135821]],
    [[49.6060035226774, 14.0611021135821], [49.6059929022755, 14.0611090221975]],
    [[49.6063401344267, 14.0608977849817], [49.6063404314406, 14.060873416956]],
    [[49.6063404314406, 14.060873416956], [49.6062486490437, 14.0608365371505]],
    [[49.6062486490437, 14.0608365371505], [49.6060828614982, 14.0609352114627]],
    [[49.6060828614982, 14.0609352114627], [49.6059967463603, 14.0610877444896]],
    [[49.6059967463603, 14.0610877444896], [49.6059922613879, 14.0610907711003]],
    [[49.6059922613879, 14.0610907711003], [49.6059936541748, 14.0611304347785]],
    [[49.6037351804691, 14.0623220480697], [49.603741482387, 14.0623449832896]],
    [[49.60345809886, 14.0625948813224], [49.6034688087535, 14.0625529823421]],
    [[49.6034688087535, 14.0625529823421], [49.6035146560851, 14.0625667866409]],
    [[49.6035146560851, 14.0625667866409], [49.6035177618845, 14.0625772481588]],
    [[49.6033196328174, 14.0625138962939], [49.6033124959564, 14.0625196635523]],
    [[49.6033124959564, 14.0625196635523], [49.6033055517473, 14.0625213352439]],
    [[49.6033055517473, 14.0625213352439], [49.6032710102241, 14.0625120780506]],
    [[49.6032710102241, 14.0625120780506], [49.6031746574953, 14.0624845150052]],
    [[49.6031746574953, 14.0624845150052], [49.6031519174144, 14.0624802888022]],
    [[49.6031643974126, 14.0624756402467], [49.603173882257, 14.0624721073259]],
    [[49.603173882257, 14.0624721073259], [49.6031795532685, 14.0624641776133]],
    [[49.6033175423932, 14.0625077718676], [49.6033110650383, 14.0625130061837]],
    [[49.6033110650383, 14.0625130061837], [49.603305591053, 14.0625143239464]],
    [[49.603305591053, 14.0625143239464], [49.6032718068692, 14.0625052697265]],
    [[49.6032718068692, 14.0625052697265], [49.6031763744458, 14.0624779699602]],
    [[49.6031763744458, 14.0624779699602], [49.6031825769265, 14.0624692971009]],
    [[49.6033291240393, 14.0625257991907], [49.6033167826299, 14.0624890138168]],
    [[49.6033167826299, 14.0624890138168], [49.6033056893153, 14.0624967957028]],
    [[49.6033056893153, 14.0624967957028], [49.6032737984803, 14.0624882489151]],
    [[49.6032737984803, 14.0624882489151], [49.6031945314919, 14.0624655735155]],
    [[49.6031519174144, 14.0624802888022], [49.603076938877, 14.0624663542042]],
    [[49.603076938877, 14.0624663542042], [49.6029720586223, 14.062467732873]],
    [[49.6029720586223, 14.062467732873], [49.6028686838969, 14.0625072461503]],
    [[49.6031643974126, 14.0624756402467], [49.6030771902706, 14.0624594329975]],
    [[49.6030771902706, 14.0624594329975], [49.6029714896428, 14.0624608224653]],
    [[49.6029714896428, 14.0624608224653], [49.6028674335261, 14.0625005962078]],
    [[49.6031667841369, 14.0624586639638], [49.6030778187528, 14.0624421299806]],
    [[49.6030778187528, 14.0624421299806], [49.6029700671923, 14.0624435464468]],
    [[49.6029700671923, 14.0624435464468], [49.602929279121, 14.0624591370535]],
    [[49.6031825769265, 14.0624692971009], [49.6031795532685, 14.0624641776133]],
    [[49.6033175423932, 14.0625077718676], [49.6033196328174, 14.0625138962939]],
    [[49.6031667841369, 14.0624586639638], [49.6031719941226, 14.0624513788972]],
    [[49.6031719941226, 14.0624513788972], [49.6031945314919, 14.0624655735155]],
    [[49.6033291240393, 14.0625257991907], [49.6033162314334, 14.0625371623252]],
    [[49.602867433526, 14.0625005962078], [49.6027672878153, 14.0625516174534]],
    [[49.6027672878153, 14.0625516174534], [49.6026372344496, 14.0625832641999]],
    [[49.6026372344496, 14.0625832641999], [49.6024803240759, 14.0626360032772]],
    [[49.6024803240759, 14.0626360032772], [49.6024547928313, 14.062689190178]],
    [[49.6024547928313, 14.062689190178], [49.6024412687408, 14.0627020404565]],
    [[49.6024412687408, 14.0627020404565], [49.6024176029778, 14.0627119044647]],
    [[49.6024176029778, 14.0627119044647], [49.602166160289, 14.0627048183433]],
    [[49.602166160289, 14.0627048183433], [49.6020629930277, 14.0627169789881]],
    [[49.6020629930277, 14.0627169789881], [49.6019615900833, 14.0627475693271]],
    [[49.6019615900833, 14.0627475693271], [49.6018600391024, 14.0628009101727]],
    [[49.6018600391024, 14.0628009101727], [49.6018396666274, 14.0628368238995]],
    [[49.6018396666274, 14.0628368238995], [49.6018415296065, 14.0628501769695]],
    [[49.6018415296065, 14.0628501769695], [49.6017654716527, 14.0629043934688]],
    [[49.6017654716527, 14.0629043934688], [49.6016748770679, 14.0629869181094]],
    [[49.6016748770679, 14.0629869181094], [49.6016193181039, 14.0630426633766]],
    [[49.6016193181039, 14.0630426633766], [49.6014709768223, 14.0632027197446]],
    [[49.6014709768223, 14.0632027197446], [49.6011850599442, 14.0635124250412]],
    [[49.6011850599442, 14.0635124250412], [49.6008843324815, 14.0638441321516]],
    [[49.6008843324815, 14.0638441321516], [49.6006004534774, 14.0641547252478]],
    [[49.6006004534774, 14.0641547252478], [49.6005958941521, 14.0641575162195]],
    [[49.6020074096583, 14.0627161238948], [49.6019586798707, 14.0627308242642]],
    [[49.6019586798707, 14.0627308242642], [49.6018535540549, 14.0627860428666]],
    [[49.6018535540549, 14.0627860428666], [49.6018274952043, 14.0628319808545]],
    [[49.6018274952043, 14.0628319808545], [49.6018286551446, 14.0628402948251]],
    [[49.6018286551446, 14.0628402948251], [49.6017602329562, 14.0628890683233]],
    [[49.6017602329562, 14.0628890683233], [49.6016653117178, 14.0629750232137]],
    [[49.6028686838969, 14.0625072461503], [49.6027683526028, 14.0625583619309]],
    [[49.6027683526028, 14.0625583619309], [49.6026380660356, 14.062590065406]],
    [[49.6026380660356, 14.062590065406], [49.6024829357415, 14.0626422061617]],
    [[49.6024829357415, 14.0626422061617], [49.6024578935708, 14.0626943742142]],
    [[49.6024578935708, 14.0626943742142], [49.6024430749999, 14.0627084544765]],
    [[49.6024430749999, 14.0627084544765], [49.602418160653, 14.0627188388974]],
    [[49.602418160653, 14.0627188388974], [49.6021662911248, 14.0627117407108]],
    [[49.6021662911248, 14.0627117407108], [49.6020635993201, 14.0627238452976]],
    [[49.6020635993201, 14.0627238452976], [49.6019627541676, 14.0627542673528]],
    [[49.6019627541676, 14.0627542673528], [49.6018626331208, 14.0628068570963]],
    [[49.6018626331208, 14.0628068570963], [49.6018445351966, 14.0628387611182]],
    [[49.6018445351966, 14.0628387611182], [49.6018466823117, 14.0628541507636]],
    [[49.6018466823117, 14.0628541507636], [49.6017675912843, 14.0629099727047]],
    [[49.6017675912843, 14.0629099727047], [49.6016772565766, 14.0629927889381]],
    [[49.6016772565766, 14.0629927889381], [49.6016218373801, 14.0630483939626]],
    [[49.6016218373801, 14.0630483939626], [49.6014735615436, 14.0632083796997]],
    [[49.6014735615436, 14.0632083796997], [49.6011876637856, 14.0635180642524]],
    [[49.6011876637856, 14.0635180642524], [49.6008869449603, 14.0638497618006]],
    [[49.6008869449603, 14.0638497618006], [49.6006026181007, 14.0641608448611]],
    [[49.6006026181007, 14.0641608448611], [49.6005846577322, 14.0641718392193]],
    [[49.6005958941521, 14.0641575162195], [49.6005846577322, 14.0641718392193]],
    [[49.6053475009815, 14.0677217421619], [49.6053397519486, 14.0677332276772]],
    [[49.6053397519486, 14.0677332276772], [49.6053093496015, 14.0676846615732]],
    [[49.6053698955941, 14.0677230521397], [49.6052806608575, 14.067635544214]],
    [[49.6052806608575, 14.067635544214], [49.605262199475, 14.0676216555638]],
    [[49.605262199475, 14.0676216555638], [49.6052059056806, 14.0676001372539]],
    [[49.6052059056806, 14.0676001372539], [49.6051682076335, 14.0675968071858]],
    [[49.6051682076335, 14.0675968071858], [49.6051405957062, 14.067599620986]],
    [[49.6051405957062, 14.067599620986], [49.6050936290453, 14.0676092961751]],
    [[49.6050936290453, 14.0676092961751], [49.6050272573864, 14.0676178729443]],
    [[49.6050272573864, 14.0676178729443], [49.6050180231959, 14.0676222857381]],
    [[49.6050180231959, 14.0676222857381], [49.604962989762, 14.0676780321208]],
    [[49.604962989762, 14.0676780321208], [49.6049545814014, 14.0676946393173]],
    [[49.6049545814014, 14.0676946393173], [49.6049435455355, 14.0677420915985]],
    [[49.6049435455355, 14.0677420915985], [49.6049434942617, 14.0677974312256]],
    [[49.6049434942617, 14.0677974312256], [49.6049571177326, 14.0678767586995]],
    [[49.6049571177326, 14.0678767586995], [49.6049554519278, 14.0678913652765]],
    [[49.6049554519278, 14.0678913652765], [49.6048103832176, 14.0680073572873]],
    [[49.6048103832176, 14.0680073572873], [49.6048000147245, 14.0680129351649]],
    [[49.6047948333308, 14.068015722568], [49.6047918108562, 14.0680173485501]],
    [[49.6047918108562, 14.0680173485501], [49.6047541726111, 14.067916562431]],
    [[49.6047541726111, 14.067916562431], [49.6046128089306, 14.0675939263179]],
    [[49.6046128089306, 14.0675939263179], [49.6045492085298, 14.0674744796849]],
    [[49.6045492085298, 14.0674744796849], [49.6044369391223, 14.0672850244999]],
    [[49.6044369391223, 14.0672850244999], [49.6043121738677, 14.0670759976963]],
    [[49.6043121738677, 14.0670759976963], [49.6042617634099, 14.0669874455525]],
    [[49.6042617634099, 14.0669874455525], [49.6042557757869, 14.0669839441816]],
    [[49.6042557757869, 14.0669839441816], [49.6042500420807, 14.066987021003]],
    [[49.6047858070645, 14.0680359842129], [49.604744627967, 14.0679257165171]],
    [[49.604744627967, 14.0679257165171], [49.6046037824711, 14.0676042630344]],
    [[49.6046037824711, 14.0676042630344], [49.6045407027571, 14.0674857942791]],
    [[49.6045407027571, 14.0674857942791], [49.6044286473611, 14.067296700214]],
    [[49.6044286473611, 14.067296700214], [49.6043038062836, 14.0670875463503]],
    [[49.6043038062836, 14.0670875463503], [49.6042364305978, 14.0669690850529]],
    [[49.6053723042122, 14.0677172106996], [49.605282863329, 14.0676295006277]],
    [[49.605282863329, 14.0676295006277], [49.6052637459774, 14.0676151184891]],
    [[49.6052637459774, 14.0676151184891], [49.6052065823183, 14.0675932676812]],
    [[49.6052065823183, 14.0675932676812], [49.6051681878467, 14.0675898760996]],
    [[49.6051681878467, 14.0675898760996], [49.60514014837, 14.0675927334734]],
    [[49.60514014837, 14.0675927334734], [49.6050931423421, 14.0676024167788]],
    [[49.6050931423421, 14.0676024167788], [49.6050263923724, 14.067611042444]],
    [[49.6050263923724, 14.067611042444], [49.6050160823527, 14.0676159693516]],
    [[49.6050160823527, 14.0676159693516], [49.6049598986378, 14.0676728809235]],
    [[49.6049598986378, 14.0676728809235], [49.6049505914603, 14.0676912633572]],
    [[49.6049505914603, 14.0676912633572], [49.6049390507736, 14.0677408862794]],
    [[49.6049390507736, 14.0677408862794], [49.604938997555, 14.0677983250057]],
    [[49.604938997555, 14.0677983250057], [49.6049525189887, 14.0678770583267]],
    [[49.6049525189887, 14.0678770583267], [49.6049514076838, 14.0678868027821]],
    [[49.6049514076838, 14.0678868027821], [49.604808595716, 14.0680009903919]],
    [[49.604808595716, 14.0680009903919], [49.6047977357672, 14.0680068326563]],
    [[49.604261719631, 14.0669800194054], [49.60425584187, 14.0669765822793]],
    [[49.60425584187, 14.0669765822793], [49.6042460910856, 14.0669818147464]],
    [[49.6048731648661, 14.0682257860668], [49.6048535133303, 14.0682496962767]],
    [[49.6048535133303, 14.0682496962767], [49.60488697607, 14.0683148135462]],
    [[49.60488697607, 14.0683148135462], [49.6048958652994, 14.0683039979535]],
    [[49.6049614791013, 14.0685300646467], [49.6048958652994, 14.0683039979535]],
    [[49.6048731648661, 14.0682257860668], [49.6048454301777, 14.068130229546]],
    [[49.6048454301777, 14.068130229546], [49.6047887384578, 14.0680349487569]],
    [[49.6047887384578, 14.0680349487569], [49.6047858070645, 14.0680359842129]],
    [[49.6049614791013, 14.0685300646467], [49.6049689196379, 14.0685250841774]],
    [[49.6032209365683, 14.06543482205], [49.603152079662, 14.0653915822524]],
    [[49.603152079662, 14.0653915822524], [49.6031157783136, 14.0653787344706]],
    [[49.6031157783136, 14.0653787344706], [49.6030769101539, 14.0653775950731]],
    [[49.6030769101539, 14.0653775950731], [49.6030411188303, 14.0653878543424]],
    [[49.6030411188303, 14.0653878543424], [49.603006933875, 14.0654077487039]],
    [[49.603006933875, 14.0654077487039], [49.602974588956, 14.0654372639421]],
    [[49.602974588956, 14.0654372639421], [49.6029459255427, 14.0654778374691]],
    [[49.6029459255427, 14.0654778374691], [49.6028758309103, 14.0655359166539]],
    [[49.6028758309103, 14.0655359166539], [49.6027740156998, 14.0655976212738]],
    [[49.6027740156998, 14.0655976212738], [49.6026531902226, 14.065633182557]],
    [[49.6026531902226, 14.065633182557], [49.6025462096089, 14.0656253065893]],
    [[49.6025462096089, 14.0656253065893], [49.6023525154086, 14.0656443427678]],
    [[49.6023525154086, 14.0656443427678], [49.6023091362473, 14.0656850115289]],
    [[49.6023091362473, 14.0656850115289], [49.60226687539, 14.0656807136121]],
    [[49.60226687539, 14.0656807136121], [49.6021815264068, 14.0656415834716]],
    [[49.6021815264068, 14.0656415834716], [49.6019727959224, 14.0656069679698]],
    [[49.6019727959224, 14.0656069679698], [49.6019048240895, 14.0656054737343]],
    [[49.6019048240895, 14.0656054737343], [49.6018114268755, 14.065570550439]],
    [[49.6018114268755, 14.065570550439], [49.6015225664608, 14.0655565635592]],
    [[49.6015225664608, 14.0655565635592], [49.6013777852732, 14.0655655229783]],
    [[49.6013777852732, 14.0655655229783], [49.6012571695377, 14.0655852392592]],
    [[49.6012571695377, 14.0655852392592], [49.6011005540259, 14.065628365781]],
    [[49.6011005540259, 14.065628365781], [49.6008832888011, 14.0657199667243]],
    [[49.6008832888011, 14.0657199667243], [49.600724249826, 14.0658061808059]],
    [[49.600724249826, 14.0658061808059], [49.600435873613, 14.0659754778897]],
    [[49.600435873613, 14.0659754778897], [49.6001301093084, 14.0661563742097]],
    [[49.6001301093084, 14.0661563742097], [49.5998697682343, 14.066308413324]],
    [[49.5998697682343, 14.066308413324], [49.5996408049685, 14.0664419142984]],
    [[49.5996408049685, 14.0664419142984], [49.5994369348827, 14.0665727195885]],
    [[49.5994369348827, 14.0665727195885], [49.599293172262, 14.0666843535904]],
    [[49.599293172262, 14.0666843535904], [49.5992103953356, 14.0667656963076]],
    [[49.5992103953356, 14.0667656963076], [49.5991980956665, 14.0667634753885]],
    [[49.5991980956665, 14.0667634753885], [49.5991061760207, 14.0665617444528]],
    [[49.5991061760207, 14.0665617444528], [49.5990970130206, 14.0665548361292]],
    [[49.5983902618776, 14.0670018543232], [49.598021331136, 14.0672393872169]],
    [[49.598021331136, 14.0672393872169], [49.5975429445689, 14.0675344477733]],
    [[49.5975429445689, 14.0675344477733], [49.5974142996376, 14.0676321409389]],
    [[49.5974142996376, 14.0676321409389], [49.5973868524513, 14.0676991582373]],
    [[49.5973868524513, 14.0676991582373], [49.5973296314331, 14.0679220596156]],
    [[49.5973296314331, 14.0679220596156], [49.5973276786209, 14.0679280499143]],
    [[49.5973276786209, 14.0679280499143], [49.5973234155196, 14.0679302803663]],
    [[49.5973234155196, 14.0679302803663], [49.5972307054565, 14.0679060431218]],
    [[49.5972307054565, 14.0679060431218], [49.5971826347925, 14.067907402974]],
    [[49.5971826347925, 14.067907402974], [49.5971262652047, 14.0679232482461]],
    [[49.5971262652047, 14.0679232482461], [49.5970729439869, 14.0679429013301]],
    [[49.5970729439869, 14.0679429013301], [49.5970467325875, 14.0680042504518]],
    [[49.5970467325875, 14.0680042504518], [49.5969720932618, 14.0680414905619]],
    [[49.5969720932618, 14.0680414905619], [49.5969232104797, 14.0680261169982]],
    [[49.5969232104797, 14.0680261169982], [49.5966093938366, 14.0681850181694]],
    [[49.5966093938366, 14.0681850181694], [49.596393637721, 14.0682915210837]],
    [[49.596393637721, 14.0682915210837], [49.5960792749454, 14.0684585336427]],
    [[49.5960792749454, 14.0684585336427], [49.5960676189855, 14.0684733030521]],
    [[49.5960676189855, 14.0684733030521], [49.5960591290098, 14.0684990289342]],
    [[49.5960591290098, 14.0684990289342], [49.5960527369455, 14.068502792699]],
    [[49.5959647290395, 14.0685546132618], [49.5959608192548, 14.0685569154052]],
    [[49.5959608192548, 14.0685569154052], [49.5959085440315, 14.0685333318613]],
    [[49.5959085440315, 14.0685333318613], [49.5958859575885, 14.0685448031434]],
    [[49.6032182301798, 14.06544208846], [49.6031504450531, 14.0653995216931]],
    [[49.6031504450531, 14.0653995216931], [49.6031151151622, 14.0653870177239]],
    [[49.6031151151622, 14.0653870177239], [49.6030773574005, 14.0653859108707]],
    [[49.6030773574005, 14.0653859108707], [49.6030425786107, 14.0653958799002]],
    [[49.6030425786107, 14.0653958799002], [49.6030092818391, 14.0654152573667]],
    [[49.6030092818391, 14.0654152573667], [49.6029778263333, 14.0654439609972]],
    [[49.6029778263333, 14.0654439609972], [49.6029490797996, 14.0654846521775]],
    [[49.6029490797996, 14.0654846521775], [49.6028781064846, 14.0655434594132]],
    [[49.6028781064846, 14.0655434594132], [49.6027755241194, 14.065605628947]],
    [[49.6027755241194, 14.065605628947], [49.6026535734508, 14.0656415213754]],
    [[49.6026535734508, 14.0656415213754], [49.6025462527726, 14.0656336203535]],
    [[49.6025462527726, 14.0656336203535], [49.6023541867427, 14.0656524964839]],
    [[49.6023541867427, 14.0656524964839], [49.6023104848099, 14.0656934678402]],
    [[49.6023104848099, 14.0656934678402], [49.6022659152098, 14.0656889351173]],
    [[49.6022659152098, 14.0656889351173], [49.6021804581726, 14.0656497554222]],
    [[49.6021804581726, 14.0656497554222], [49.6019724676028, 14.0656152625914]],
    [[49.6019724676028, 14.0656152625914], [49.6019041399777, 14.0656137605229]],
    [[49.6019041399777, 14.0656137605229], [49.6018106972221, 14.0655788201822]],
    [[49.6018106972221, 14.0655788201822], [49.6015225900469, 14.0655648697259]],
    [[49.6015225900469, 14.0655648697259], [49.601378179087, 14.0655738062098]],
    [[49.601378179087, 14.0655738062098], [49.6012579307148, 14.0655934624198]],
    [[49.6012579307148, 14.0655934624198], [49.6011017445804, 14.0656364706797]],
    [[49.6011017445804, 14.0656364706797], [49.600884900155, 14.0657278941745]],
    [[49.600884900155, 14.0657278941745], [49.6007261081981, 14.0658139743232]],
    [[49.6007261081981, 14.0658139743232], [49.6004378034242, 14.0659832294212]],
    [[49.6004378034242, 14.0659832294212], [49.6001320347399, 14.0661641282836]],
    [[49.6001320347399, 14.0661641282836], [49.599871682766, 14.066316173722]],
    [[49.599871682766, 14.066316173722], [49.5996427997062, 14.066449627896]],
    [[49.5996427997062, 14.066449627896], [49.5994391915242, 14.066580265114]],
    [[49.5994391915242, 14.066580265114], [49.5992958465225, 14.0666915748062]],
    [[49.5992958465225, 14.0666915748062], [49.5992116776443, 14.0667742853422]],
    [[49.5992116776443, 14.0667742853422], [49.5991950657696, 14.0667712857766]],
    [[49.5991950657696, 14.0667712857766], [49.5991025553261, 14.0665682582227]],
    [[49.5991025553261, 14.0665682582227], [49.5990969274042, 14.0665640151239]],
    [[49.5983920095769, 14.0670082273193], [49.5980230355138, 14.0672457880568]],
    [[49.5980230355138, 14.0672457880568], [49.5975447860401, 14.0675407639947]],
    [[49.5975447860401, 14.0675407639947], [49.5974179549025, 14.0676387858379]],
    [[49.5974179549025, 14.0676387858379], [49.5973916853807, 14.0677029276501]],
    [[49.5973916853807, 14.0677029276501], [49.5973345613235, 14.0679254513135]],
    [[49.5973345613235, 14.0679254513135], [49.5973315095863, 14.06793481259]],
    [[49.5973315095863, 14.06793481259], [49.5973238588501, 14.0679388154495]],
    [[49.5973238588501, 14.0679388154495], [49.5972302996162, 14.0679143561891]],
    [[49.5972302996162, 14.0679143561891], [49.5971831727608, 14.0679156893342]],
    [[49.5971831727608, 14.0679156893342], [49.5971273793421, 14.067931372638]],
    [[49.5971273793421, 14.067931372638], [49.5970762787282, 14.0679502072462]],
    [[49.5970762787282, 14.0679502072462], [49.5970501998239, 14.0680112462477]],
    [[49.5970501998239, 14.0680112462477], [49.5969724054149, 14.0680500605245]],
    [[49.5969724054149, 14.0680500605245], [49.5969235349587, 14.0680346908282]],
    [[49.5969235349587, 14.0680346908282], [49.5966110613237, 14.0681929119114]],
    [[49.5966110613237, 14.0681929119114], [49.5963953425819, 14.0682993963423]],
    [[49.5963953425819, 14.0682993963423], [49.5960819546555, 14.0684658909425]],
    [[49.5960819546555, 14.0684658909425], [49.5960719191504, 14.0684786070526]],
    [[49.5960719191504, 14.0684786070526], [49.5960629986211, 14.0685056375727]],
    [[49.5960629986211, 14.0685056375727], [49.5960547788418, 14.0685104775286]],
    [[49.5959667807467, 14.0685622923008], [49.5959610477598, 14.0685656679738]],
    [[49.5959610477598, 14.0685656679738], [49.5959086390039, 14.0685420241774]],
    [[49.5959086390039, 14.0685420241774], [49.5958876991769, 14.0685526591666]],
    [[49.6032068732735, 14.0654528885596], [49.6031471758335, 14.065415400573]],
    [[49.6031471758335, 14.065415400573], [49.6031137888576, 14.0654035842298]],
    [[49.6031137888576, 14.0654035842298], [49.6030782518921, 14.0654025424662]],
    [[49.6030782518921, 14.0654025424662], [49.6030454981696, 14.0654119310173]],
    [[49.6030454981696, 14.0654119310173], [49.6030139777659, 14.0654302746945]],
    [[49.6030139777659, 14.0654302746945], [49.6029843010865, 14.0654573551101]],
    [[49.6029843010865, 14.0654573551101], [49.6029553883123, 14.0654982815972]],
    [[49.6029553883123, 14.0654982815972], [49.6028826576317, 14.065558544934]],
    [[49.6028826576317, 14.065558544934], [49.602778540957, 14.065621644295]],
    [[49.602778540957, 14.065621644295], [49.6026543399053, 14.0656581990126]],
    [[49.6026543399053, 14.0656581990126], [49.6025463390981, 14.0656502478821]],
    [[49.6025463390981, 14.0656502478821], [49.6023969495178, 14.0656649297594]],
    [[49.6023481980943, 14.0656775521986], [49.6023131819332, 14.0657103804643]],
    [[49.6023131819332, 14.0657103804643], [49.6022639948475, 14.0657053781267]],
    [[49.6022639948475, 14.0657053781267], [49.6021783217024, 14.0656660993223]],
    [[49.6021783217024, 14.0656660993223], [49.6019718109617, 14.0656318518343]],
    [[49.6019718109617, 14.0656318518343], [49.6019027717521, 14.0656303340992]],
    [[49.6019027717521, 14.0656303340992], [49.6018092379134, 14.0655953596678]],
    [[49.6018092379134, 14.0655953596678], [49.6015226372175, 14.0655814820593]],
    [[49.6015226372175, 14.0655814820593], [49.601366102064, 14.065592475573]],
    [[49.6011397210237, 14.0656379470635], [49.600888122861, 14.0657437490765]],
    [[49.600888122861, 14.0657437490765], [49.600729824941, 14.0658295613595]],
    [[49.600729824941, 14.0658295613595], [49.600441663045, 14.0659987324861]],
    [[49.600441663045, 14.0659987324861], [49.6001358856014, 14.0661796364332]],
    [[49.6001358856014, 14.0661796364332], [49.5998755118279, 14.0663316945197]],
    [[49.5998755118279, 14.0663316945197], [49.59964678918, 14.0664650550929]],
    [[49.59964678918, 14.0664650550929], [49.5994437048056, 14.066595356167]],
    [[49.5994437048056, 14.066595356167], [49.5993011950423, 14.0667060172404]],
    [[49.5993011950423, 14.0667060172404], [49.59921424226, 14.0667914634128]],
    [[49.59921424226, 14.0667914634128], [49.5991800020706, 14.0667838551356]],
    [[49.5973412138042, 14.0679420731791], [49.5973391715159, 14.0679483379448]],
    [[49.5973391715159, 14.0679483379448], [49.5973247455093, 14.0679558856163]],
    [[49.5973247455093, 14.0679558856163], [49.5972294879338, 14.0679309823233]],
    [[49.5972294879338, 14.0679309823233], [49.5971842486954, 14.0679322620552]],
    [[49.5971842486954, 14.0679322620552], [49.5971296076152, 14.0679476214228]],
    [[49.5971296076152, 14.0679476214228], [49.5970829482091, 14.0679648190814]],
    [[49.5970829482091, 14.0679648190814], [49.5970571342954, 14.0680252378426]],
    [[49.5970571342954, 14.0680252378426], [49.5969730297193, 14.06806720045]],
    [[49.5969730297193, 14.06806720045], [49.596924320175, 14.0680518813422]],
    [[49.596924320175, 14.0680518813422], [49.5966143962961, 14.0682086993971]],
    [[49.5966143962961, 14.0682086993971], [49.596398752302, 14.068315146861]],
    [[49.596398752302, 14.068315146861], [49.5960873140743, 14.0684806055446]],
    [[49.5960873140743, 14.0684806055446], [49.5960721725617, 14.0685078753208]],
    [[49.5960721725617, 14.0685078753208], [49.5960603493881, 14.0685249717617]],
    [[49.5959901085908, 14.0685663307326], [49.5959615047677, 14.0685831731111]],
    [[49.5959615047677, 14.0685831731111], [49.5959088289466, 14.0685594088097]],
    [[49.5959088289466, 14.0685594088097], [49.5958911823519, 14.0685683712148]],
    [[49.6021453612383, 14.0656188876832], [49.6019734525598, 14.0655903787263]],
    [[49.6019734525598, 14.0655903787263], [49.6019061923115, 14.0655889001565]],
    [[49.6019061923115, 14.0655889001565], [49.6018312627026, 14.065560403455]],
    [[49.5986658831853, 14.0676843113124], [49.5986460849577, 14.0668513485228]],
    [[49.5958911823519, 14.0685683712148], [49.5958819916325, 14.0685269133683]],
    [[49.6032209365683, 14.06543482205], [49.6032568380182, 14.0654771038759]],
    [[49.6032568380182, 14.0654771038759], [49.6033787730808, 14.0655794979806]],
    [[49.6033787730808, 14.0655794979806], [49.603507236771, 14.0657132310172]],
    [[49.603507236771, 14.0657132310172], [49.6036188761236, 14.0658635655037]],
    [[49.6036188761236, 14.0658635655037], [49.6037596592089, 14.0660702779477]],
    [[49.6037596592089, 14.0660702779477], [49.6038485488343, 14.0662204673225]],
    [[49.6038485488343, 14.0662204673225], [49.6040206306691, 14.0665182000195]],
    [[49.6040206306691, 14.0665182000195], [49.6042402773092, 14.0669297547515]],
    [[49.6032182301798, 14.06544208846], [49.6032538887971, 14.0654840843032]],
    [[49.6032538887971, 14.0654840843032], [49.6033759628492, 14.0655865951402]],
    [[49.6033759628492, 14.0655865951402], [49.6035039359553, 14.0657198174877]],
    [[49.6035039359553, 14.0657198174877], [49.603615235626, 14.0658696945707]],
    [[49.603615235626, 14.0658696945707], [49.6037557977471, 14.066076082589]],
    [[49.6037557977471, 14.066076082589], [49.6038445385443, 14.0662260205119]],
    [[49.6038445385443, 14.0662260205119], [49.6040165286941, 14.0665235945965]],
    [[49.6040165286941, 14.0665235945965], [49.6042362190274, 14.0669352245978]],
    [[49.5987437263397, 14.0667827211096], [49.5987600680411, 14.0675636225568]],
    [[49.5987600680411, 14.0675636225568], [49.5987740531533, 14.0682062813122]],
    [[49.5987740531533, 14.0682062813122], [49.5987773344182, 14.0683573928229]],
    [[49.5986819668325, 14.0683622944742], [49.5986658831853, 14.0676843113124]],
    [[49.5986658831853, 14.0676843113124], [49.5986580634736, 14.0676855395155]],
    [[49.5987773344182, 14.0683573928229], [49.5986819668325, 14.0683622944742]],
    [[49.5915364180406, 14.0710656172168], [49.591453117748, 14.0705973736577]],
    [[49.591453117748, 14.0705973736577], [49.5914360708817, 14.0703278248836]],
    [[49.5914360708817, 14.0703278248836], [49.5914528270411, 14.0702144785683]],
    [[49.5914528270411, 14.0702144785683], [49.5912467597728, 14.0703412060904]],
    [[49.5901203211487, 14.0747696949335], [49.5900759003757, 14.0746400417338]],
    [[49.5900750017749, 14.0748128844585], [49.5900626285122, 14.0747767763129]],
    [[49.5900626285122, 14.0747767763129], [49.5900093488841, 14.0746230242927]],
    [[49.5872515057235, 14.0789004054423], [49.5871846410683, 14.0787867299256]],
    [[49.5871846410683, 14.0787867299256], [49.5871526162308, 14.0787331078771]],
    [[49.5862998758884, 14.0770376188482], [49.5862187029389, 14.0771226933732]],
    [[49.5862998758884, 14.0770376188482], [49.5863074760317, 14.0770537734648]],
    [[49.5863074760317, 14.0770537734648], [49.586501918416, 14.0774797678235]],
    [[49.586501918416, 14.0774797678235], [49.5867438097318, 14.0779926121247]],
    [[49.5867438097318, 14.0779926121247], [49.5867750416834, 14.0780592591867]],
    [[49.5867750416834, 14.0780592591867], [49.586808408291, 14.0781303286631]],
    [[49.586808408291, 14.0781303286631], [49.5868759491127, 14.0782546889713]],
    [[49.5862187029389, 14.0771226933732], [49.5862612702693, 14.0772150396725]],
    [[49.5862612702693, 14.0772150396725], [49.586426039592, 14.0775863400541]],
    [[49.586426039592, 14.0775863400541], [49.5866194667579, 14.0780013833965]],
    [[49.5866194667579, 14.0780013833965], [49.5867397996353, 14.0782448089926]],
    [[49.5867397996353, 14.0782448089926], [49.5867833999217, 14.0783234179814]],
    [[49.5847012840422, 14.0805906250377], [49.5849918485685, 14.0802784221589]],
    [[49.5849918485685, 14.0802784221589], [49.5859450643134, 14.0794848219327]],
    [[49.5859450643134, 14.0794848219327], [49.5865888098812, 14.0785502329585]],
    [[49.5847012840422, 14.0805906250377], [49.5847083038646, 14.0806065202607]],
    [[49.5876301226151, 14.0796738458777], [49.5876248364692, 14.0796663577401]],
    [[49.5876248364692, 14.0796663577401], [49.5875491977876, 14.0795064655482]],
    [[49.5875491977876, 14.0795064655482], [49.5874970522906, 14.0793963356693]],
    [[49.5874970522906, 14.0793963356693], [49.5874985214221, 14.079386244722]],
    [[49.5875912506314, 14.079732677242], [49.5874565866025, 14.079469452785]],
    [[49.5874565866025, 14.079469452785], [49.5874520352898, 14.0794997859195]],
    [[49.5874520352898, 14.0794997859195], [49.5871006623161, 14.0788892064225]],
    [[49.5876301226151, 14.0796738458777], [49.5875912506314, 14.079732677242]],
    [[49.5874970522906, 14.0793963356693], [49.5874752759911, 14.0793454996132]],
    [[49.5874752759911, 14.0793454996132], [49.5874825060996, 14.0792930662758]],
    [[49.5874825060996, 14.0792930662758], [49.5874363885482, 14.0792147268617]],
    [[49.5874363885482, 14.0792147268617], [49.5873432764781, 14.0790564242333]],
    [[49.581543688855, 14.0836616636931], [49.5818770766002, 14.0834805143315]],
    [[49.5818770766002, 14.0834805143315], [49.5821843370115, 14.0831748028283]],
    [[49.5821843370115, 14.0831748028283], [49.5821970750134, 14.0832054270231]],
    [[49.5814675048925, 14.0845010311847], [49.5816227639225, 14.0843842733953]],
    [[49.5816737338377, 14.0843462053536], [49.5818911624503, 14.0841707878413]],
    [[49.5814790226165, 14.0845416261396], [49.581673155249, 14.08439563847]],
    [[49.5820659070723, 14.0840986627018], [49.5827617532626, 14.0836182873474]],
    [[49.5827617532626, 14.0836182873474], [49.5832928721101, 14.08321308605]],
    [[49.5832928721101, 14.08321308605], [49.5832834323291, 14.0831838167844]],
    [[49.5820636898608, 14.0840516859396], [49.5820634324813, 14.0840275655641]],
    [[49.5796608436336, 14.0858037358721], [49.5796486786487, 14.0857636335977]],
    [[49.5796486786487, 14.0857636335977], [49.5798022194257, 14.0856534701368]],
    [[49.5787236926891, 14.0862594676898], [49.5781650611465, 14.0864932211457]],
    [[49.5794180707403, 14.0859147531815], [49.5794089279525, 14.0858727950397]],
    [[49.5794089279525, 14.0858727950397], [49.5793893835586, 14.0858825174814]],
    [[49.5789133884833, 14.0853949601465], [49.578539710071, 14.0856274949847]],
    [[49.578539710071, 14.0856274949847], [49.5785472038599, 14.0856559766164]],
    [[49.5789133884833, 14.0853949601465], [49.578921113954, 14.0854244711006]],
    [[49.5756072236186, 14.0873934444208], [49.5756146841703, 14.0874471307985]],
    [[49.5741469076588, 14.0879088061664], [49.5741262264247, 14.0878925505162]],
    [[49.5741262264247, 14.0878925505162], [49.5740733455928, 14.0879041963362]],
    [[49.5731208348378, 14.0880903655184], [49.5731555384671, 14.0881043582391]],
    [[49.5731555384671, 14.0881043582391], [49.5731418545825, 14.0881846062132]],
    [[49.5731418545825, 14.0881846062132], [49.5731290694283, 14.088179451136]],
    [[49.5716384148458, 14.0875378509253], [49.5715990334526, 14.0875248743969]],
    [[49.5715990334526, 14.0875248743969], [49.5715877289192, 14.0876059910107]],
    [[49.5715877289192, 14.0876059910107], [49.571599720731, 14.08760994244]],
    [[49.5704026700944, 14.0862424895127], [49.570471900704, 14.0862995587672]],
    [[49.5704026700966, 14.0862424895146], [49.5703289964681, 14.0864612309055]],
    [[49.5703289964681, 14.0864612309055], [49.5703051494313, 14.0865274707264]],
    [[49.5703051494313, 14.0865274707264], [49.5702439591575, 14.0866976730447]],
    [[49.5702439591575, 14.0866976730447], [49.5701162739349, 14.0870691206886]],
    [[49.5701162739349, 14.0870691206886], [49.5699377032005, 14.0875670379626]],
    [[49.5700444838314, 14.0875628312704], [49.5702299735253, 14.0870336551627]],
    [[49.5702299735253, 14.0870336551627], [49.5702861384327, 14.0868699982237]],
    [[49.5702861384327, 14.0868699982237], [49.5703657675079, 14.0866143494927]],
    [[49.5703657675079, 14.0866143494927], [49.570471900704, 14.0862995587672]],
    [[49.5698763963958, 14.0891369640002], [49.5698613498429, 14.0888281302929]],
    [[49.5696816634518, 14.0883125805346], [49.569583619982, 14.088617631845]],
    [[49.569583619982, 14.088617631845], [49.5695003076867, 14.0888265971111]],
    [[49.5695003076867, 14.0888265971111], [49.5694883200239, 14.0888282349937]],
    [[49.5694547532608, 14.0892518450745], [49.5693800712644, 14.0891940738343]],
    [[49.5693800712644, 14.0891940738343], [49.5695003076867, 14.0888265971111]],
    [[49.5694883200239, 14.0888282349937], [49.5694033523364, 14.088788005417]],
    [[49.5694033523364, 14.088788005417], [49.5693662448284, 14.0888890111781]],
    [[49.5693662448284, 14.0888890111781], [49.5694424913416, 14.0890033009902]],
    [[49.5640772855019, 14.0855911276804], [49.5640796650896, 14.0855402431098]],
    [[49.5640796650896, 14.0855402431098], [49.5639259332607, 14.0855234395938]],
    [[49.5636228018644, 14.0855678502814], [49.5636263735198, 14.085490672238]],
    [[49.5636263735198, 14.085490672238], [49.5637143712343, 14.0855002978308]],
    [[49.5628875072142, 14.0849989061258], [49.5629754753866, 14.0851083965714]],
    [[49.5629754753866, 14.0851083965714], [49.5629855954634, 14.0851132553875]],
    [[49.562962172187, 14.0851500498314], [49.5629206049845, 14.0851661888045]],
    [[49.5629206049845, 14.0851661888045], [49.563126879466, 14.0860031414734]],
    [[49.5629695953685, 14.0865563550664], [49.5626914368746, 14.0864035796836]],
    [[49.5626562314129, 14.086384243617], [49.5622902848162, 14.0861829965357]],
    [[49.5622902848162, 14.0861829965357], [49.5623028251426, 14.086129453753]],
    [[49.5626914368746, 14.0864035796836], [49.5626710331626, 14.0864174955657]],
    [[49.5626710331626, 14.0864174955657], [49.5626562314129, 14.086384243617]],
    [[49.5633247314934, 14.0870493479399], [49.5633345840774, 14.0870402960204]],
    [[49.5633345840774, 14.0870402960204], [49.5632703353677, 14.0867697717776]],
    [[49.5632703353677, 14.0867697717776], [49.5632423091855, 14.0866498989568]],
    [[49.5569463045827, 14.0830684245695], [49.5569532600961, 14.0830329231729]],
    [[49.5561376846475, 14.0827149636372], [49.556426070883, 14.0828654958943]],
    [[49.5565469915175, 14.0819968445619], [49.5565354554702, 14.0819252080734]],
    [[49.5565354554702, 14.0819252080734], [49.5564765107875, 14.0819476377918]],
    [[49.5560962187464, 14.0819006059361], [49.5561016497619, 14.0816580223585]],
    [[49.5561016497619, 14.0816580223585], [49.5561712936665, 14.0812927854832]],
    [[49.5561712936665, 14.0812927854832], [49.5562282323999, 14.0811696982393]],
    [[49.5562282323999, 14.0811696982393], [49.5562935315037, 14.0810900883653]],
    [[49.5562935315037, 14.0810900883653], [49.556428405513, 14.080994439345]],
    [[49.556428405513, 14.080994439345], [49.5563774853793, 14.080757957516]],
    [[49.5563774853793, 14.080757957516], [49.5562596996798, 14.0808322908804]],
    [[49.5562596996798, 14.0808322908804], [49.5562067632586, 14.0809057738101]],
    [[49.5562067632586, 14.0809057738101], [49.555839035796, 14.0810979510758]],
    [[49.555839035796, 14.0810979510758], [49.5556472213431, 14.0811981056434]],
    [[49.5556472213431, 14.0811981056434], [49.5552715658931, 14.0813943559869]],
    [[49.5557690277164, 14.082508259085], [49.5556578136706, 14.0825744458743]],
    [[49.5556578136706, 14.0825744458743], [49.5555242589908, 14.0825967766935]],
    [[49.5555242589908, 14.0825967766935], [49.5554084454606, 14.0825689612949]],
    [[49.5554084454606, 14.0825689612949], [49.5551722210465, 14.0824599653345]],
    [[49.5551722210465, 14.0824599653345], [49.5550753334823, 14.0823997845684]],
    [[49.5550753334823, 14.0823997845684], [49.5547240473272, 14.0822404236613]],
    [[49.5547240473272, 14.0822404236613], [49.5546428337466, 14.0822179711819]],
    [[49.5546428337466, 14.0822179711819], [49.5544837825806, 14.0821413010532]],
    [[49.5544837825806, 14.0821413010532], [49.5543690481455, 14.0821321138776]],
    [[49.5543690481455, 14.0821321138776], [49.5542022060724, 14.0821494860143]],
    [[49.5542022060724, 14.0821494860143], [49.5541613999623, 14.0821702939658]],
    [[49.5541613999623, 14.0821702939658], [49.5538657378685, 14.082320452341]],
    [[49.5538657378685, 14.082320452341], [49.5538244122015, 14.0821218075833]],
    [[49.5538244122015, 14.0821218075833], [49.5539200967079, 14.0820732487313]],
    [[49.5539200967079, 14.0820732487313], [49.5540175236512, 14.0820238899579]],
    [[49.5540175236512, 14.0820238899579], [49.5540203930794, 14.0819848284772]],
    [[49.5540203930794, 14.0819848284772], [49.5542561108571, 14.0819023574598]],
    [[49.5542561108571, 14.0819023574598], [49.5543060907462, 14.0818769035013]],
    [[49.5527631763328, 14.0804300205949], [49.5522798812507, 14.0802167757441]],
    [[49.5522798812507, 14.0802167757441], [49.5522731988266, 14.0802571653968]],
    [[49.5406657312076, 14.0800707509788], [49.5406660129288, 14.0801141171218]],
    [[49.5406660129288, 14.0801141171218], [49.5403140350116, 14.080088205454]],
    [[49.539579226254, 14.0800564498116], [49.5391877805787, 14.0800623688378]],
    [[49.5391877805787, 14.0800623688378], [49.5391874828518, 14.0800187644627]],
    [[49.5403549999824, 14.0801344848795], [49.5403736653428, 14.0801569691656]],
    [[49.5403736653428, 14.0801569691656], [49.540382794576, 14.0801891168532]],
    [[49.540382794576, 14.0801891168532], [49.5403815467777, 14.0802325987044]],
    [[49.5403815467777, 14.0802325987044], [49.5403543983301, 14.0802301944159]],
    [[49.5403543983301, 14.0802301944159], [49.5403555781173, 14.0801969185649]],
    [[49.5403555781173, 14.0801969185649], [49.5403517943483, 14.0801835943965]],
    [[49.5403517943483, 14.0801835943965], [49.5403070727103, 14.0801321789793]],
    [[49.5402453318105, 14.0792497270491], [49.5402358499085, 14.0792431511373]],
    [[49.5402358499085, 14.0792431511373], [49.5402272625234, 14.0792107653639]],
    [[49.5330573292448, 14.0779485404358], [49.5330049454882, 14.0779181091985]],
    [[49.532999959583, 14.0780212572454], [49.5328254164504, 14.0779002418379]],
    [[49.5328254164504, 14.0779002418379], [49.5328120475954, 14.077944467624]],
    [[49.5355772849316, 14.0796349838389], [49.5355683792852, 14.0796826070842]],
    [[49.5355683792852, 14.0796826070842], [49.5354231022047, 14.0796143771969]],
    [[49.5269855506417, 14.075370948613], [49.5269640638662, 14.0753647979661]],
    [[49.5269640638662, 14.0753647979661], [49.5269176648593, 14.0753204229778]],
    [[49.5260098694112, 14.076172056216], [49.5260139713499, 14.0761682293686]],
    [[49.5260139713499, 14.0761682293686], [49.5260342251298, 14.0762189987351]],
    [[49.5260342251298, 14.0762189987351], [49.5259029056256, 14.0763449887743]],
    [[49.5259029056256, 14.0763449887743], [49.5258814151024, 14.0762918955132]],
    [[49.5258814151024, 14.0762918955132], [49.525932862321, 14.0762438987819]],
    [[49.5260098694112, 14.076172056216], [49.525932862321, 14.0762438987819]],
    [[49.5228616881037, 14.0869383963701], [49.5228550568927, 14.0870187967441]],
    [[49.5228550568927, 14.0870187967441], [49.522799084482, 14.0870077883446]],
    [[49.522799084482, 14.0870077883446], [49.5228063858601, 14.0869204898992]],
    [[49.5228063858601, 14.0869204898992], [49.5228315927245, 14.0869251292435]],
    [[49.5259579813823, 14.075700418093], [49.5259414878754, 14.0760395697408]],
    [[49.6231744921715, 14.0524922604043], [49.6231949942597, 14.0525547286664]],
    [[49.6231949942597, 14.0525547286664], [49.6232547847653, 14.0525628532506]],
    [[49.6232547847653, 14.0525628532506], [49.623323864499, 14.0526996619414]],
    [[49.623323864499, 14.0526996619414], [49.6232025398106, 14.0526940014403]],
    [[49.6199308888374, 14.0571268619606], [49.6198543332575, 14.0572152384215]],
    [[49.6198543332575, 14.0572152384215], [49.6198209124094, 14.057229002795]],
    [[49.6197669917133, 14.0573842692064], [49.6197681148805, 14.0574964217391]],
    [[49.6197681148805, 14.0574964217391], [49.6197545845826, 14.0576308023073]],
    [[49.6090104994823, 14.0605299214791], [49.6090295198353, 14.0606029020499]],
    [[49.6090295198353, 14.0606029020499], [49.6086822825137, 14.0607538296045]],
    [[49.6086822825137, 14.0607538296045], [49.6086788284253, 14.0607322243869]],
    [[49.6078284266783, 14.0646773928652], [49.6078402112199, 14.0644387487697]],
    [[49.6078402112199, 14.0644387487697], [49.6078366179493, 14.0643155661707]],
    [[49.6078366179493, 14.0643155661707], [49.6078182464895, 14.0642505504755]],
    [[49.6047812918926, 14.067193592538], [49.6046895976527, 14.0667181201992]],
    [[49.6046895976527, 14.0667181201992], [49.6046799469177, 14.0666678275457]],
    [[49.6046799469177, 14.0666678275457], [49.6046231999079, 14.0664109587749]],
    [[49.6046231999079, 14.0664109587749], [49.6045709358451, 14.0661740710024]],
    [[49.6045709358451, 14.0661740710024], [49.6045404661705, 14.066036243807]],
    [[49.6045404661705, 14.066036243807], [49.6045194967922, 14.0659411954205]],
    [[49.6045194967922, 14.0659411954205], [49.6043878360967, 14.0653450753306]],
    [[49.6043878360967, 14.0653450753306], [49.6043401549931, 14.065129265852]],
    [[49.6047812918926, 14.067193592538], [49.604825115615, 14.0674570681482]],
    [[49.6051400218463, 14.0685357243687], [49.6050653936261, 14.0682563565916]],
    [[49.6050653936261, 14.0682563565916], [49.6049851959116, 14.0680188785625]],
    [[49.6049851959116, 14.0680188785625], [49.6049350909866, 14.0678484572075]],
    [[49.6050097096117, 14.068566375424], [49.6049855648468, 14.0684212904576]],
    [[49.6049855648468, 14.0684212904576], [49.6049084070303, 14.0679575831325]],
    [[49.6049084070303, 14.0679575831325], [49.6048726075845, 14.0677425201753]],
    [[49.6048726075845, 14.0677425201753], [49.6049182638765, 14.0678609492152]],
    [[49.6049182638765, 14.0678609492152], [49.6049350909866, 14.0678484572075]],
    [[49.602675393685, 14.0590749125834], [49.6025868902527, 14.0589421348375]],
    [[49.6025868902527, 14.0589421348375], [49.6025549943865, 14.0588968636419]],
    [[49.6025549943865, 14.0588968636419], [49.6025278398341, 14.0588606146362]],
    [[49.6025278398341, 14.0588606146362], [49.6025150765998, 14.0588414731155]],
    [[49.6025150765998, 14.0588414731155], [49.6024993507972, 14.0588207453519]],
    [[49.6024993507972, 14.0588207453519], [49.6024993507972, 14.0588207453519]],
    [[49.6024993507972, 14.0588207453519], [49.6021059859849, 14.0582501977661]],
    [[49.6021059859849, 14.0582501977661], [49.6019944454587, 14.0580883769674]],
    [[49.6019944454587, 14.0580883769674], [49.6022252981686, 14.0584645373689]],
    [[49.591498814191, 14.0712765791855], [49.5914626941692, 14.0710904258152]],
    [[49.5917074713892, 14.0721376346229], [49.5918338287952, 14.0721506209343]],
    [[49.5731926519633, 14.0906514685104], [49.5731926519633, 14.0906514685104]],
    [[49.5731926519633, 14.0906514685104], [49.5728846063738, 14.0892424711717]],
    [[49.5727782039143, 14.0887348902504], [49.5726457781851, 14.0881023289349]],
    [[49.5725162881152, 14.0874802021184], [49.5723521706852, 14.0866914091846]],
    [[49.5722818017201, 14.0867132156229], [49.5724300664887, 14.087465253661]],
    [[49.5724300664887, 14.087465253661], [49.5724351945341, 14.087489860194]],
    [[49.5725662780603, 14.0881193771976], [49.5726375927082, 14.0884477637278]],
    [[49.5726375927082, 14.0884477637278], [49.5728071111112, 14.0892471174864]],
    [[49.5728071111112, 14.0892471174864], [49.5728830880891, 14.089605351837]],
    [[49.5728830880891, 14.089605351837], [49.5729846807479, 14.0900845184049]],
    [[49.5729846807479, 14.0900845184049], [49.5731110249952, 14.0906801985133]],
    [[49.5731926519633, 14.0906514685104], [49.5731765888619, 14.0906565147805]],
    [[49.5731765888619, 14.0906565147805], [49.5731462168862, 14.0906678998712]],
    [[49.5731462168862, 14.0906678998712], [49.5731110249952, 14.0906801985133]],
    [[49.5698084402308, 14.0882397671898], [49.569579897324, 14.0888848926822]],
    [[49.569579897324, 14.0888848926822], [49.5694931156654, 14.0891393578726]],
    [[49.5681485233247, 14.0873922404344], [49.5681433891466, 14.087328847193]],
    [[49.5681433891466, 14.087328847193], [49.5681124349433, 14.0873349143912]],
    [[49.5681124349433, 14.0873349143912], [49.5679412330966, 14.0873682828233]],
    [[49.5291447141092, 14.0769163094142], [49.5290209683001, 14.0768903373242]],
    [[49.5290209683001, 14.0768903373242], [49.5290284951321, 14.0768235294386]],
    [[49.6044841985187, 14.0619557129508], [49.6045027360701, 14.0619980505329]],
    [[49.6044841985187, 14.0619557129508], [49.6044692841232, 14.0619711742612]],
    [[49.5735197208221, 14.0872209081132], [49.5735224698453, 14.0872424381946]],
    [[49.5735284331147, 14.0872891420551], [49.5735319829269, 14.087316943983]],
    [[49.6122189552725, 14.0586669066409], [49.6122909286364, 14.0586259549091]],
    [[49.6122909286364, 14.0586259549091], [49.6122884948785, 14.0585675057032]],
    [[49.59194635854, 14.0722227843238], [49.5919050707192, 14.0721735812697]],
    [[49.5919050707192, 14.0721735812697], [49.591807158799, 14.0720832504225]],
    [[49.591807158799, 14.0720832504225], [49.5917047966491, 14.0718563438288]],
    [[49.5465687119229, 14.0790363890771], [49.5465666843675, 14.0790747798834]],
    [[49.604261719631, 14.0669800194054], [49.6042362190274, 14.0669352245978]],
    [[49.6048545612744, 14.0681209746283], [49.6048003699375, 14.0680305482967]],
    [[49.6048003699375, 14.0680305482967], [49.6047948333308, 14.068015722568]],
    [[49.6048591349663, 14.0681163874347], [49.6048047462467, 14.0680256050667]],
    [[49.6048047462467, 14.0680256050667], [49.6048000147245, 14.0680129351649]],
    [[49.6047977357672, 14.0680068326563], [49.6047614143204, 14.0679095726074]],
    [[49.6047614143204, 14.0679095726074], [49.6046684728127, 14.0677009711959]],
    [[49.6046684728127, 14.0677009711959], [49.6046196977823, 14.0675861243835]],
    [[49.6046196977823, 14.0675861243835], [49.6045556729186, 14.0674658806009]],
    [[49.6045556729186, 14.0674658806009], [49.6044432408606, 14.067276150957]],
    [[49.6044432408606, 14.067276150957], [49.604318533233, 14.0670672207174]],
    [[49.604318533233, 14.0670672207174], [49.6042402773092, 14.0669297547515]],
    [[49.6048545612744, 14.0681209746283], [49.6049674167803, 14.0685098080623]],
    [[49.6049674167803, 14.0685098080623], [49.6049723428924, 14.0685064228746]],
    [[49.6049723428924, 14.0685064228746], [49.6048591349663, 14.0681163874347]],
    [[49.6016463256638, 14.0573867556765], [49.6015764762972, 14.0574905362661]],
    [[49.6015764762972, 14.0574905362661], [49.6019283014686, 14.0579930421964]],
    [[49.5908993467089, 14.0721940877745], [49.5908742216127, 14.0721525629745]],
    [[49.5908670035313, 14.0721550152], [49.5905932771274, 14.0725469229721]],
    [[49.5813620359492, 14.0837839515062], [49.5813723726178, 14.08379022792]],
    [[49.5813723726178, 14.08379022792], [49.5813931697559, 14.0838573680525]],
    [[49.5813986538742, 14.0837571217817], [49.5814222424676, 14.083836758194]],
    [[49.5815641043059, 14.0843079850538], [49.581621842674, 14.0842906620015]],
    [[49.581621842674, 14.0842906620015], [49.5816537048168, 14.0843861293927]],
    [[49.5815507702007, 14.0842654890567], [49.5816380546785, 14.0842393012944]],
    [[49.5816380546785, 14.0842393012944], [49.5816646748447, 14.0843190622068]],
    [[49.5816737338377, 14.0843462053536], [49.5816801030725, 14.0843652892889]],
    [[49.5816801030725, 14.0843652892889], [49.5816537048168, 14.0843861293927]],
    [[49.5815507702007, 14.0842654890567], [49.5815641043059, 14.0843079850538]],
    [[49.5735648055821, 14.0873127022634], [49.5735629746952, 14.087293807082]],
    [[49.5735629746952, 14.087293807082], [49.5735478161715, 14.0872974138124]],
    [[49.5735478161715, 14.0872974138124], [49.5735404645941, 14.087239836799]],
    [[49.5735284331147, 14.0872891420551], [49.5735224698453, 14.0872424381946]],
    [[49.5735197208221, 14.0872209081132], [49.5735373674456, 14.0872155802318]],
    [[49.5735373674456, 14.0872155802318], [49.5735404645941, 14.087239836799]],
    [[49.562962172187, 14.0851500498314], [49.5629696281331, 14.0850990110298]],
    [[49.5629696281331, 14.0850990110298], [49.562895347495, 14.0850692307018]],
    [[49.562895347495, 14.0850692307018], [49.5628850751218, 14.0850302468715]],
    [[49.5628850751218, 14.0850302468715], [49.5628558988176, 14.0850454682224]],
    [[49.5727782039143, 14.0887348902504], [49.5728470878958, 14.0887078248691]],
    [[49.5728470878958, 14.0887078248691], [49.5728727561641, 14.0888302802839]],
    [[49.5728727561641, 14.0888302802839], [49.572951797495, 14.0891646619851]],
    [[49.5729443431948, 14.089224528043], [49.5728846063738, 14.0892424711717]],
    [[49.572951797495, 14.0891646619851], [49.5729435273407, 14.0891744298396]],
    [[49.5729435273407, 14.0891744298396], [49.5729443431948, 14.089224528043]],
    [[49.5724357992548, 14.0867059475672], [49.5723851771877, 14.0867311185421]],
    [[49.5723851771877, 14.0867311185421], [49.5724347792461, 14.0869680257423]],
    [[49.5724347792461, 14.0869680257423], [49.5724562363606, 14.0869574698822]],
    [[49.5724562363606, 14.0869574698822], [49.5724177625637, 14.0867741320958]],
    [[49.5724177625637, 14.0867741320958], [49.5724475258301, 14.0867581294161]],
    [[49.5724357992548, 14.0867059475672], [49.5724475258301, 14.0867581294161]],
    [[49.572511490992, 14.0884176795604], [49.5723301876926, 14.0881517121269]],
    [[49.5723301876926, 14.0881517121269], [49.572295164804, 14.0873019195506]],
    [[49.572295164804, 14.0873019195506], [49.5723116419568, 14.0871605314738]],
    [[49.5723116419568, 14.0871605314738], [49.5723089418312, 14.0871451904703]],
    [[49.5725077953209, 14.0884218128143], [49.5723258120087, 14.0881548478149]],
    [[49.5723258120087, 14.0881548478149], [49.5722906435412, 14.0873015227545]],
    [[49.5722906435412, 14.0873015227545], [49.5723070385483, 14.0871608395711]],
    [[49.5723070385483, 14.0871608395711], [49.5723046020757, 14.0871469965364]],
    [[49.572974719342, 14.0902449677828], [49.5729705892262, 14.0902475752739]],
    [[49.5723089418312, 14.0871451904703], [49.5723046020757, 14.0871469965364]],
    [[49.5556539082602, 14.0822487472778], [49.5556621194097, 14.0822997311254]],
    [[49.5439733994413, 14.0787715118656], [49.5439182300198, 14.0788282599034]],
    [[49.5439253647819, 14.0798138300029], [49.5439276817327, 14.0799720930713]],
    [[49.5439276817327, 14.0799720930713], [49.5439270254362, 14.0807058483048]],
    [[49.5439270254362, 14.0807058483048], [49.5439325346551, 14.0811785042862]],
    [[49.5439325346551, 14.0811785042862], [49.5439440371788, 14.0811928787469]],
    [[49.5439440371788, 14.0811928787469], [49.5439686935283, 14.0811943285885]],
    [[49.5439182300198, 14.0788282599034], [49.5439122419499, 14.0789183177845]],
    [[49.5439122419499, 14.0789183177845], [49.5439139158755, 14.0790324709509]],
    [[49.5439790712333, 14.07982402979], [49.5439861930771, 14.0801587567061]],
    [[49.5439861930771, 14.0801587567061], [49.5439666427847, 14.0808512803175]],
    [[49.5439666427847, 14.0808512803175], [49.5439686935283, 14.0811943285885]],
    [[49.5907113082889, 14.0731610030857], [49.5908459871921, 14.0729767348037]],
    [[49.5908459871921, 14.0729767348037], [49.5909619178495, 14.0728141436887]],
    [[49.5909619178495, 14.0728141436887], [49.5911120743555, 14.0725800860148]],
    [[49.5909504265669, 14.072126673537], [49.5907493415554, 14.072392060637]],
    [[49.5908993467089, 14.0721940877745], [49.5907493415554, 14.072392060637]],
    [[49.5230760241533, 14.0862567792828], [49.5231936522714, 14.0858215462789]],
    [[49.5231936522714, 14.0858215462789], [49.52385738437, 14.0824300012409]],
    [[49.52385738437, 14.0824300012409], [49.5243748566595, 14.079726436593]],
    [[49.5247307336259, 14.0777642303776], [49.5247283666221, 14.0777633137373]],
    [[49.5247283666221, 14.0777633137373], [49.5247272174814, 14.0777745703455]],
    [[49.6203881474683, 14.0538511768943], [49.6206546594047, 14.0536602727918]],
    [[49.6206546594047, 14.0536602727918], [49.6207346134502, 14.0536149184775]],
    [[49.6207346134502, 14.0536149184775], [49.6207435552729, 14.0536309906313]],
    [[49.6203804951839, 14.0538238001636], [49.6204198455267, 14.0537838467892]],
    [[49.6204198455267, 14.0537838467892], [49.6205878308337, 14.053678469385]],
    [[49.6205878308337, 14.053678469385], [49.6207254711435, 14.0535984859813]],
    [[49.6207254711435, 14.0535984859813], [49.6207913341498, 14.0535623587201]],
    [[49.6016433761971, 14.0655447044738], [49.6015410441693, 14.0655573359464]],
    [[49.6015410441693, 14.0655573359464], [49.6009348283143, 14.0656791019794]],
    [[49.546096105327, 14.0790267015172], [49.546111973627, 14.0789794139766]],
    [[49.546111973627, 14.0789794139766], [49.5465687119229, 14.0790363890771]],
    [[49.6043244992244, 14.0623901710981], [49.6042879049282, 14.0624314558745]],
    [[49.6042879049282, 14.0624314558745], [49.604169346497, 14.0625605879085]],
    [[49.604169346497, 14.0625605879085], [49.6040891628983, 14.0627010110468]],
    [[49.6040891628983, 14.0627010110468], [49.6040840590931, 14.0627204377023]],
    [[49.6040840590931, 14.0627204377023], [49.6040340519309, 14.0627533795557]],
    [[49.6036110668829, 14.0628640317594], [49.6035848241449, 14.0628600768289]],
    [[49.6035848241449, 14.0628600768289], [49.6035629021113, 14.0628567773689]],
    [[49.6035629021113, 14.0628567773689], [49.6035451254262, 14.0628541063626]],
    [[49.6035451254262, 14.0628541063626], [49.6038391802186, 14.0626751806458]],
    [[49.6060025694041, 14.0625942876793], [49.6060348881998, 14.0625981500675]],
    [[49.6060053330123, 14.0625434677825], [49.6059274977437, 14.0625431249686]],
    [[49.6060053330123, 14.0625434677825], [49.6060025694041, 14.0625942876793]],
    [[49.5968508763672, 14.0666617269757], [49.5969026027989, 14.0668604744141]],
    [[49.6043705652936, 14.0646581991257], [49.604430842659, 14.0648991981112]],
    [[49.604430842659, 14.0648991981112], [49.60451891814, 14.0652354259516]],
    [[49.60451891814, 14.0652354259516], [49.6045357632943, 14.065299654016]],
    [[49.6045357632943, 14.065299654016], [49.6045959936214, 14.0655695937135]],
    [[49.6045959936214, 14.0655695937135], [49.6047098730323, 14.0660805904903]],
    [[49.6047098730323, 14.0660805904903], [49.6048454131971, 14.0667059126562]],
    [[49.6048454131971, 14.0667059126562], [49.6049693546296, 14.0670470029091]],
    [[49.6049693546296, 14.0670470029091], [49.6051282861061, 14.0673108285867]],
    [[49.6051282861061, 14.0673108285867], [49.60523384169, 14.0674101215418]],
    [[49.60523384169, 14.0674101215418], [49.605170502955, 14.0674068123331]],
    [[49.605170502955, 14.0674068123331], [49.6050972680243, 14.0673682175507]],
    [[49.5958546486556, 14.0675025476863], [49.5958417266752, 14.067446005164]],
    [[49.5958417266752, 14.067446005164], [49.5956406585222, 14.0675714328297]],
    [[49.5956406585222, 14.0675714328297], [49.5956539774352, 14.0676247631439]],
    [[49.5946919543972, 14.0682104215954], [49.5946811386221, 14.0681654886956]],
    [[49.5946811386221, 14.0681654886956], [49.5946093954186, 14.0682095379274]],
    [[49.5946093954186, 14.0682095379274], [49.5946245845646, 14.0682518686964]],
    [[49.5556647872365, 14.0817884427727], [49.555661595537, 14.0819453633479]],
    [[49.555661595537, 14.0819453633479], [49.5556455748406, 14.0821599548681]],
    [[49.5556455748406, 14.0821599548681], [49.5556859284067, 14.0821170683714]],
    [[49.5556859284067, 14.0821170683714], [49.5556990124509, 14.0818059505023]],
    [[49.5557481328407, 14.0813699608533], [49.5556467635149, 14.0814208827311]],
    [[49.569858033771, 14.0887600695218], [49.5698613498429, 14.0888281302929]],
    [[49.5400204316093, 14.0803896095649], [49.5400898041849, 14.080409058321]],
    [[49.6043705652936, 14.0646581991257], [49.604364490701, 14.0647936941101]],
    [[49.604364490701, 14.0647936941101], [49.6043507854059, 14.0650812363635]],
    [[49.6043507854059, 14.0650812363635], [49.6043401549931, 14.065129265852]],
    [[49.6082554768796, 14.0610047192617], [49.6082391821741, 14.0609642782336]],
    [[49.5694547532608, 14.0892518450745], [49.5694931156654, 14.0891393578726]],
    [[49.581673155249, 14.08439563847], [49.581903719923, 14.0842106261912]],
    [[49.581903719923, 14.0842106261912], [49.5820659070723, 14.0840986627018]],
    [[49.5818911624503, 14.0841707878413], [49.5820636898608, 14.0840516859396]],
    [[49.5794180707403, 14.0859147531815], [49.5788619695572, 14.0862013528997]],
    [[49.5788619695572, 14.0862013528997], [49.5787236926891, 14.0862594676898]],
    [[49.5347286036911, 14.0785762747698], [49.5347121572397, 14.078591365396]],
    [[49.5347121572397, 14.078591365396], [49.5342402110458, 14.0784084777942]],
    [[49.5342402110458, 14.0784084777942], [49.5338939499142, 14.078294104971]],
    [[49.5338939499142, 14.078294104971], [49.5338836150026, 14.0782695487678]],
    [[49.6165618581749, 14.056068671007], [49.6165283945753, 14.056075304287]],
    [[49.6027514140735, 14.060093599924], [49.602743176959, 14.0601274718429]],
    [[49.602743176959, 14.0601274718429], [49.6027343520583, 14.0600810407869]],
    [[49.5983902618776, 14.0670018543232], [49.5985484168916, 14.0668983548534]],
    [[49.5985484168916, 14.0668983548534], [49.5986457836933, 14.0668389241908]],
    [[49.5986459615026, 14.0668462571497], [49.5985501260008, 14.0669047531243]],
    [[49.5985501260008, 14.0669047531243], [49.5983920095769, 14.0670082273193]],
    [[49.5986457836933, 14.0668389241908], [49.5987068895169, 14.0668016263404]],
    [[49.5987068895169, 14.0668016263404], [49.5990970130206, 14.0665548361292]],
    [[49.5990969274042, 14.0665640151239], [49.5987085709327, 14.0668080415356]],
    [[49.5987085709327, 14.0668080415356], [49.5986459615026, 14.0668462571497]],
    [[49.586935471062, 14.0780728791911], [49.5869334232011, 14.0780691807995]],
    [[49.5869334232011, 14.0780691807995], [49.5867158555548, 14.0778481950966]],
    [[49.586954797896, 14.0780437914371], [49.5869514794721, 14.0780377984395]],
    [[49.5869514794721, 14.0780377984395], [49.5867059846135, 14.0777885037994]],
    [[49.5867059846135, 14.0777885037994], [49.5866964079472, 14.077810288745]],
    [[49.5874090697748, 14.079015083338], [49.5873023112088, 14.0786714001684]],
    [[49.5873023112088, 14.0786714001684], [49.5872973421799, 14.0786624259999]],
    [[49.5872757948251, 14.0786875036888], [49.5872794886398, 14.0786941747954]],
    [[49.5872794886398, 14.0786941747954], [49.5873848388904, 14.0790335006823]],
    [[49.5671272355679, 14.0879197160412], [49.5671288584435, 14.0878872542248]],
    ]
    
    

const dxfData = [
    [[50.0746090856957, 14.4170156450737], [50.0745671742546, 14.416942110726]],
    [[50.0745259226074, 14.4174589071142], [50.0746029335795, 14.4174379213976]],
    [[50.0745936550026, 14.4177081358817], [50.074546185751, 14.4172860478583]],
    [[50.0745061146443, 14.4168214804948], [50.0745737951991, 14.4169471815717]],
    [[50.074546185751, 14.4172860478583], [50.0744179915092, 14.4170479198993]],
    [[50.0746226103599, 14.417661045083], [50.0745561375831, 14.4176790712139]],
    [[50.0746108280104, 14.4168990675046], [50.0744279370407, 14.4171366123777]],
    [[50.0745319049906, 14.4174260378717], [50.0745223314672, 14.4173364194655]],
    [[50.0745223314672, 14.4173364194655], [50.0745188844747, 14.4173057082309]],
    [[50.0745188844747, 14.4173057082309], [50.0744581556331, 14.4171929043765]],
    [[50.0744581556331, 14.4171929043765], [50.0743957717871, 14.4170767881644]],
    [[50.0743957717871, 14.4170767881644], [50.0744402880335, 14.4170188941524]],
    [[50.0744402880335, 14.4170188941524], [50.074453862194, 14.4170440544196]],
    [[50.074453862194, 14.4170440544196], [50.0745324867072, 14.4169419155243]],
    [[50.0745324867072, 14.4169419155243], [50.074483486408, 14.4168508593918]],
    [[50.074483486408, 14.4168508593918], [50.074528742873, 14.4167921015703]],
    [[50.074528742873, 14.4167921015703], [50.0745777432177, 14.4168831577288]],
    [[50.0745777432177, 14.4168831577288], [50.074592236835, 14.4168644035113]],
    [[50.074592236835, 14.4168644035113], [50.0746295082471, 14.4169337124709]],
    [[50.0746295082471, 14.4169337124709], [50.0746125754544, 14.416955667935]],
    [[50.0746125754544, 14.416955667935], [50.074630079688, 14.4169883080779]],
    [[50.074630079688, 14.4169883080779], [50.0745938762452, 14.4170355395493]],
    [[50.0745938762452, 14.4170355395493], [50.0745762706907, 14.4170027800653]],
    [[50.0745762706907, 14.4170027800653], [50.0744911335002, 14.4171133633187]],
    [[50.0744911335002, 14.4171133633187], [50.0745027610083, 14.4171349913437]],
    [[50.0745027610083, 14.4171349913437], [50.0745734870238, 14.4172663874634]],
    [[50.0745734870238, 14.4172663874634], [50.0745795897798, 14.4173209279955]],
    [[50.0745795897798, 14.4173209279955], [50.074590219914, 14.417410179469]],
    [[50.0745965916668, 14.4174718547669], [50.0746158043866, 14.4176426163285]],
    [[50.0745585582934, 14.4176582458298], [50.0745387253333, 14.4174866299659]]
]

const dxfArcs = [
    {
        center: [50.0745779379649, 14.4174446783045],
        radius: 2.82,
        startAngle: 40.846487,
        stopAngle: 143.315495
    },
    {
        center: [50.0745674258742, 14.4174745677907],
        radius: 5.26,
        startAngle: 143.315514,
        stopAngle: 220.846469
    },
    {
        center: [50.0745509182231, 14.4174521502219],
        radius: 2.82,
        startAngle: 220.846488,
        stopAngle: 323.315496
    },
    {
        center: [50.0745615193799, 14.4174222416882],
        radius: 5.26,
        startAngle: 323.315506,
        stopAngle: 40.846461
    },
    {
        center: [50.0746029345164, 14.4176663819302],
        radius: 2.22,
        startAngle: 40.846484,
        stopAngle: 143.315492
    },
    {
        center: [50.0745923332971, 14.4176962904357],
        radius: 4.66,
        startAngle: 143.31551,
        stopAngle: 220.846465
    },
    {
        center: [50.0745759024991, 14.4176737153248],
        radius: 2.22,
        startAngle: 220.846484,
        stopAngle: 323.315492
    },
    {
        center: [50.0745864269026, 14.4176439642792],
        radius: 4.66,
        startAngle: 323.31551,
        stopAngle: 40.846465
    }
]

// test
const dxfData0 = [
    [[50.0745259226074, 14.4174589071142], [50.0746029335795, 14.4174379213976]],
    [[50.0745936550026, 14.4177081358817], [50.074546185751, 14.4172860478583]],

    /*[[50.0745644456069, 14.417448409492], [50.0746183097544, 14.4174337313365]],
    [[50.0745257000865, 14.4174566972868], [50.0746031558985, 14.4174401292193]],
    [[50.0745874841729, 14.4177096301234], [50.0745500432955, 14.4172851137355]]*/

    [[50.0745795897798, 14.4173209279955], [50.074590219914, 14.417410179469]],
    [[50.0745965916668, 14.4174718547669], [50.0746158043866, 14.4176426163285]]
]

const dxfArcs0 = [
    {
        center: [50.0745779379649, 14.4174446783045],
        radius: 2.82,
        startAngle: 40.846487,
        stopAngle: 143.315495
    },
    /*{
        center: [50.0745780171913, 14.4174454523158],
        radius: 2.82,
        startAngle: 38.7375837890747,
        stopAngle: 141.206591789037
    }*/

    {
        center: [50.0745779379649, 14.4174446783045],
        radius: 2.82,
        startAngle: 40.846487,
        stopAngle: 143.315495
    }
]
//

// test posunuty
const dxfData2 = [
    [[50.074192420419, 14.4171243766869], [50.0741452655599, 14.4170367339295]],
    [[50.0741975203251, 14.4170231586742], [50.0741788764184, 14.4170473132436]],
    [[50.0740989771233, 14.4175499492045], [50.0741689114238, 14.417531041619]],
    [[50.0741631689187, 14.4169591486619], [50.0741468873813, 14.4169802594727]],
    [[50.0741635052808, 14.4178029637414], [50.0741157020569, 14.4173781304291]],
    [[50.0740601995537, 14.4168848558025], [50.0741433117739, 14.4170392672235]],
    [[50.0741157020569, 14.4173781304291], [50.0739700037971, 14.4171073645929]],
    [[50.0741097577971, 14.4175661173304], [50.0741097577971, 14.4175661173304]],
    [[50.0740687455586, 14.4172261383863], [50.0740277159541, 14.4172793372704]],
    [[50.0742031387803, 14.4169615985022], [50.0739934690633, 14.4172339200696]],
    [[50.0741819138826, 14.4170477916912], [50.0741457749598, 14.417094726824]],
    [[50.0741472598432, 14.4169752439602], [50.0741020155447, 14.4170341395455]],
    [[50.0740989771233, 14.4175499492045], [50.0741339888099, 14.4175404858924]],
    [[50.0741460828794, 14.4170900071676], [50.0741258046712, 14.4171162035598]],
    [[50.07410521889, 14.417034441484], [50.0740914655778, 14.4170523319774]],
    [[50.0741097577971, 14.4175661173304], [50.0741097577971, 14.4175661173304]],
    [[50.0741258046712, 14.4171162035598], [50.0740914655778, 14.4170523319774]],
    [[50.0741939078141, 14.4177527434065], [50.0741238844063, 14.4177716697319]],
    [[50.0741303991947, 14.4177509560053], [50.0741096564637, 14.4175659979819]],
    [[50.0741055041339, 14.4175293738596], [50.0740940622634, 14.4174278868247]],
    [[50.0740940622634, 14.4174278868247], [50.0740905417233, 14.4173963454518]],
    [[50.0740905417233, 14.4173963454518], [50.0740294844382, 14.4172829076423]],
    [[50.0740294844382, 14.4172829076423], [50.0739687310432, 14.4171698281626]],
    [[50.0739687310432, 14.4171698281626], [50.0740098374077, 14.4171164717966]],
    [[50.0740098374077, 14.4171164717966], [50.0740230952847, 14.4171411354232]],
    [[50.0740230952847, 14.4171411354232], [50.0740914655778, 14.4170523319774]],
    [[50.0740914655778, 14.4170523319774], [50.0740928810179, 14.4170549907004]],
    [[50.0740928810179, 14.4170549907004], [50.0741066465928, 14.417037238608]],
    [[50.0741066465928, 14.417037238608], [50.0740564458514, 14.4169439015613]],
    [[50.0740564458514, 14.4169439015613], [50.0740982924442, 14.4168896814191]],
    [[50.0740982924442, 14.4168896814191], [50.0741484809663, 14.4169828800897]],
    [[50.0741484809663, 14.4169828800897], [50.0741625537673, 14.4169644981386]],
    [[50.0741625537673, 14.4169644981386], [50.074194062027, 14.4170230522971]],
    [[50.074194062027, 14.4170230522971], [50.0741774609782, 14.4170446545161]],
    [[50.0741774609782, 14.4170446545161], [50.0741961534356, 14.417079437628]],
    [[50.0741961534356, 14.417079437628], [50.074163359885, 14.4171221315453]],
    [[50.074163359885, 14.4171221315453], [50.0741445661062, 14.417087229093]],
    [[50.0741445661062, 14.417087229093], [50.0741243892326, 14.4171135448333]],
    [[50.0741243892326, 14.4171135448333], [50.0741258046712, 14.4171162035598]],
    [[50.0741258046712, 14.4171162035598], [50.0740574343298, 14.4172050069781]],
    [[50.0740574343298, 14.4172050069781], [50.0740705663299, 14.4172292745042]],
    [[50.0740705663299, 14.4172292745042], [50.074070578592, 14.4172294129049]],
    [[50.074070578592, 14.4172294129049], [50.0741408746495, 14.4173600537885]],
    [[50.0741408746495, 14.4173600537885], [50.0741468915294, 14.4174136250355]],
    [[50.0741468915294, 14.4174136250355], [50.0741583211504, 14.4175149737772]],
    [[50.0741623966746, 14.4175517553919], [50.0741832284996, 14.4177366945597]],
    [[50.0741280743078, 14.4177503250977], [50.074107552286, 14.4175678583002]],
    [[50.0741648106342, 14.417552367238], [50.0741853204185, 14.4177346958447]],
    [[50.0741031669842, 14.417528604562], [50.0740918477313, 14.4174285015408]],
    [[50.0740918477313, 14.4174285015408], [50.0740884007627, 14.4173977905743]],
    [[50.0740884007627, 14.4173977905743], [50.074029505036, 14.4172882621307]],
    [[50.074029505036, 14.4172882621307], [50.0740276720043, 14.4172849876127]],
    [[50.0740276720043, 14.4172849876127], [50.073965288244, 14.4171688723199]],
    [[50.073965288244, 14.4171688723199], [50.0740098045398, 14.4171109789099]],
    [[50.0740098045398, 14.4171109789099], [50.0740233786818, 14.4171361389779]],
    [[50.0740233786818, 14.4171361389779], [50.0741020032824, 14.4170340011447]],
    [[50.0741020032824, 14.4170340011447], [50.0740530030503, 14.416942945733]],
    [[50.0740530030503, 14.416942945733], [50.0740982595656, 14.4168841885225]],
    [[50.0740982595656, 14.4168841885225], [50.0741472598432, 14.4169752439602]],
    [[50.0741472598432, 14.4169752439602], [50.0741617534765, 14.4169564899379]],
    [[50.0741617534765, 14.4169564899379], [50.0741990248374, 14.4170257983487]],
    [[50.0741990248374, 14.4170257983487], [50.074182092026, 14.4170477535845]],
    [[50.074182092026, 14.4170477535845], [50.0741995962355, 14.4170803934689]],
    [[50.0741995962355, 14.4170803934689], [50.0741633927523, 14.4171276244495]],
    [[50.0741633927523, 14.4171276244495], [50.074145787222, 14.4170948652249]],
    [[50.074145787222, 14.4170948652249], [50.0740606499369, 14.4172054473284]],
    [[50.0740606499369, 14.4172054473284], [50.074072277429, 14.4172270751822]],
    [[50.074072277429, 14.4172270751822], [50.0740740213907, 14.417230368754]],
    [[50.0740740213907, 14.417230368754], [50.0741430033477, 14.4173584702618]],
    [[50.0741430033477, 14.4173584702618], [50.0741491060613, 14.417413010318]],
    [[50.0741491060613, 14.417413010318], [50.0741603362555, 14.4175131325071]],
    [[50.0740860225269, 14.4172582627612], [50.0740450942415, 14.4173115809848]],
    [[50.0740913000087, 14.4171610967152], [50.0740569609397, 14.4170972251466]],
    [[50.0741320822151, 14.4175363809517], [50.0741358063328, 14.4175446098859]],
    [[50.0741350215835, 14.4175357522216], [50.0741328669643, 14.4175452386154]],
    [[50.0741569895236, 14.4177581016244], [50.0741608026975, 14.417766311528]],
    [[50.0741600179651, 14.4177574538556], [50.074157774256, 14.4177669592963]]
]

const dxfArcs2 = [
    {
        center: [50.0741045236444, 14.4169671808139],
        radius: 4.79,
        startAngle: 348.852995,
        stopAngle: 75.251503
    },
    {
        center: [50.0741754719405, 14.4170928871342],
        radius: 3.31,
        startAngle: 159.627524,
        stopAngle: 264.477112
    },
    {
        center: [50.0741076704977, 14.4170856728031],
        radius: 10.04,
        startAngle: 283.294351,
        stopAngle: 320.808599
    },
    {
        center: [50.074088209434, 14.4171108482399],
        radius: 7.56,
        startAngle: 278.833058,
        stopAngle: 325.27173
    },
    {
        center: [50.074111045134, 14.4169793240815],
        radius: 4.17,
        startAngle: 345.412331,
        stopAngle: 78.692034
    },
    {
        center: [50.0741696965873, 14.4170819944718],
        radius: 2.81,
        startAngle: 154.139765,
        stopAngle: 269.582945
    },
    {
        center: [50.0741474664107, 14.4175368979189],
        radius: 2.22,
        startAngle: 40.843289,
        stopAngle: 52.077777
    },
    {
        center: [50.0741474664107, 14.4175368979189],
        radius: 1.97,
        startAngle: 40.843252,
        stopAngle: 49.535435
    },
    {
        center: [50.0741474664107, 14.4175368979189],
        radius: 1.97,
        startAngle: 49.535363,
        stopAngle: 134.620211
    },
    {
        center: [50.0741474664107, 14.4175368979189],
        radius: 1.97,
        startAngle: 134.620139,
        stopAngle: 143.312323
    },
    {
        center: [50.0741369420338, 14.417566648717],
        radius: 4.41,
        startAngle: 143.31231,
        stopAngle: 220.843265
    },
    {
        center: [50.0741204343989, 14.4175442313173],
        radius: 1.97,
        startAngle: 220.843321,
        stopAngle: 229.571892
    },
    {
        center: [50.0741204343989, 14.4175442313173],
        radius: 1.97,
        startAngle: 229.57189,
        stopAngle: 314.583689
    },
    {
        center: [50.0741204343989, 14.4175442313173],
        radius: 1.97,
        startAngle: 314.583688,
        stopAngle: 323.312259
    },
    {
        center: [50.0741310355801, 14.417514323071],
        radius: 4.41,
        startAngle: 323.312311,
        stopAngle: 40.843266
    },
    {
        center: [50.0741474664107, 14.4175368979189],
        radius: 2.22,
        startAngle: 52.077796,
        stopAngle: 132.039092
    },
    {
        center: [50.0741474664107, 14.4175368979189],
        radius: 2.22,
        startAngle: 132.039136,
        stopAngle: 143.312262
    },
    {
        center: [50.0741369420338, 14.417566648717],
        radius: 4.66,
        startAngle: 143.31231,
        stopAngle: 220.843265
    },
    {
        center: [50.0741204343989, 14.4175442313173],
        radius: 2.22,
        startAngle: 220.843347,
        stopAngle: 232.119674
    },
    {
        center: [50.0741204343989, 14.4175442313173],
        radius: 2.22,
        startAngle: 232.119711,
        stopAngle: 312.01178
    },
    {
        center: [50.0741204343989, 14.4175442313173],
        radius: 2.22,
        startAngle: 312.011744,
        stopAngle: 323.312305
    },
    {
        center: [50.0741310355801, 14.417514323071],
        radius: 4.66,
        startAngle: 323.312311,
        stopAngle: 40.843266
    },
    {
        center: [50.0741724505285, 14.4177584612096],
        radius: 2.22,
        startAngle: 52.077796,
        stopAngle: 132.039092
    },
    {
        center: [50.0741724505285, 14.4177584612096],
        radius: 2.22,
        startAngle: 132.039136,
        stopAngle: 143.312262
    },
    {
        center: [50.0741618492846, 14.4177883694277],
        radius: 4.66,
        startAngle: 143.31231,
        stopAngle: 220.843265
    },
    {
        center: [50.074145418503, 14.4177657944873],
        radius: 2.22,
        startAngle: 220.843347,
        stopAngle: 232.119674
    },
    {
        center: [50.074145418503, 14.4177657944873],
        radius: 2.22,
        startAngle: 232.119711,
        stopAngle: 312.01178
    },
    {
        center: [50.074145418503, 14.4177657944873],
        radius: 2.22,
        startAngle: 312.011744,
        stopAngle: 323.312305
    },
    {
        center: [50.0741559429307, 14.4177360437276],
        radius: 4.66,
        startAngle: 323.312311,
        stopAngle: 40.843266
    },
    {
        center: [50.0741724505285, 14.4177584612096],
        radius: 1.97,
        startAngle: 49.535363,
        stopAngle: 134.620211
    },
    {
        center: [50.0741724505285, 14.4177584612096],
        radius: 1.97,
        startAngle: 134.62014,
        stopAngle: 143.312323
    },
    {
        center: [50.0741618492846, 14.4177883694277],
        radius: 4.41,
        startAngle: 143.31231,
        stopAngle: 220.843265
    },
    {
        center: [50.074145418503, 14.4177657944873],
        radius: 1.97,
        startAngle: 220.843321,
        stopAngle: 229.571891
    },
    {
        center: [50.074145418503, 14.4177657944873],
        radius: 1.97,
        startAngle: 229.57189,
        stopAngle: 314.583689
    },
    {
        center: [50.074145418503, 14.4177657944873],
        radius: 1.97,
        startAngle: 314.583688,
        stopAngle: 323.312259
    },
    {
        center: [50.0741559429307, 14.4177360437276],
        radius: 4.41,
        startAngle: 323.312311,
        stopAngle: 40.843266
    },
    {
        center: [50.0741724505285, 14.4177584612096],
        radius: 2.22,
        startAngle: 40.843289,
        stopAngle: 52.077777
    },
    {
        center: [50.0741724505285, 14.4177584612096],
        radius: 1.97,
        startAngle: 40.843252,
        stopAngle: 49.535435
    }
]

//

const tileSvc1 = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const tileSvc2 = '//mapserver.mapy.cz/base-m/{z}-{x}-{y}'
const tileSvc3 = 'http://ags.cuzk.cz/arcgis/services/zmwm/MapServer/WMSServer'
const tileSvc4 = 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'

const polyline = [[51.505, -0.09], [51.51, -0.1], [51.51, -0.12]]

const polyline2 = [{ lat: 51.505, lng: -0.09 }, { lat: 51.51, lng: -0.1 }, { lat: 51.51, lng: -0.12 }]

const axisDataJtsk = [
    { y: 593574.18, x: 1099485.05, z: 545.05 },
    { y: 593578.36, x: 1099487.79, z: 545.04 },
    { y: 593582.55, x: 1099490.53, z: 545.03 },
    { y: 593586.74, x: 1099493.25, z: 545.02 },
    { y: 593590.98, x: 1099495.9, z: 545.34 },
    { y: 593595.26, x: 1099498.48, z: 545.64 },
    { y: 593599.59, x: 1099500.98, z: 545.96 },
    { y: 593603.96, x: 1099503.42, z: 546.27 },
    { y: 593608.36, x: 1099505.79, z: 546.58 },
    { y: 593612.8, x: 1099508.08, z: 546.89 },
    { y: 593617.3, x: 1099510.27, z: 547.2 },
    { y: 593621.85, x: 1099512.34, z: 547.52 },
    { y: 593626.47, x: 1099514.25, z: 547.82 },
    { y: 593631.09, x: 1099516.16, z: 548.14 },
    { y: 593635.71, x: 1099518.07, z: 548.45 },
    { y: 593640.46, x: 1099519.63, z: 548.77 },
    { y: 593645.21, x: 1099521.19, z: 549.08 },
    { y: 593649.97, x: 1099522.74, z: 549.39 },
    { y: 593654.77, x: 1099524.13, z: 549.71 },
    { y: 593659.58, x: 1099525.49, z: 550.03 },
    { y: 593664.39, x: 1099526.85, z: 550.36 },
    { y: 593669.27, x: 1099527.95, z: 550.68 },
    { y: 593674.16, x: 1099528.97, z: 551 },
    { y: 593679.06, x: 1099529.98, z: 551.32 },
    { y: 593683.99, x: 1099530.8, z: 551.63 },
    { y: 593688.94, x: 1099531.5, z: 551.95 },
    { y: 593693.89, x: 1099532.2, z: 552.28 },
    { y: 593698.86, x: 1099532.75, z: 552.6 },
    { y: 593703.84, x: 1099533.15, z: 552.92 },
    { y: 593708.83, x: 1099533.54, z: 553.23 },
    { y: 593713.82, x: 1099533.8, z: 553.55 },
    { y: 593718.82, x: 1099533.82, z: 553.86 },
    { y: 593723.82, x: 1099533.85, z: 554.17 },
    { y: 593728.81, x: 1099533.81, z: 554.49 },
    { y: 593733.81, x: 1099533.55, z: 554.8 },
    { y: 593738.8, x: 1099533.28, z: 555.11 },
    { y: 593743.79, x: 1099532.99, z: 555.42 },
    { y: 593748.77, x: 1099532.49, z: 555.74 },
    { y: 593753.74, x: 1099531.98, z: 556.06 },
    { y: 593758.72, x: 1099531.48, z: 556.38 },
    { y: 593763.64, x: 1099530.62, z: 556.7 },
    { y: 593768.56, x: 1099529.75, z: 557.02 },
    { y: 593773.49, x: 1099528.88, z: 557.35 },
    { y: 593778.32, x: 1099527.62, z: 557.67 },
    { y: 593783.14, x: 1099526.28, z: 557.99 },
    { y: 593787.96, x: 1099524.95, z: 558.32 },
    { y: 593792.78, x: 1099523.61, z: 558.64 },
    { y: 593797.6, x: 1099522.28, z: 558.96 },
    { y: 593802.42, x: 1099520.95, z: 559.29 },
    { y: 593807.13, x: 1099519.29, z: 559.61 },
    { y: 593811.76, x: 1099517.42, z: 559.91 },
    { y: 593816.4, x: 1099515.56, z: 560.19 },
    { y: 593821.04, x: 1099513.68, z: 560.48 },
    { y: 593825.67, x: 1099511.8, z: 560.8 },
    { y: 593830.3, x: 1099509.92, z: 561.15 },
    { y: 593834.9, x: 1099507.96, z: 561.51 },
    { y: 593839.44, x: 1099505.87, z: 561.85 },
    { y: 593843.99, x: 1099503.78, z: 562.18 },
    { y: 593848.5, x: 1099501.64, z: 562.5 },
    { y: 593852.97, x: 1099499.4, z: 562.82 },
    { y: 593857.44, x: 1099497.15, z: 563.13 },
    { y: 593861.89, x: 1099494.88, z: 563.44 },
    { y: 593866.31, x: 1099492.53, z: 563.76 },
    { y: 593870.71, x: 1099490.16, z: 564.09 },
    { y: 593875.08, x: 1099487.74, z: 564.41 },
    { y: 593879.45, x: 1099485.3, z: 564.69 },
    { y: 593883.76, x: 1099482.77, z: 564.97 },
    { y: 593888.07, x: 1099480.23, z: 565.26 },
    { y: 593892.34, x: 1099477.62, z: 565.55 },
    { y: 593896.61, x: 1099475.02, z: 565.83 },
    { y: 593900.87, x: 1099472.42, z: 566.11 },
    { y: 593905.14, x: 1099469.81, z: 566.1 }
]

const jtsk_to_wgs = (X, Y, H) => {
    var coord = { wgs84_latitude: '', wgs84_longitude: '', lat: 0, lng: 0, vyska: 0 }
    /*Vypocet zemepisnych souradnic z rovinnych souradnic*/
    let a = 6377397.15508
    let e = 0.081696831215303
    let n = 0.97992470462083
    let konst_u_ro = 12310230.12797036
    let sinUQ = 0.863499969506341
    let cosUQ = 0.504348889819882
    let sinVQ = 0.420215144586493
    let cosVQ = 0.907424504992097
    let alfa = 1.000597498371542
    let k = 1.003419163966575
    let ro = Math.sqrt(X * X + Y * Y)
    let epsilon = 2 * Math.atan(Y / (ro + X))
    let D = epsilon / n
    let S = 2 * Math.atan(Math.exp((1 / n) * Math.log(konst_u_ro / ro))) - Math.PI / 2
    let sinS = Math.sin(S)
    let cosS = Math.cos(S)
    let sinU = sinUQ * sinS - cosUQ * cosS * Math.cos(D)
    let cosU = Math.sqrt(1 - sinU * sinU)
    let sinDV = (Math.sin(D) * cosS) / cosU
    let cosDV = Math.sqrt(1 - sinDV * sinDV)
    let sinV = sinVQ * cosDV - cosVQ * sinDV
    let cosV = cosVQ * cosDV + sinVQ * sinDV
    let Ljtsk = (2 * Math.atan(sinV / (1 + cosV))) / alfa
    let t = Math.exp((2 / alfa) * Math.log((1 + sinU) / cosU / k))
    let pom = (t - 1) / (t + 1)
    let sinB
    do {
        sinB = pom
        pom = t * Math.exp(e * Math.log((1 + e * sinB) / (1 - e * sinB)))
        pom = (pom - 1) / (pom + 1)
    } while (Math.abs(pom - sinB) > 1e-15)

    let Bjtsk = Math.atan(pom / Math.sqrt(1 - pom * pom))

    /* Pravoúhlé souřadnice ve S-JTSK */

    a = 6377397.15508
    let f_1 = 299.152812853
    let e2 = 1 - (1 - 1 / f_1) * (1 - 1 / f_1)
    ro = a / Math.sqrt(1 - e2 * Math.sin(Bjtsk) * Math.sin(Bjtsk))
    let x = (ro + H) * Math.cos(Bjtsk) * Math.cos(Ljtsk)
    let y = (ro + H) * Math.cos(Bjtsk) * Math.sin(Ljtsk)
    let z = ((1 - e2) * ro + H) * Math.sin(Bjtsk)

    /* Pravoúhlé souřadnice v WGS-84*/
    let dx = 570.69
    let dy = 85.69
    let dz = 462.84
    let wz = ((-5.2611 / 3600) * Math.PI) / 180
    let wy = ((-1.58676 / 3600) * Math.PI) / 180
    let wx = ((-4.99821 / 3600) * Math.PI) / 180
    let m = 3.543e-6
    let xn = dx + (1 + m) * (x + wz * y - wy * z)
    let yn = dy + (1 + m) * (-wz * x + y + wx * z)
    let zn = dz + (1 + m) * (wy * x - wx * y + z)

    /* Geodetické souřadnice v systému WGS-84*/
    a = 6378137.0
    f_1 = 298.257223563
    let a_b = f_1 / (f_1 - 1)
    let p = Math.sqrt(xn * xn + yn * yn)
    e2 = 1 - (1 - 1 / f_1) * (1 - 1 / f_1)
    let theta = Math.atan((zn * a_b) / p)
    let st = Math.sin(theta)
    let ct = Math.cos(theta)
    t = (zn + e2 * a_b * a * st * st * st) / (p - e2 * a * ct * ct * ct)
    let B = Math.atan(t)
    let L = 2 * Math.atan(yn / (p + xn))
    H = Math.sqrt(1 + t * t) * (p - a / Math.sqrt(1 + (1 - e2) * t * t))

    /* Formát výstupních hodnot */

    B = (B / Math.PI) * 180

    coord.lat = B
    let latitude = 'N'
    if (B < 0) {
        B = -B
        latitude = 'S'
    }

    let st_sirky = Math.floor(B)
    B = (B - st_sirky) * 60
    let min_sirky = Math.floor(B)
    B = (B - min_sirky) * 60
    let vt_sirky = Math.round(B * 1000) / 1000
    latitude = st_sirky + '°' + min_sirky + "'" + vt_sirky + latitude
    coord.wgs84_latitude = latitude

    L = (L / Math.PI) * 180
    coord.lng = L
    let longitude = 'E'
    if (L < 0) {
        L = -L
        longitude = 'W'
    }

    let st_delky = Math.floor(L)
    L = (L - st_delky) * 60
    let min_delky = Math.floor(L)
    L = (L - min_delky) * 60
    let vt_delky = Math.round(L * 1000) / 1000
    longitude = st_delky + '°' + min_delky + "'" + vt_delky + longitude
    coord.wgs84_longitude = longitude

    coord.vyska = Math.round(H * 100) / 100

    return coord
}

const axisDataWgs84 = axisDataJtsk.map(i => jtsk_to_wgs(i.x, i.y, i.z))

const building1 = [[49.746631, 16.577457], [49.746506, 16.577529]]
const building2 = [
    [49.746972, 16.576998],
    [49.747111, 16.577151],
    [49.746983, 16.577417],
    [49.746863, 16.577288],
    [49.746893, 16.577219],
    [49.746951, 16.577279],
    [49.746978, 16.577219],
    [49.746978, 16.577219],
    [49.746906, 16.57713]
]

const profilePoints = [
    jtsk_to_wgs(1099495.1703, 593860.3534, 567.7239),
    jtsk_to_wgs(1099490.6165, 593858.1601, 565.1327),
    jtsk_to_wgs(1099500.5474, 593863.3963, 565.084),
    jtsk_to_wgs(1099489.4445, 593857.8927, 560.0974),
    jtsk_to_wgs(1099501.5194, 593864.2391, 560.3602)
]

const pointColors = ['#e71d1d', '#0022ff', '#128807', '#e76314', '#3c44a2']

const arcs = [
    [[50.0740962752855, 14.4169689452333], [50.0741036695835, 14.4169673635074]],
    [[50.0741036695835, 14.4169673635074], [50.0741110898317, 14.41696577623]],
    [[50.0741110898317, 14.41696577623], [50.0741183105695, 14.4169642316297]],
    [[50.0741183105695, 14.4169642316297], [50.0741251123986, 14.4169627766385]],
    [[50.0741251123986, 14.4169627766385], [50.0741312886488, 14.4169614554656]],
    [[50.0741312886488, 14.4169614554656], [50.0741366516579, 14.4169603082543]],
    [[50.0741366516579, 14.4169603082543], [50.0741410384735, 14.4169593698621]],
    [[50.0741410384735, 14.4169593698621], [50.0741443158045, 14.4169586688019]],
    [[50.0741443158045, 14.4169586688019], [50.0741457832673, 14.416958354894]]
]

const kn = true

class gisMap extends React.Component {
    state = {
        center: kn
            ? {
                  lat: dxfData[0][0][0],
                  lng: dxfData[0][0][1]
              }
            : {
                  lat: 49.747207,
                  lng: 16.577593
              },
        zoom: kn ? 19 : 15
    }

    render = props => {
        //debugger
        const v = jtsk_to_wgs(593860.3534, 1099495.1703, 567.7239)
        return (
            <div>
                <Map
                    center={
                        this.state.center
                        //[50.074577937964911, 14.417444678304502]
                        //[50.074145418503, 14.4177657944873]
                    }
                    zoom={
                        this.state.zoom
                        //22
                    }
                    style={{ height: '880px' }}>
                    <LayersControl position="topright">
                        <Overlay name="Hrebec" checked>
                            <LayerGroup>
                                <Polyline color="lime" positions={axisDataWgs84} />
                                <Polyline color="lime" positions={[profilePoints[3], profilePoints[4]]} />
                                <Rectangle bounds={building1} color="black" />
                                <Polygon positions={building2} color="black">
                                    <Tooltip>
                                        <div>
                                            <p>Budova XYZ 20m</p>
                                            <img src="images/xyz.png" alt="tooltip" />
                                        </div>
                                    </Tooltip>
                                </Polygon>
                                <CircleMarker center={[49.747207, 16.577593]} color="red" radius={2} />

                                {profilePoints.map(
                                    (p, index) =>
                                        true && (
                                            <Circle
                                                key={index}
                                                center={{ lat: p.lat, lng: p.lng }}
                                                color={pointColors[index]}
                                                radius={0.01}>
                                                <Popup className="ppp">
                                                    <img src={`images/11100${index + 1}.png`} alt="Popup" />
                                                </Popup>
                                            </Circle>
                                        )
                                )}
                            </LayerGroup>
                        </Overlay>

                        <Overlay name="KN" checked>
                            <LayerGroup>
                                {dxfData.map(
                                    (l, index) =>
                                        true && (
                                            <Polyline
                                                key={index}
                                                color={true || index < 3 ? 'lime' : 'blue'}
                                                positions={l}
                                            />
                                        )
                                )}

                                {false && (
                                    <SemiCircle
                                        radius={2.82}
                                        startAngle={0}
                                        //                        startAngle={40.846487}
                                        stopAngle={-60}
                                        //                        stopAngle={143.315495}
                                        center={[50.074577937964911, 14.417444678304502]}
                                        fill={false}
                                        color={'yellow'}
                                    />
                                )}

                                {dxfArcs
                                    //.slice(0, 100)
                                    .map((a, i) => {
                                        console.log(a)
                                        return (
                                            true && (
                                                <SemiCircle
                                                    key={i}
                                                    center={a.center}
                                                    radius={a.radius}
                                                    startAngle={f(a.startAngle + 10)}
                                                    stopAngle={f(a.stopAngle + 10)}
                                                    color={true || i < 1 ? 'lime' : 'blue'}
                                                    fill={false}
                                                />
                                            )
                                        )
                                    })}
                                {dxfArcs.map((a, i) => {
                                    // stredy
                                    //console.log(a)
                                    return (
                                        false && (
                                            <CircleMarker
                                                key={i}
                                                center={a.center}
                                                radius={3}
                                                startAngle={a.startAngle}
                                                stopAngle={a.stopAngle}
                                                color={'orange'}
                                            />
                                        )
                                    )
                                })}
                            </LayerGroup>
                        </Overlay>

                        <Overlay name="FM" checked>
                            <LayerGroup>
                                {dxfDataFM.map(
                                    (l, index) =>
                                        true && (
                                            <Polyline
                                                key={index}
                                                color={'lime'}
                                                positions={l}
                                            />
                                        )
                                )}
                            </LayerGroup>
                        </Overlay>


                        <BaseLayer checked name="Google Maps TMS">
                            <LayerGroup>
                                {true && (
                                    <TileLayer
                                        attribution="Map data &amp;copy 2019 Google"
                                        url={'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'}
                                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                        maxZoom={25}
                                    />
                                )}
                            </LayerGroup>
                        </BaseLayer>

                        <BaseLayer name="Open Street Maps TMS">
                            <LayerGroup>
                                {true && (
                                    <TileLayer
                                        attribution="Map data &amp;copy 2019 Google"
                                        url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                                        maxZoom={25}
                                    />
                                )}
                            </LayerGroup>
                        </BaseLayer>

                        <BaseLayer name="CUZK OrtoFoto WMS">
                            {true && (
                                <WMSTileLayer
                                    url={'http://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx'}
                                    layers={'GR_ORTFOTORGB'}
                                />
                            )}
                        </BaseLayer>
                        <BaseLayer name="CUZK 1:10 000">
                            {true && (
                                <WMSTileLayer
                                    url={'http://geoportal.cuzk.cz/WMS_ZM10_PUB/WMService.aspx'}
                                    layers={'GR_ZM10'}
                                />
                            )}
                        </BaseLayer>
                    </LayersControl>
                </Map>

                <div>
                    <button
                        onClick={() =>
                            this.setState({
                                center: {
                                    lat: dxfData[0][0][0],
                                    lng: dxfData[0][0][1]
                                },
                                zoom: 19
                            })
                        }>
                        KN
                    </button>
                    <button
                        onClick={() =>
                            this.setState({
                                center: {
                                    lat: 49.747207,
                                    lng: 16.577593
                                },
                                zoom: 15
                            })
                        }>
                        Hrebec
                    </button>
                    <button
                        onClick={() =>
                            this.setState({
                                center: {
                                    lat: 49.579768,
                                    lng: 14.078147
                                },
                                zoom: 12
                            })
                        }>
                        Chraštice
                    </button>
                </div>
                <p>Confidential do not distribute</p>
            </div>
        )
    }
}

const f = a => {
    return (360 - a) % 360
}

export default gisMap
