import React from 'react'
import './App.css'
import GisMap from './containers/GisMap/GisMap'

const pa = 'Hre'
const sw = 'bec'

class App extends React.Component {
    state = {
        password: '' //pa+sw
        //password: pa+sw
    }

    renderBody = () => {
        if (this.state.password === pa + sw) {
            return <GisMap />
        } else {
            return (
                <div>
                    <p>Vložte heslo:</p>
                    <input
                        value={this.state.password}
                        onChange={e => this.setState({ password: e.target.value })}
                    />
                    <p style={{ color: 'red' }}>{this.state.password && 'Nesprávné heslo'}</p>
                </div>
            )
        }
    }

    render = () => <div className="App">{this.renderBody()}</div>
}

export default App
