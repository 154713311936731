import { CircleMarker } from 'leaflet'
import { Path } from 'leaflet'
import * as lf from 'leaflet'

/*
 * @class Circle
 * @aka L.Circle
 * @inherits CircleMarker
 *
 * A class for drawing circle overlays on a map. Extends `CircleMarker`.
 *
 * It's an approximation and starts to diverge from a real circle closer to poles (due to projection distortion).
 *
 * @example
 *
 * ```js
 * L.circle([50.5, 30.5], {radius: 200}).addTo(map);
 * ```
 */
const x = lf

const extendPoint = () => {
    const { point, Point } = lf
    const rotated2 = (p, angle, r) => {
        const x = Math.cos(angle)
        const y = Math.sin(angle)
        const px = point(x, y)
        const py = px.multiplyBy(r)
        const res = p.add(py)

        return res
    }

    const rotated = (p, angle, r) => {
        const cos = Math.cos(angle)
        const sin = Math.sin(angle)
        //const px = point(x, y)
        //const py = px.multiplyBy(r)

        //const x = p.x * cos - p.y * sin + r
        //const y = p.x * sin + p.y * cos + r
        //const res = point(-y, -x)

        const x = r * cos + p.x
        const y = r * sin + p.y
        const res = point(x, y)

        return res
    }

    Point.prototype.rotated = function(angle, r) {
        return rotated(this, angle, r)
    }
}
extendPoint()

const extendSvg = () => {
    const { SVG } = lf
    SVG.include({
        _updateSemiCircle: function(layer) {
            // If we want a circle, we use the original function
            /* if (
                !(layer instanceof L.SemiCircle || layer instanceof L.SemiCircleMarker) ||
                !layer.isSemicircle()
            ) {
                return _updateCircleSVG.call(this, layer)
            }*/
            if (layer._empty()) {
                return this._setPath(layer, 'M0 0')
            }

            const p = layer._map.latLngToLayerPoint(layer._latlng)
            const r = layer._radius
            const r2 = r //Math.round(layer._radiusY || r)
            const start = p.rotated(layer.startAngle(), r)
            const end = p.rotated(layer.stopAngle(), r)

            const largeArc = layer.startAngle() - layer.startAngle() >= 180 ? '1' : '0'

            const d =
                'M ' +
                p.x +
                ' ' +
                p.y + // line to first start point
                ' M ' +
                start.x +
                ' ' +
                start.y +
                ' A ' +
                r +
                ' ' +
                r2 +
                ' 0 ' +
                largeArc +
                ' 1 ' +
                end.x +
                ' ' +
                end.y //+ ' z'

            //console.log(d)

            let d2 = d + ' M ' + (p.x + 10) + ' ' + (p.y + 65) + ' H ' + end.x
            //d2 = d2 + ' M ' + end.x + ', ' + end.y + 'H ' + (start.x - 10)

            this._setPath(layer, d)
        }
    })
}

extendSvg()

export var SemiCircle = CircleMarker.extend({
    options: {
        startAngle: 0,
        stopAngle: 359.9999
    },

    startAngle: function() {
        return fixAngle(this.options.stopAngle)
        return fixAngle(this.options.startAngle)
        if (this.options.startAngle < this.options.stopAngle) {
            return fixAngle(this.options.startAngle)
        } else {
            return fixAngle(this.options.stopAngle)
        }
    },

    stopAngle: function() {
        return fixAngle(this.options.startAngle)
        return fixAngle(this.options.stopAngle)
        if (this.options.startAngle < this.options.stopAngle) {
            return fixAngle(this.options.stopAngle)
        } else {
            return fixAngle(this.options.startAngle)
        }
    },
    /*
    setStartAngle: function(angle) {
        this.options.startAngle = angle
        return this.redraw()
    },

    setStopAngle: function(angle) {
        this.options.stopAngle = angle
        return this.redraw()
    },

    setDirection: function(direction, degrees) {
        if (degrees === undefined) {
            degrees = 10
        }
        this.options.startAngle = direction - degrees / 2
        this.options.stopAngle = direction + degrees / 2

        return this.redraw()
    },
    getDirection: function() {
        return this.stopAngle() - (this.stopAngle() - this.startAngle()) / 2
    },*/

    initialize: function(latlng, options, legacyOptions) {
        if (typeof options === 'number') {
            // Backwards compatibility with 0.7.x factory (latlng, radius, options?)
            options = lf.Util.extend({}, legacyOptions, { radius: options })
        }

        lf.Util.setOptions(this, options)
        this._latlng = lf.latLng(latlng)

        if (isNaN(this.options.radius)) {
            throw new Error('Circle radius cannot be NaN')
        }

        // @section
        // @aka Circle options
        // @option radius: Number; Radius of the circle, in meters.
        this._mRadius = this.options.radius
    },
    /*
    // @method setRadius(radius: Number): this
    // Sets the radius of a circle. Units are in meters.
    setRadius: function(radius) {
        this._mRadius = radius
        return this.redraw()
    },

    // @method getRadius(): Number
    // Returns the current radius of a circle. Units are in meters.
    getRadius: function() {
        return this._mRadius
    },*/

    // @method getBounds(): LatLngBounds
    // Returns the `LatLngBounds` of the path.
    getBounds: function() {
        var half = [this._radius, this._radiusY || this._radius]

        return new lf.LatLngBounds(
            this._map.layerPointToLatLng(this._point.subtract(half)),
            this._map.layerPointToLatLng(this._point.add(half))
        )
    },

    _containsPoint: function(p) {
        function normalize(angle) {
            while (angle <= -Math.PI) {
                angle += 2.0 * Math.PI
            }
            while (angle > Math.PI) {
                angle -= 2.0 * Math.PI
            }
            return angle
        }
        var angle = Math.atan2(p.y - this._point.y, p.x - this._point.x)
        var nStart = normalize(this.startAngle())
        var nStop = normalize(this.stopAngle())
        if (nStop <= nStart) {
            nStop += 2.0 * Math.PI
        }
        if (angle <= nStart) {
            angle += 2.0 * Math.PI
        }
        return (
            nStart < angle &&
            angle <= nStop &&
            p.distanceTo(this._point) <= this._radius + this._clickTolerance()
        )
    },

    _updatePath: function() {
        //this._renderer._updateCircle(this)
        this._renderer._updateSemiCircle(this)
    },

    setStyle: Path.prototype.setStyle,

    _project: function() {
        const { lng, lat } = this._latlng
        const map = this._map
        const crs = map.options.crs

        const Earth = lf.CRS.Earth
        if (crs.distance === Earth.distance) {
            var d = Math.PI / 180,
                latR = this._mRadius / Earth.R / d,
                top = map.project([lat + latR, lng]),
                bottom = map.project([lat - latR, lng]),
                p = top.add(bottom).divideBy(2),
                lat2 = map.unproject(p).lat,
                lngR =
                    Math.acos(
                        (Math.cos(latR * d) - Math.sin(lat * d) * Math.sin(lat2 * d)) /
                            (Math.cos(lat * d) * Math.cos(lat2 * d))
                    ) / d

            if (isNaN(lngR) || lngR === 0) {
                lngR = latR / Math.cos((Math.PI / 180) * lat) // Fallback for edge case, #2425
            }

            this._point = p.subtract(map.getPixelOrigin())
            this._radius = isNaN(lngR) ? 0 : p.x - map.project([lat2, lng - lngR]).x
            this._radiusY = p.y - top.y
        } else {
            var latlng2 = crs.unproject(crs.project(this._latlng).subtract([this._mRadius, 0]))

            this._point = map.latLngToLayerPoint(this._latlng)
            this._radius = this._point.x - map.latLngToLayerPoint(latlng2).x
        }

        this._updateBounds()
    }
})

// @factory L.circle(latlng: LatLng, options?: Circle options)
// Instantiates a circle object given a geographical point, and an options object
// which contains the circle radius.
// @alternative
// @factory L.circle(latlng: LatLng, radius: Number, options?: Circle options)
// Obsolete way of instantiating a circle, for compatibility with 0.7.x code.
// Do not use in new applications or plugins.
export function semicircle(latlng, options, legacyOptions) {
    return new SemiCircle(latlng, options, legacyOptions)
}

const DEG_TO_RAD = Math.PI / 180

// make sure 0 degrees is up (North) and convert to radians.
const fixAngle = angle => {
    //    return (angle - 90) * DEG_TO_RAD
    return (angle + 0) * DEG_TO_RAD
}
